'use strict';

import _ from 'lodash';

function validateInputLeadData(leadData, errors) {
    const phoneRegExp = /^[0-9]{10}$/;
    const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validationResult = true;

    // NAME VALIDATION
    if (_.isEmpty(leadData.name)) {
        validationResult = false;
        errors.leadData.name = ['Your Name is empty'];
    }

    // EMAIL VALIDATION
    if (_.isEmpty(leadData.email)) {
        validationResult = false;
        errors.leadData.email = ['Email is empty'];
    } else if (!emailRegExp.test(leadData.email)) {
        validationResult = false;
        errors.leadData.email = ['Invalid email format'];
    }

    // BUSINESS NAME VALIDATION
    if (_.isEmpty(leadData.businessName)) {
        validationResult = false;
        errors.leadData.businessName = ['Business Name is empty'];
    }

    // PHONE VALIDATION
    if (_.isEmpty(leadData.phone)) {
        validationResult = false;
        errors.leadData.phone = ['Phone is empty'];
    } else if (!phoneRegExp.test(_.trim(leadData.phone))) {
        validationResult = false;
        errors.leadData.phone = ['Invalid phone format'];
    }

    return validationResult;
}

function parseLeadCreationErrors(errorDataDetails) {
    const parsedErrorBody = {leadData: {}, commonFormError: null};

    if (_.isArray(errorDataDetails.businessName)) {
        parsedErrorBody.leadData.businessName = [_.first(errorDataDetails.businessName)];
        parsedErrorBody.commonFormError = 'Wrong inputs';
    }

    if (_.isArray(errorDataDetails.email)) {
        parsedErrorBody.leadData.email = [_.first(errorDataDetails.email)];
        parsedErrorBody.commonFormError = 'Wrong inputs';
    }

    if (_.isArray(errorDataDetails.name)) {
        parsedErrorBody.leadData.name = [_.first(errorDataDetails.name)];
        parsedErrorBody.commonFormError = 'Wrong inputs';
    }

    if (_.isArray(errorDataDetails.phone)) {
        parsedErrorBody.leadData.phone = [_.first(errorDataDetails.phone)];
        parsedErrorBody.commonFormError = 'Wrong inputs';
    }

    if (_.isArray(errorDataDetails.services)) {
        parsedErrorBody.leadData.services = [_.first(errorDataDetails.services)];
        parsedErrorBody.commonFormError = 'Wrong inputs';
    }

    return parsedErrorBody;
}

class HomeCtrl {
    constructor($scope, $http, $uibModal, $location, blockUI) {
        var $this = this;

        this.$scope = $scope;
        this.$http = $http;
        this.blockData = blockUI.instances.get('block-data');

        //models:
        this.name = '';
        this.email = '';
        this.businessName = '';
        this.phone = '';
        this.services = {
            listing_management: false,
            websites: false,
            social_IQ: false,
            phone_IQ: false,
            paid_search: false,
            review_generator: false
        };
        this.isLeadSubmited = false;

        //errors:
        $this.errors = {leadData: {}, commonFormError: null};

        this.$scope.signInPopup = (typeFormOpen)=> {
            $this.$scope.typeForm = typeFormOpen;
            $this.$scope.modalInstance = $uibModal
                .open({
                    templateUrl: '/public/templates/signInPopup.tpl.html',
                    size: 'login-panel-form',
                    keyboard: true,
                    bindToController: true,
                    controller: 'UserCtrl',
                    controllerAs: 'uc',
                    scope: this.$scope
                });
        };
        let hash = $location.hash();
        if (hash && ['login', 'registration', 'forgot'].indexOf(hash) !== -1) {
            this.$scope.signInPopup(hash);
        }

        HomeCtrl.init();
    }

    static init() {
    }

    /**
     * sends lead data
     */
    sendLeads() {
        const $this = this;

        var serviceKeys = Object.keys($this.services);
        const service = [];

        for (var key in serviceKeys) {
            if ($this.services[serviceKeys[key]]) {
                service.push(serviceKeys[key]);
            }
        }

        const leadData = {
            name: $this.name,
            businessName: $this.businessName,
            email: $this.email,
            phone: $this.phone,
            service: service,
            leadSource: "Organic BPP"
        };

        $this.errors = {leadData: {}, commonFormError: null};

        //Validate input fields:
        let isValidLeadData = validateInputLeadData(leadData, $this.errors);

        if (isValidLeadData) {
            $this.blockData.start();

            $this.$http.post('/home/create-salesforce-lead', leadData)
              .then((response) => {
                if(response.status === 200 && !_.isEmpty(response.data) && _.isObject(response.data)) {
                    $this.isLeadSubmited = true;
                    $this.blockData.stop();

                } else {
                  $this.blockData.stop();
                }
              })
              .catch(function (response) {

                if (response.data && response.data.details) {
                    if (_.isString(response.data.details)) {
                        $this.errors.commonFormError = response.data.details;
                    } else {
                        $this.errors = parseLeadCreationErrors(response.data.details);
                    }
                }

                if (_.isEmpty($this.errors.commonFormError) || _.isEqual($this.errors.commonFormError, 'Validation error')
                    || _.isEqual($this.errors.commonFormError, 'Unprocessable Entity')) {
                    $this.errors.commonFormError = 'Please revise your information or call us at 800-266-0219';
                }

                console.error(response);
                $this.blockData.stop();
              });

        } else {
            $this.errors.commonFormError = 'Wrong inputs';
        }

    }

}

export default HomeCtrl
