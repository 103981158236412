'use strict';


const _$http = new WeakMap(),
    _$sce = new WeakMap();


class BusinessService {


    constructor($http, $sce) {
        _$http.set(this, $http);
        _$sce.set(this, $sce);
    }

    loadECL(businessId, ecl) {
        let params = {
            listingId: businessId,
            template: ecl.type,
            listId: ecl.id,
            format: 'html'
        },
        that = this;


        return _$http.get(this).get(`/business/enhanced_content`, {params: params})
            .then(onSuccess);


        function onSuccess(response) {
            return (response.headers('Content-type').includes('text/html') ?  _$sce.get(that).trustAsHtml(response.data) : response.data);
        }

        function onError(error) {
            return error;
        }
    }

}

export default BusinessService;