'use strict';

class DirectoryCtrl {
    constructor() {
        this.SORT_FIELD = 'https://pr.business/directory sorting field';

        this.sortField = localStorage.getItem(this.SORT_FIELD);
        if (!/^-?(name.substring\(0,1\)|listingCount)$/.test(this.sortField)) {
            this.sortField = 'name.substring(0,1)';
        }
    }

    uiInit(breadcrumbs, heading, subheading, directoryHeading, directoryItems, directoryBusinessesPagination, featuredBusinesses, topBusinesses) {
        this.breadcrumbs = breadcrumbs;
        this.heading = heading;
        this.subheading = subheading;
        this.directoryHeading = directoryHeading;
        this.directoryItems = directoryItems;
        this.directoryBusinessesPagination = directoryBusinessesPagination;
        this.featuredBusinesses = featuredBusinesses;
        this.topBusinesses = topBusinesses;
        this.breadcrumbs = breadcrumbs;
    }

    setSortField(sortField) {
        if (sortField === 'listingCount' && !/^-?listingCount$/.test(this.sortField)) {
            this.sortField = `-${sortField}`;
        } else {
            this.sortField = this.sortField === sortField ? `-${sortField}` : sortField;
        }

        localStorage.setItem(this.SORT_FIELD, this.sortField);
    }

    updatePagination() {
        location.href = `${this.breadcrumbs.itemListElement[this.breadcrumbs.itemListElement.length - 1].item}?page=${this.directoryBusinessesPagination.page}`;
        this.directoryBusinessesPagination.page = this.directoryBusinessesPagination.initialPage;
    }
}

export default DirectoryCtrl;
