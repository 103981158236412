// import jQuery from 'jquery'
import angular from 'angular';
import ngAnimate from 'angular-animate';
import ngTouch from 'angular-touch';
import bootstrap from 'bootstrap-sass';
import angularBootstrap from 'angular-bootstrap';
import angularCookies from 'angular-cookies';
import inview from 'jquery-inview';
// import register from './utils/register';
import Braintree from 'braintree-web';
import * as BusinessModule from './business/index';
import * as CommonModule from './common/index';
import * as StaticModule from './static/index';
import * as SearchModule from './search/index';
import * as DirectoryModule from './directory/index';
import * as HomeModule from './home/index';
import * as PaymentModule from './payment/index.js';
import * as SalesFunnelModule from './sales_funnel/index';
import * as UserModule from './user/index.js';
import * as AdsModule from './ads/index.js';
import * as ext from './modules/ext.modules.js';
import bowser from 'bowser';
import _ from 'lodash';
// import clusterer from './utils/markerclusterer';

import StaticPagesCtrl from './static-pages-serfing/staticPages.ctrl';

import * as Vendasta from './vendasta';

import vcRecaptcha from 'angular-recaptcha';

angular.module('app', [
    'vcRecaptcha',
    'ui.bootstrap', 'ngAutocomplete', 'ngAnimate', 'ngTouch', 'blockUI', 'scoreGauge', 'scoreBreakdownGauge', 'hoursInfo', 'prRating',
    'adGoogleCustomSearch', 'reviews', 'ngCookies', 'scriptHelper', 'checkout', 'numberLen', 'agentVerify',
    'ngMaterial', 'ngMessages', 'slickCarousel', 'renderGroupedReviewTable', 'renderGroupedReviewTableFoodMenu', 'renderGroupedReviewTableTextMessage', 'renderGroupedReviewTableIMapping', 'renderGroupedReviewTableWebsitePerformance',
])
    .filter('tel', CommonModule.PhoneFilter)
    .filter('utilizeText', ['$sce', CommonModule.UtilizeTextFilter])
    .filter('startFrom', CommonModule.StartFromFilter)
    .filter('mediaTypes', CommonModule.MediaTypesFilter)
    .filter('decodeURIComponent', CommonModule.DecodeURIComponentFilter)

    .config(['blockUIConfig', function (blockUIConfig) {
        // Provide the custom template via a url
        blockUIConfig.template = `
            <div class="block-ui-overlay"></div>
            <div class="block-ui-message-container" aria-live="assertive" aria-atomic="true">
                <div class="block-ui-message ng-binding text-center" data-ng-class="$_blockUiMessageClass">
                    <i class="fa fa fa-spinner fa-pulse fa-3x"></i>
                    <div>{{ (state.message !== 'Loading ...') ? state.message : '' }}</div>
                </div>
            </div>`;
        // Disable automatically blocking of the user interface
        blockUIConfig.autoBlock = false;
    }])

//try to fix issue with md-theme-style attribute in <style> of header :
    .config(['$mdThemingProvider', function($mdThemingProvider) {
        $mdThemingProvider.setDefaultTheme('default');

        //disable theme generation:
        $mdThemingProvider.generateThemesOnDemand(true);

        //themes are still defined in config, but the css is not generated
        $mdThemingProvider.theme('altTheme');
    }])

    .run(['$rootScope', '$http', function ($rootScope, $http) {
        $http.get('/auth')
            .then(function (response) {
                $rootScope.authData = response.data.authData;
            })
            .catch(function (error) {
            })
    }])

    .service('ListingService', ['$http', BusinessModule.ListingService])
    .service('BusinessService', ['$http', '$sce', BusinessModule.BusinessService])
    .service('SearchService', ['$http', '$sce', SearchModule.SearchService])
    .service('CommonUtils', ['$window', '$sce', CommonModule.CommonUtils])
    .service('DefaultReviewsData', CommonModule.DefaultReviewsData)

    .controller('BusinessCtrl', ['$scope', '$uibModal', '$sce', '$interval', '$location', '$anchorScroll', '$http', 'ListingService', 'CommonUtils', 'BusinessService', 'blockUI', BusinessModule.BusinessController])
    .controller('AddBusinessCtrl', ['$scope', '$http', '$window', 'blockUI', BusinessModule.AddBusinessController])
    .controller('GalleryCtrl', ['$scope', '$uibModalInstance', 'options', 'CommonUtils', BusinessModule.GalleryCtrl])
    .controller('SearchCtrl', ['$window', '$scope', '$rootScope', '$http', '$cookies', '$uibModal', '$location', 'CommonUtils', 'SearchService', SearchModule.SearchCtrl])
    .controller('DirectoryCtrl', DirectoryModule.DirectoryCtrl)
    .controller('HomeCtrl', ['$scope', '$http', '$uibModal', '$location', 'blockUI', HomeModule.HomeController])
    .controller('ReviewCampaignCtrl', ['blockUI', '$scope', '$http', HomeModule.ReviewCampaignCtrl])
    .controller('BusinessSearchLandingCtrl', ['CommonUtils', 'blockUI', '$interval', '$timeout', '$window', '$scope', '$http', '$q', '$uibModal', HomeModule.BusinessSearchLandingCtrl])
    .controller('BusinessSearchLandingModalCtrl', ['$scope', 'data', HomeModule.BusinessSearchLandingModalCtrl])
    .controller('UserCtrl', ['$scope', '$rootScope', '$http', '$cookies', '$window', '$location', 'blockUI', UserModule.UserController])
    .controller('PaymentUserCtrl', ['$scope', '$rootScope', '$http', '$cookies', '$window', '$location', 'blockUI', UserModule.PaymentUserController])
    .controller('ContactPageController', ['$scope', '$http', '$window', StaticModule.ContactPageController])
    .controller('SalesFunnelCtrl', ['$scope', '$uibModal', '$location', 'ListingService', 'CommonUtils', SalesFunnelModule.SalesFunnelCtrl])
    .controller('PaymentCtrl', ['$scope', '$rootScope', '$http', '$uibModal', '$cookies', '$timeout', '$window', '$location', 'blockUI', 'CheckoutService', 'CommonUtils', 'CheckoutConst', 'ListingService', 'DefaultReviewsData', PaymentModule.PaymentController])
    .controller('AffiliatesPaymentCtrl', ['$scope', '$rootScope', '$http', '$uibModal', '$cookies', '$timeout', '$window', '$location', 'blockUI', 'CheckoutService', 'CommonUtils', 'CheckoutConst', 'ListingService', 'DefaultReviewsData', PaymentModule.AffiliatesPaymentCtrl])
    .controller('ReviewGeneratorPaymentCtrl', ['$scope', '$rootScope', '$http', '$uibModal', '$cookies', '$timeout', '$window', '$location', 'blockUI', 'CheckoutService', 'CommonUtils', 'CheckoutConst', 'ListingService', 'DefaultReviewsData', PaymentModule.ReviewGeneratorPaymentCtrl])
    .controller('OldPaymentCtrl', ['$scope', '$rootScope', '$http', '$window', '$location', 'blockUI', 'CheckoutService', 'CommonUtils', 'CheckoutConst', PaymentModule.OldPaymentController])
    .controller('ManageThumbsDownReviewCtrl', ['$window', '$scope', '$http', '$uibModal', 'blockUI', 'CommonUtils', BusinessModule.ManageThumbsDownReviewCtrl])
    .controller('StaticPagesCtrl', ['$window', '$scope', '$rootScope', '$http', '$cookies', '$uibModal', '$location', '$sce', 'blockUI', StaticPagesCtrl])
    .controller('VendastaCtrl', ['$scope', '$rootScope', '$http', '$uibModal', '$cookies', '$window', '$location', 'blockUI', Vendasta.VendastaCtrl])

    .directive('categoriesBreadcrumbs', BusinessModule.CategoriesBreadcrumbs)
    .directive('prSyncedLabel', () => new CommonModule.PrSyncedLabel())
    .directive('prYextPartnerActiveLabel', () => new CommonModule.PrYextPartnerActiveLabel())
    .directive('prVerifiedLabel', () => new CommonModule.PrVerifiedLabel())

    .directive('copyToClipboard', () => new CommonModule.CopyToClipboard())
    .directive('addthisToolbox', CommonModule.AddthisToolbox.directiveFactory1)
    .directive('slideInZone', CommonModule.SlideInZone.directiveFactorySlideInZone)
    .directive('inlineError', () => new CommonModule.InlineErrorDrct())
    .directive('showMore', () => new CommonModule.ShowMore())
    .directive('selectCategory', () => new CommonModule.SelectCategory())
    .directive('ratingsChart', CommonModule.RatingsChart)
    .directive('previewGallery', () => new CommonModule.PreviewGallery())
    .directive('viewGalleryItem', CommonModule.ViewGalleryItem.directiveFactory3)
    .directive('videooModal', CommonModule.VideooModal.directiveFactoryVideooModal)
    .directive('formattedPhone', () => new CommonModule.MaskInputPhone())
    .directive('businessMap', SearchModule.BusinessMap.directiveFactoryBusinessMap)
    .directive('ads4', () => new AdsModule.ads4())
    .directive('clickOut', ['$window', '$parse', CommonModule.ClickOut.directiveFactory4]);

