angular.module('scoreGauge', [])
    .directive('scoreGauge', function () {

        return {
            restrict: 'A',
            transclude: true,
            scope: {
                scoreValue: "=",
                width: "=",
                height: "=",
                fontsSmall: "=",
                fontsBig: "=",
                lineWidth: "=",
                disableColors: "=",
                isLighthouse: "=?",
            },
            link: function (scope, elem, attrs) {
                var backgroundColor = '#cdd0d7',
                    disabledColor = '#e5e5e5';

                var viewPrScoreGauge = function () {

                    var size = {width: scope.width, height: scope.height},
                        fonts = {small: scope.fontsSmall, big: scope.fontsBig},
                        ctx = elem[0].getContext('2d'),
                        curveLength = 2,
                        lineWidth = scope.lineWidth || 3,
                        endPos = curveLength * (scope.scoreValue / 100) + 0.5,
                        backStyle = backgroundColor,
                        style = !!scope.disableColors ? disabledColor : getColor(scope.scoreValue);

                    if (scope.isLighthouse) {
                        var lighthouseColors = getLighthouseColors(scope.scoreValue);
                        backStyle = lighthouseColors.background;
                        style = lighthouseColors.stroke;
                    }

                    if (window.devicePixelRatio > 1) {
                        var canvasWidth = scope.width;
                        var canvasHeight = scope.height;

                        elem[0].width = canvasWidth * window.devicePixelRatio;
                        elem[0].height = canvasHeight * window.devicePixelRatio;

                        elem[0].style.width = canvasWidth + 'px';
                        //elem[0].style.height = canvasHeight + 'px';

                        ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
                    }

                    var radius = (size.width - lineWidth) / 2;

                    return function (drawText) {
                        ctx.clearRect(0, 0, size.width, size.height);
                        var disableText = (typeof scope.disableColors === 'undefined') ? false : scope.disableColors;
                        ctx.lineWidth = lineWidth;
                        ctx.fillStyle = +scope.scoreValue === 0 ? scope.disableColors : style;
                        ctx.strokeStyle = backStyle;
                        //drawing background arc (full path)
                        ctx.beginPath();
                        ctx.arc(size.width / 2, size.height / 2, radius, endPos * Math.PI, toRadians(90));
                        ctx.stroke();
                        if (scope.isLighthouse) {
                            ctx.arc(size.width / 2, size.height / 2, size.width / 2, 0, 2 * Math.PI);
                            ctx.fillStyle = backStyle;
                            ctx.fill();
                        }

                        if (scope.scoreValue <= 100) {
                            //drawing actual score arc (with % length)
                            ctx.beginPath();
                            ctx.strokeStyle = style;
                            ctx.arc(size.width / 2, size.height / 2, radius, toRadians(90), toRadians(endPos * 180));
                            ctx.stroke();
                            //we'll draw text only if flag set to true - when required font is loaded - and score display isn't disabled
                            if (!!drawText && !disableText) {
                                let varScoreValue = scope.scoreValue + (scope.isLighthouse ? '' : '%');
                                ctx.font = "bold " + fonts.big + "px Raleway,Helvetica,Arial,sans-serif";
                                ctx.fillStyle = style + (scope.isLighthouse ? '' : 'font-weight: 800;');
                                ctx.fillText(varScoreValue, (size.width - ctx.measureText(varScoreValue).width) / 2, (scope.isLighthouse ? 1.5 : 2) * fonts.big);
                            }

                            if (scope.scoreValue <= 93 && !scope.isLighthouse) {
                                ctx.beginPath();
                                ctx.lineWidth = lineWidth / 2;
                                // (size.width / 2 + 1) margin to the right on 1px
                                ctx.arc(size.width / 2 + 1, size.height / 2 + radius, lineWidth / 4, toRadians(90), toRadians(270));
                                ctx.strokeStyle = backStyle;
                                ctx.stroke();
                                ctx.fillStyle = backStyle;
                                ctx.fill();
                            }

                        }

                    }
                };

                scope.$watch("scoreValue", function (oldVal, newVal) {
                    showScoreWithPreload();
                });

                function toRadians(deg) {
                    return deg * Math.PI / 180;
                }

                function showScoreWithPreload() {
                    var drawer = viewPrScoreGauge();
                    drawer(scope.isLighthouse);

                    var cb = function (e) {
                        e.target.removeEventListener('error', this);
                        drawer(true);
                    };
                    //This is a workaround to avoid cases, when canvas text is painted before font is loaded
                    //We're forcing browser to load/cache it via 'Image' usage
                    var fontLink = document.getElementById('marvelFontDownloadLink');
                    var element = document.createElement('IMG');
                    element.addEventListener('error', cb, true);
                    element.src = fontLink.href;
                }

                function getColor(score) {
                    var style;
                    switch (true) {
                        case score <=30:
                            style = '#E54141';
                            break;
                        case score <= 31 <= score && score <= 34:
                            style = '#E8513A';
                            break;
                        case 35 <= score && score <= 38:
                            style = '#EE712B';
                            break;
                        case 39 <= score && score <= 42:
                            style = '#EE712B';
                            break;
                        case 43 <= score && score <= 46:
                            style = '#F18124';
                            break;
                        case 47 <= score && score <= 50:
                            style = '#F3911D';
                            break;
                        case 51 <= score && score <= 54:
                            style = '#F3911D';
                            break;
                        case 55 <= score && score <= 58:
                            style = '#F9B10E';
                            break;
                        case 59 <= score && score <= 62:
                            style = '#FCC107';
                            break;
                        case 63 <= score && score <= 66:
                            style = '#FFD100';
                            break;
                        case 67 <= score && score <= 70:
                            style = '#F4D108';
                            break;
                        case 71 <= score && score <= 74:
                            style = '#E8D110';
                            break;
                        case 75 <= score && score <= 78:
                            style = '#DDD117';
                            break;
                        case 79 <= score && score <= 82:
                            style = '#D1D11F';
                            break;
                        case 83 <= score && score <= 86:
                            style = '#C6D127';
                            break;
                        case 87 <= score && score <= 90:
                            style = '#BAD12F';
                            break;
                        case 91 <= score && score <= 94:
                            style = '#AFD136';
                            break;
                        case 95 <= score && score <= 98:
                            style = '#A3D13E';
                            break;
                        case score >= 99:
                            style = '#0089e5';
                            break;
                    }
                    return style;
                }

                function getLighthouseColors(score) {
                    var style;
                    switch (true) {
                        case score <= 89:
                            style = { background: '#ffd2d2', stroke: '#e2212c' };
                            // style = { background: '#ffabab', stroke: '#a70101' };
                            break;
                        case score >= 90:
                            style = { background: '#dcffdc', stroke: '#34a344' };
                            break;
                    }
                    return style;
                }

                if (!scope.isLighthouse) {
                    var h5 = $('<h5>')
                        .text('reputation score')
                        .addClass('text-center reputation_score')
                        .css({'color': getColor(scope.scoreValue)});

                    $(elem[0]).after(h5);
                }
            }
        };

    });
