/**
 * showMore Directive
 *
 * This directive is used to add a 'show more' option to any text. To use put on the view the text inside the followings divs:
 *
 *    <div show-more show-more-height='80'">
 *      <div class='showMoreContent'>
 *         **** TEXT ****
 *      </div>
 *    </div>
 *
 *
 */

class ShowMore {
    constructor($scope, $element, $interval) {
        this.template = `
            <span>
                <div data-ng-style='showLessStyle' data-ng-hide='expanded' data-ng-transclude></div>
                <div data-ng-show='expanded' data-ng-transclude></div>
                <a style='float:right;' data-ng-hide='expanded || !expandable' data-ng-click='expanded = !expanded; $event.stopPropagation();' localize>Read More</a>
                 <a style='float:right;' class='col-sm-offset-3' data-ng-show='expanded && expandable' data-ng-click='expanded = !expanded; $event.stopPropagation();'>Show Less</a>
            </span>`;
        this.restrict = 'A';
        this.transclude = true;
        this.scope = {
            showMoreHeight: '@',
            enabled: '@'
        };

        this.controller = ['$scope', '$interval', '$element', function ($scope, $interval, $element) {
            $scope.enabled = $scope.enabled !== 'false';

            $scope.expanded = !$scope.enabled;

            $interval(function () {
                renderStyles();
            }, 300);

            $scope.expandable = false;

            function renderStyles() {
                if ($element.height() >= $scope.showMoreHeight && $scope.expanded === false) {
                    $scope.expandable = true;
                }
            }

            if ($scope.enabled) {
                $scope.showLessStyle = {
                    'max-height': $scope.showMoreHeight + 'px',
                    'overflow': 'hidden'
                };
            }
        }];
    }

}

export default ShowMore;


