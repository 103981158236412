'use strict';

class ReviewCampaignCtrl {

    constructor(blockUI, $scope, $http) {
        this.$http = $http;
        this.loading = blockUI.instances.get('review_campaign');
        this.zipRegex = /^\d{5}$/i;
        this.showThanksMsg = false;
        this.termsOfUse = false;
        this.review = {};
        this.errors = {};
        this.signInPanel = false;
        this.campaignName = '';
        this.campaignsData = {
            'buycox': {
                logoURL: '/public/images/dynamic/cox_logo.png',
                headline: 'Thank You for Your Recent Order with Buycox.com!',
                description: 'Please rate your experience with our sales center and ordering process.',
                locationPrompt: 'Where will this installation happen?'
            },
            'toshiba-business-solutions': {
                logoURL: '/public/images/dynamic/toshiba-business-solutions-logo.png',
                headline: 'Thank You For Doing Business With Us At Toshiba Business Solutions.',
                description: 'Your opinion helps us be the best we can be. Please rate your experience with us.',
                locationPrompt: 'Where is your office located?'
            },
            'toshiba-business-west': {
                logoURL: '/public/images/dynamic/toshiba-business-solutions-logo.png',
                headline: 'Thank You For Doing Business With Us At Toshiba Business Solutions.',
                description: 'Your opinion helps us be the best we can be. Please rate your experience with us.',
                locationPrompt: 'Where is your office located?'
            },
            'toshiba-business-east': {
                logoURL: '/public/images/dynamic/toshiba-business-solutions-logo.png',
                headline: 'Thank You For Doing Business With Us At Toshiba Business Solutions.',
                description: 'Your opinion helps us be the best we can be. Please rate your experience with us.',
                locationPrompt: 'Where is your office located?'
            },
            'buytimewarnercable': {
                logoURL: '/public/images/dynamic/time-warner-cable-logo.png',
                headline: 'Thank You For Doing Business With Us At Time Warner Cable.',
                description: 'Your opinion helps us be the best we can be. Please rate your experience with us.',
                locationPrompt: 'Where will this installation happen?'
            },
            'fabt': {
                logoURL: 'https://pr-email-campaigns.s3.amazonaws.com/images/First_American_Bank_and_Trust_387x150.jpg',
                headline: 'Thank you for choosing First American Bank and Trust.',
                description: 'Your opinion helps us be the best we can be. Please rate your experience with us.',
                locationPrompt: 'In what zip code do you typically do your banking?'
            }
        };
    }

    resetSubmitButton() {
        this.disableSubmit = false;
        this.showsubmit = true;
        this.errors = {};
    }

    sendReview() {
        var $this = this;

        this.loading.start();
        this.errors.rate = '';
        this.errors.zip = '';
        this.errors.message = '';
        this.errors.termsOfUse = '';
        this.errors.user_name = '';
        this.errors.user_email = '';
        this.disableSubmit = true;

        if (!this.validateReview()) {
            this.loading.stop();
            return;
        }

        this.index = this.user_name.indexOf(' ');
        this.review.userData = {
            firstName: this.user_name.substring(0, this.index),
            lastName: this.user_name.substring(this.index + 1, this.user_name.length),
            email: this.user_email
        };
        this.review.campaignName = this.campaignName;

        $this.$http.post("/review/campaign", this.review)
            .then(function (resp) {
                if (resp.status !== 200) {
                    $this.errors.status = resp.errorMessage ? resp.errorMessage : (resp.error ? (resp.error.message || resp.error) : 'Something unexpected happened.');
                } else {
                    if (!!resp.loginRequired) {
                        $this.signInPanel = true;
                    } else {
                        //show tnx message
                        $this.showThanksMsg = true;

                        // trigger event tagging for business review
                        // ll('eventTag', 'Write a Review', {'Business Reviewed' : $this.campaignName});
//                        var _hsq = window._hsq = window._hsq || [];
//                        _hsq.push(['trackEvent', {
//                            id: '000005941335',
//                            value: {'Business Reviewed' : $this.campaignName}
//                        }]);
                    }
                }
            })
            .catch(function (err) {
                $this.errors.zip = "Oops! It appears there was a problem with your form. Please contact your representative.";
                $this.disableSubmit = false;
                console.error(err);
            })
            .finally(function () {
                $this.loading.stop();
            });

    }

    validateReview() {
        var valid = true;
        if (!this.review.rate) {
            this.errors.rate = "Please rate us.";
            valid = false;
        }
        if (!this.review.zip || !this.zipRegex.test(this.review.zip)) {
            this.errors.zip = "Please, enter valid zipcode";
            valid = false;
        }
        if (!this.review.message) {
            this.errors.message = 'Please write a comment.';
            valid = false;
        }
        if (!this.termsOfUse) {
            this.errors.termsOfUse = 'Please confirm you agree to our Service policies.';
            valid = false;
        }
        if (!this.user_name) {//TODO: min length validation
            this.errors.user_name = 'Please enter your name.';
            valid = false;
        }
        if (!this.user_email || this.user_email.indexOf('@') < 0) {
            this.errors.user_email = 'Please enter valid email.';
            valid = false;
        }

        return valid;
    }

}

export default ReviewCampaignCtrl;
