import _ from 'lodash';

class ViewGalleryItem {
    /*@ngInject*/
    constructor($compile) {
        this.$compile = $compile;
        this.template = `
                    <div data-ng-if="media.galleryFilterType === 'photo'" class="wrap-slider">
                        <img class="center-block img-responsive" data-ng-src="{{media.imageUrl}}" alt="{{media.imageAlt}}"/>
                    </div>
                    <p>{{media.imageTitle}}</p>
                    
                    <iframe data-ng-if="media.galleryFilterType === 'video' && !showVideoo"
                            data-ng-src="{{trustUrlFn()(media.playUrl)}}"
                            frameborder="0" width="100%" height="315"
                            allowfullscreen></iframe>
                    <div data-ng-if="media.galleryFilterType === 'video' && showVideoo">
                       <script src="https://videoo.com/w/spa/509?embed=1"></script>
                        <div id="videoo_container_592c3ccc776a3" class="videooContainer" style="width:100%"><iframe id="widget_509" class="videoo-widget videoo-widget-player" src="about:blank" style="width:100%" border="0" frameborder="0" scrolling="no"></iframe></div> 
                    </div>
                   
        `;
        this.restrict = 'E';
        this.scope = {
            media: '=',

            //fix service does not inject
            trustUrlFn: '&'
        };
    }

    link(scope, element, attr) {

        scope.getViewYoutubeImage = (url) => {
            let videoId = _.last(url.split('v=')),
                ampersandPosition = videoId.indexOf('&');

            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            return `https://www.youtube.com/embed/${videoId}`;
        };

        // var that = this;
        var that = ViewGalleryItem.instance;
        var video = '<script type="text/javascript" src="https://videoo.com/w/spa/509?embed=1"></script>';
        var el = that.$compile(video)(scope);
        element.parent().append(el);


        scope.videoo = 'https://videoo.com/w/spa/509?embed=1';
        scope.trustVideooUrl = scope.trustUrlFn()(scope.videoo);
        scope.showVideoo = scope.media.playUrl.indexOf('2NA1Pw9_Dx8') !== -1;

        //video only
        scope.trustUrl = scope.trustUrlFn()(scope.media.playUrl);

    }

    static directiveFactory3($compile){
        ViewGalleryItem.instance = new ViewGalleryItem($compile);
        return ViewGalleryItem.instance;
    }
}

ViewGalleryItem.directiveFactory3.$inject = ['$compile'];

export default ViewGalleryItem;


