class ads4 {
    constructor() {
        this.template = `
        <ins class="adsbygoogle"
             style="display:inline-block;min-width:170px;max-width:278px;width:100%;height:auto; min-height:230px; max-height:250px"
             data-ad-client="ca-pub-7483335053104056"
             data-ad-slot="8208431326">
        </ins>
        <script type="text/javascript-lazy">try{(adsbygoogle = window.adsbygoogle || []).push({});} catch(e) {}</script>`;
        this.restrict = 'E';
    }
}

export default ads4;