'use strict';
import _ from 'lodash'

angular.module('hoursInfo', [])
    .directive('hoursInfo', ['$window', '$http', '$timeout', 'CommonUtils', function ($window, $http, $timeout, CommonUtils) {
        return {
            restrict: 'E',
            scope: {
                data: '@',
                hoursInfo: '@'
            },
            template: `<div>
                            {{infoMessage}}<span data-ng-if="infoTime">{{::infoTime}}</span>
                            
                            <a data-ng-if=\"!openAllHours\" class=\"view_more\" href=\"\" data-toggle=\"popover\" popover-append-to-body=\"true\" data-ng-click=\"viewMore($event)\">View More<\/a>\r\n                            
                            <div class=\"hours-table-wrapper\" style=\"display: none;\">\r\n                                
                                <table class=\"table new-table hours-table\" style=\"min-width: 200px;\">\r\n                                    
                                    <tr data-ng-repeat=\"hour in hours\" class=\"p2\" data-ng-class=\"{\'current-day\':hour.isToday}\">\r\n                                        
                                        <td class=\"p2 capitalize\">{{hour.day}}<\/td>\r\n                                        
                                        <td data-ng-if=\"!hour.isClosed && !hour.openAllHours\" class=\"p2 text-center\">{{ hour.openHour | date :\'hh:mm a\' | lowercase}}<\/td>\r\n
                                        <td data-ng-if=\"!hour.isClosed && !hour.openAllHours\" class=\"p2 text-center\">-<\/td>\r\n
                                        <td data-ng-if=\"!hour.isClosed && !hour.openAllHours\" class=\"p2 text-center\">{{hour.closeHour | date :\'hh:mm a\' | lowercase}}<\/td>\r\n                                        
                                        <td data-ng-if=\"hour.isClosed\" class=\"p2 text-center\" colspan=\"3\">Closed<\/td>\r\n                                        
                                        <td data-ng-if=\"hour.openAllHours\" class=\"p2 text-center\" colspan=\"3\">Open 24 hours<\/td>\r\n                                    
                                    <\/tr>\r\n                                    
                                    <\/tbody>\r\n                                
                                <\/table>\r\n
                                <div>{{::hoursInfoMessage}}</div>
                            <\/div>\r\n
                         <\/div>`,
            controller: function ($scope, $filter) {
                $scope.showViewMore = false;
                $scope.showHoursInfo = false;
                $scope.timeFormat = 'hh:mm a';
                $scope.infoTime = '';

                let parsedHoursInfo = JSON.parse($scope.hoursInfo);
                $scope.hoursInfoMessage = parsedHoursInfo === 'null' ? null : parsedHoursInfo;

                let weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    todayStr = $filter('date')(new Date()),
                    todayNumber = new Date(todayStr).getDay(),
                    parsedHours = JSON.parse($scope.data);

                _.forEach(parsedHours, h => {
                    if (h.dayNo === 7) {
                        for (let j = 1; j < 6; j++) {
                            let normalizedHour = _.clone(h);
                            normalizedHour.dayNo = j;
                            let contains = !_.isEmpty(_.filter(parsedHours, parsedH => parsedH.dayNo === normalizedHour.dayNo));
                            if (!contains) {
                                parsedHours.push(normalizedHour);
                            }
                        }
                    }
                });

                $scope.hours = _.sortBy(parsedHours.filter(h => h.dayNo !== 7).map(function (hour) {
                    let openHour = new Date(),
                        openTime = hour.openTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
                    openHour.setHours(parseInt(openTime[1]) + (openTime[3] ? 12 : 0));
                    openHour.setMinutes(parseInt(openTime[2]) || 0);
                    hour.openHour = openHour;
                    hour.day = weekday[hour.dayNo];
                    let closeHour = new Date(),
                        closeTime = hour.closeTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
                    closeHour.setHours(parseInt(closeTime[1]) + (closeTime[3] ? 12 : 0));
                    closeHour.setMinutes(parseInt(closeTime[2]) || 0);
                    //Avoid cases when close hour is 24:00 (which is converted automatically to 00:00)
                    if (closeHour.getHours() === 0) {
                        closeHour.setDate(closeHour.getDate() + 1);
                    }
                    hour.closeHour = closeHour;

                    hour.isToday = todayNumber === hour.dayNo;
                    hour.openAllHours = (hour.openTime === '00:00:00' && hour.closeTime === '00:00:00' && hour.isClosed === false);

                    return hour;
                }), h => h.dayNo);

                let filteredHours = _.filter($scope.hours, h => h.openAllHours);
                $scope.openAllHours = filteredHours.length === 7;

                (function () {
                    let hours = _.clone($scope.hours),
                        today = new Date(),
                        todayDayId = today.getDay(),
                        thisDayExists = false,
                        thisDayHours,
                        appointment = _.find(parsedHours, h => {
                        return h.dayNo === -1;
                    });
                    for (let dayNumber = 0; dayNumber < hours.length; dayNumber++) {
                        if (todayDayId === hours[dayNumber].dayNo) {
                            thisDayHours = hours[dayNumber];
                            thisDayExists = true;
                        }
                    }
                    let message = '';
                    if (!thisDayExists && !_.isEmpty(appointment)) {
                        message = 'Available by Appointment Only';
                        $scope.openAllHours = true;
                    } else if (thisDayExists && thisDayHours.openAllHours) {
                        message = 'Open 24 hours';
                    } else if (thisDayExists && thisDayHours.isClosed) {
                        message = 'Closed';
                    } else if (thisDayExists && today >= thisDayHours.openHour && today <= thisDayHours.closeHour) {
                        message = 'Open until ';
                        $scope.infoTime = $filter('date')(thisDayHours.closeHour, $scope.timeFormat);
                    } else {
                        message = 'Hours';
                    }

                    $scope.infoMessage = message;

                }());

                $scope.viewMore = function ($event) {
                    $scope.showViewMore = !$scope.showViewMore;
                    let $target = jQuery($event.target);
                    if (!$scope.initialized) {
                        $target.popover({
                            animation: true,
                            html: true,
                            placement: CommonUtils.isMobile() ? 'bottom' : 'right',
                            content: $target.siblings('.hours-table-wrapper').html(),
                            trigger: 'focus'
                        });
                        $scope.initialized = true;
                        $target.popover('show');
                    }
                };
            }
        };
    }]);