'use strict';
import _ from 'lodash';
import moment from "moment";

function definedMediaType(media) {
  let galleryFilterType = '';
  if(media.imageType){
    galleryFilterType = 'photo';
  }
  if(media.type){
    galleryFilterType = 'video';
  }
  media.galleryFilterType = galleryFilterType;

  return media;
}

const PAGE_SIZE  = 16;


class BusinessCtrl {
    constructor($scope, $uibModal, $sce, $interval, $location, $anchorScroll, $http, ListingService, CommonUtils, BusinessService, blockUI) {
        var $this = this;
        this.blockData = blockUI.instances.get('block-data');
        this.$scope = $scope;
        this.$scope.isShownTab = '';
        $this.filterMode = 'all';
        $this.currentPage = 0;
        $this.pageSize = PAGE_SIZE;


        this.$scope.showTab = () => {
            return $scope.isShownTab;
        };
        this.$scope.toggleTab = (tabName) => {
            $scope.isShownTab = tabName === $scope.isShownTab ? '' : tabName;
        };
        this.$scope.isMobile = () => {
            return CommonUtils.isMobile();
        };
        this.$scope.isTablet = () => {
            return CommonUtils.isTablet();
        };
        this.$scope.featuresWrapper = {features: []};
        this.$scope.locations = [];

        this.$scope.adCampaigns = {};
        this.$scope.absBlock = {};
        this.$http = $http;
        this.$uibModal = $uibModal;

        this.bc = $scope.bc;
        this.bc.reportData = {reports: []};
        this.bc.visibilityScore = {};
        this.bc.mainScore = 0;
        this.bc.reviewsQuantity = {};

        // Configure slick slider
        this.$scope.slickSettings = {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          variableWidth: true,
          centerMode: true,
        };

        // this.bc.reports = [];


        $this.bc.openListing = ()=> {
            $this.blockData.start();

            ListingService.loadReport($this.bc.businessId, 'baseline')
                .then(response => {
                    let report = _.head(response.data.report);

                    //set filter not to use WhitePages and 411.com reports
                    if (report && !_.isEmpty(report.report)) {
                        report.report = _.filter(report.report,
                            currentResult => (currentResult.siteId
                                && currentResult.siteId !== 'WhitePages' && currentResult.siteId !== '411.com' ));
                    }

                    $this.bc.reviewsQuantity.prReviewsQuantity = response.data.prReviewsQuantity;
                    $this.bc.reportData = report;
                    $this.bc.createdDateReport = response.data;
                    $this.bc.visibilityScore = ListingService.getVisibilityScore(report, $this.bc.isBusUpgraded);
                    $this.bc.mainScore = _.round($this.bc.visibilityScore.score);
                    $this.bc.napConsistancy = ~~_.round($this.bc.visibilityScore.napConsistancy);
                    $this.bc.napScoreColor = ListingService.getNAPScoreColor($this.bc.mainScore);

                    $this.bc.year = moment(report.createdAt).format('YYYY');
                    $this.bc.month = moment(report.createdAt).format('MM');
                    $this.bc.host = window.location.host;

                    $this.blockData.stop();
                })
                .catch(err => {
                $this.blockData.stop();
                console.log(err)
            });
        };

        $this.bc.isNotError = report => {
            return report && _.isEmpty(report.errors);
        };

        /**
         * @param {String} phone
         * @returns {String} phone with dashes
         */
        $this.bc.getPhoneDash = (phone) => {
            if (_.isEmpty(phone)) {
                return '';
            }
            return phone.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
        };

        $this.bc.isUpgraded = isUpdated => {
            return isUpdated === 'true';
        };

        $this.bc.checkStatus = (report)=> {
            let image = 'attention', color = 'red', fontWeight = 'bold';

            if (!report.match_address && !report.match_name && !report.match_phone) {
                image = 'close';
            } else if (report.match_address && report.match_phone && report.match_name) {
                image = 'check';
                color = 'black';
                fontWeight = ''
            }

            return {image: image, color: color, fontWeight: fontWeight};

        };

        $('#yextModal').on('show.bs.modal', function(){
            $this.bc.openListing();
        });


        $this.bc.init = businessId => {
            this.bc.businessId = businessId;
        };

        $this.bc.isBusinessUpgraded = isBusinessUpgraded => {
            this.bc.isBusUpgraded = isBusinessUpgraded;
        };

        //UPGRADE
        $scope.showUpgradedError = function () {
            $uibModal.open({
                templateUrl: 'showUpgradedErrorModalContent.html',
                size: 'lg',
                backdrop: 'static',
                windowClass: 'top25vh',
                scope: $this.$scope
            })
                .result
                .then(() => {
                    $('#yextModal').modal("hide");
                })
                .catch(() => {
                    $('#yextModal').modal("hide");
                });
        };

        //VIDEOO
        $scope.showVideoo = function () {
            $uibModal.open({
                templateUrl: '/public/templates/videoo.tpl.html',
                size: 'lg',
                backdrop: 'static',
                windowClass: 'top25vh',
                scope: $this.$scope
            })
                .result
                .then(() => {
                    $('#showVideooModal').modal("hide");
                })
                .catch(() => {
                    $('#showVideooModal').modal("hide");
                });
        };

        $scope.showGallery = function (showMedia) {

            $this.$scope.modalInstance = $this.$uibModal
                .open({
                    templateUrl: '/public/templates/gallery-modal.tpl.html',
                    keyboard: true,
                    backdrop: false,
                    size: 'wrapper-gallery',
                    bindToController: true,
                    controller: 'GalleryCtrl',
                    controllerAs: 'gc',
                    resolve: {
                        options: function() {
                            return {
                                images: $this.$scope.galleryImages,
                                videos: $this.$scope.galleryVideos,
                                businessName: $this.$scope.businessName,
                                initEntry: showMedia
                            }
                        }
                    }
                });
        };


        $scope.showEcl = function (ecl) {
            $this.$scope.modalInstance = $this.$uibModal
                .open({
                    template: `<div data-ng-bind-html="ec.trustContent"> {{ec.trustContent}} </div>`,
                    keyboard: true,
                    // backdrop: false,
                    bindToController: true,
                    controller: function ($uibModalInstance, eclData) {
                        this.trustContent =  eclData;
                    },
                    controllerAs: 'ec',
                    resolve: {
                        eclData: function () {
                            return BusinessService.loadECL($this.bc.businessId, angular.fromJson(ecl));
                        }
                    }
                });
        };


        //CLAIM
        $scope.showClaimPopup = function (businessId) {
            if ($scope.$root.authData) {
                $uibModal.open({
                    templateUrl: 'showClaimPopup.html',
                    size: 'lg',
                    backdrop: 'static',
                    windowClass: 'top25vh',
                    scope: $this.$scope
                })
                    .result
                    .then(() => {
                        $this.blockData.start();
                        $scope.performFastClaim(businessId);
                    })
                    .catch(() => {
                        $('#showClaimPopup.html').modal("hide");
                    });
            } else {
                $this.$scope.signInPopup('registration', authData => {
                    $this.$scope.performFastClaim(businessId);
                });
            }
        };
        $scope.performFastClaim = businessId => {
            $this.$http.post(`/business/${businessId}/fast-claim`)
                .then(response => {
                    var businessZip = response.data.business.zipcode,
                        businessCategory = _.get(response.data, 'business.Category.name', '');

                    // Business sucessfully claimed, tag event in Localytics
                    // ll('tagEvent', 'Claim a Business', {
                    //     'Business ID': businessId,
                    //     'Location': businessZip,
                    //     'Category of Business': businessCategory,
                    //     'Claim Type': 'BPP'
                    // });
                    // Business sucessfully claimed, tag event in Hubspot
//                    var _hsq = window._hsq = window._hsq || [];
//                    _hsq.push(['trackEvent', {
//                        id: '000005941336',
//                        value: {
//                            'Business ID': businessId,
//                            'Location': businessZip,
//                            'Category of Business': businessCategory,
//                            'Claim Type': 'BPP'
//                        }
//                    }]);

                    window.location.assign(response.data.redirectURL);
                })
                .catch(err => {
                    $this.blockData.stop();
                    console.log(err)
                });
        };

        //The V8 sort is not stable, unfortunately. This decision was made in order to avoid incorrect sorting in v8(Chrome)

        this.$scope.loadGallery = (images, videos, businessName) => {
            $this.$scope.galleryImages = images;
            $this.$scope.galleryVideos = videos;
            $this.$scope.businessName = businessName;
            $this.$scope.mediaList = images.concat(videos).map(item => definedMediaType(item));
        };

        //VIDEO
        this.$scope.videoIsPlaying = false;

        this.$scope.trustUrl = (url)=>{
            return CommonUtils.trustUrl(url);
        };




        this.$scope.initYextIframe = function () {
            var innerHeightIframe = $(window).height() - 339;
            $('.yext_iframe').attr('height', innerHeightIframe);
        };

        this.$scope.toggleVideoPlay = function ($event) {
            $this.$scope.videoIsPlaying = !$this.$scope.videoIsPlaying;
            var video = $event.target;
            if ($this.$scope.videoIsPlaying) {
                video.play();
            } else {
                video.pause();
            }
        };

        //COMMON

        /**
         * Here comes magic to recalculate height of right column on both desktop and tablet
         */
        //if (!$scope.isMobile()) {
        $this.$scope.$bottomContainer = null;
        $this.$scope.$bottomTabletContainer = null;
        $this.$scope.$rightSide = null;

        $this.$scope.getBottomContainer = function () {
            if (!$this.$scope.$bottomContainer) {
                $this.$scope.$bottomContainer = $('.business_reviews_all');
            }
            return $this.$scope.$bottomContainer || {};
        };
        $this.$scope.getBottomTabletContainer = function () {
            if (!$this.$scope.$bottomTabletContainer) {
                $this.$scope.$bottomTabletContainer = $('.space_for_ads_tablet');
            }
            return $this.$scope.$bottomTabletContainer || {};
        };

        $this.$scope.getRightSide = function () {
            if (!$this.$scope.$rightSide) {
                $this.$scope.$rightSide = $('.right_side');
            }
            return $this.$scope.$rightSide || {};
        };

        $interval(function () {
            if (!$scope.isMobile() && !$scope.isTablet()) {
                $this.$scope.getBottomContainer().css('min-height', $this.$scope.getRightSide().innerHeight() - $('.business_main').innerHeight() + 40/*padding-top*/);//desktop
                $this.$scope.getRightAdTablet().css('top', 0);//desktop
            }
        }, 1000);

        //if ($scope.isTablet()) {
        $this.$scope.$containerAllBusinessInfoTablet = null;
        $this.$scope.$containerRigthAdTablet = null;

        $this.$scope.getTabletRightSide = function () {
            if (!$this.$scope.$containerAllBusinessInfoTablet) {
                $this.$scope.$containerAllBusinessInfoTablet = $('.tablet_view_all_business_info')
            }
            return $this.$scope.$containerAllBusinessInfoTablet || {};
        };
        $this.$scope.getRightAdTablet = function () {
            if (!$this.$scope.$containerRigthAdTablet) {
                $this.$scope.$containerRigthAdTablet = $('.business_ads')
            }
            return $this.$scope.$containerRigthAdTablet || {};
        };

        $interval(function () {
            if ($scope.isTablet()) {
                $this.$scope.getBottomTabletContainer().css('min-height', $this.$scope.getRightSide().innerHeight() - 250);//tablet
                $this.$scope.getRightAdTablet().css('top', $this.$scope.getTabletRightSide().innerHeight() + 40 + ($('#dvBusinessBanner') || {}).innerHeight());//tablet
            }
        }, 1000);

        //}

        //}

        /**
         * Scroll to element with id
         */
        $this.$scope.scrollTo = function (id) {
            $location.hash(id);
            $anchorScroll();
        };

        this.$scope.redirectToUrl = function (url) {
            window.location.href = url;
        };

        let hash = $location.hash();
        if (hash && ['scan'].indexOf(hash) !== -1) {
            let frms = document.getElementsByClassName('btn-check_listings');
            if (frms && frms.length > 0) {
                frms[0].click();
            }
        }

        this.$scope.logout = function ($close) {
            $this.$http.get('/logout')
                .then(function () {
                    //Logging user out, tag event in Localytics
                    // ll('tagEvent', 'Log Out');
                    //Logging user out, tag event in Hubspot
//                    var _hsq = window._hsq = window._hsq || [];
//                    _hsq.push(['trackEvent', {
//                        id: '000005941338',
//                        value: null
//                    }]);

                    $scope.$root.authData = null;
                    $close();
                    $scope.signInPopup('login');
                })
                .catch(function (error) {
                    console.error(error);
                });
        };
        this.$scope.signInPopup = function (typeFormOpen, credentialsCallback) {
            // params across scope to popup
            $this.$scope.typeForm = typeFormOpen;

            if ($this.$scope.modalInstance) {
                $this.$scope.modalInstance.close();
            }
            if (!!credentialsCallback) {
                $this.$scope.credentialsCallback = credentialsCallback;
            }
            $this.$scope.modalInstance = $this.$uibModal
                .open({
                    templateUrl: '/public/templates/signInPopup.tpl.html',
                    size: 'login-panel-form',
                    keyboard: true,
                    bindToController: true,
                    controller: 'UserCtrl',
                    controllerAs: 'uc',
                    scope: $this.$scope
                });
        };

        BusinessCtrl.init();
    }

    static init() {
    }

}

export default BusinessCtrl;
