'use strict';

class SlideInZone {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            slideTimeout: '='
        };
    }

    link(scope, elem, attrs) {
        $(elem).find('.inview').on('inview', function (event, visible) {
            var e = $(SlideInZone.instance);
            if (visible) {
                setTimeout(function () {
                    e.addClass('slide-in');
                }, Math.floor( Math.random() * scope.slideTimeout ) + 50);
            }
        });
        $(document).ready(function () {
            $(window).trigger('resize')
        });
    }

    static directiveFactorySlideInZone(){
        SlideInZone.instance = new SlideInZone();
        return SlideInZone.instance;
    }
}

export default SlideInZone;


