'use strict';

class ClickOut {
    constructor($window, $parse) {
        this.$window = $window;
        this.$parse = $parse;
        this.restrict = 'A';
        this.scope = {
            clickOut: '='
        };
    }

    link(scope, element, attrs) {
        var $parent = scope.$parent,
            // clickOutHandler = this.$parse(attrs.clickOut);
            clickOutHandler = ClickOut.instance.$parse(attrs.clickOut);

        // angular.element(this.$window).on('click', function (event) {
        angular.element(ClickOut.instance.$window).on('click', function (event) {
            let child = element[0].contains(event.target);
            if (!child) {
                clickOutHandler($parent, {$event: event});
                scope.$apply();
            }
        });
    }

    static directiveFactory4($window, $parse){
        ClickOut.instance = new ClickOut($window, $parse);
        return ClickOut.instance;
    }
}

export default ClickOut;


