'use strict';

import _ from 'lodash';

function validateInputPayment(businessInformation, contactInformation, creditCardBillingAddress, creditCardInformation, errors) {
    let validationResult = true;

    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    const phoneRegExp = /^[0-9]{10}$/;
    const yearRegExp = /^\d{4}$/;

    //businessInformation:
    if (_.isEmpty(businessInformation.name)) {
        validationResult = false;
        errors.businessInformation.name = ['Name is empty'];
    }

    if (_.isEmpty(businessInformation.phone)) {
        validationResult = false;
        errors.businessInformation.phone = ['Phone is empty'];
    } else if (!phoneRegExp.test(_.trim(businessInformation.phone))) {
        validationResult = false;
        errors.businessInformation.phone = ['Invalid phone format'];
    }

    if (_.isEmpty(businessInformation.category)) {
        validationResult = false;
        errors.businessInformation.category = ['Category is empty'];
    }

    if (_.isEmpty(businessInformation.address)) {
        validationResult = false;
        errors.businessInformation.address = ['Address is empty'];
    }

    if (_.isEmpty(businessInformation.city)) {
        validationResult = false;
        errors.businessInformation.city = ['City is empty'];
    }

    if (_.isEmpty(businessInformation.state)) {
        validationResult = false;
        errors.businessInformation.state = ['State is empty'];
    }

    if (_.isEmpty(businessInformation.zipcode)) {
        validationResult = false;
        errors.businessInformation.zipcode = ['Zipcode is empty'];
    } else if (!zipCodeRegExp.test(businessInformation.zipcode)) {
        validationResult = false;
        errors.businessInformation.zipcode = ['Invalid zipcode format'];
    }

    if (_.isEmpty(businessInformation.country)) {
        validationResult = false;
        errors.businessInformation.country = ['Country is empty'];
    }

    //contactInformation:
    if (_.isEmpty(contactInformation.firstName)) {
        validationResult = false;
        errors.contactInformation.firstName = ['First Name is empty'];
    }

    if (_.isEmpty(contactInformation.lastName)) {
        validationResult = false;
        errors.contactInformation.lastName = ['Last Name is empty'];
    }

    if (_.isEmpty(contactInformation.email)) {
        validationResult = false;
        errors.contactInformation.email = ['Email is empty'];
    } else if (!emailRegExp.test(contactInformation.email)) {
        validationResult = false;
        errors.contactInformation.email = ['Invalid email format'];
    }

    if (_.isEmpty(contactInformation.password)) {
        validationResult = false;
        errors.contactInformation.password = ['Password is empty'];
    }

    if (_.isEmpty(contactInformation.passwordConfirm)) {
        validationResult = false;
        errors.contactInformation.passwordConfirm = ['Confirm is empty'];
    } else if (contactInformation.passwordConfirm !== contactInformation.password) {
        validationResult = false;
        errors.contactInformation.passwordConfirm = ['Passwords are different'];
    }

    if (_.isEmpty(contactInformation.mobilePhone)) {
        validationResult = false;
        errors.contactInformation.mobilePhone = ['Mobile Phone is empty'];
    } else if (!phoneRegExp.test(_.trim(contactInformation.mobilePhone))) {
        validationResult = false;
        errors.contactInformation.mobilePhone = ['Invalid mobile phone format'];
    }

    //creditCardBillingAddress:
    if (_.isEmpty(creditCardBillingAddress.companyName)) {
        validationResult = false;
        errors.creditCardBillingAddress.companyName = ['Name is empty'];
    }

    if (_.isEmpty(creditCardBillingAddress.address)) {
        validationResult = false;
        errors.creditCardBillingAddress.address = ['Address is empty'];
    }

    if (_.isEmpty(creditCardBillingAddress.city)) {
        validationResult = false;
        errors.creditCardBillingAddress.city = ['City is empty'];
    }

    if (_.isEmpty(creditCardBillingAddress.state)) {
        validationResult = false;
        errors.creditCardBillingAddress.state = ['State is empty'];
    }

    if (_.isEmpty(creditCardBillingAddress.zipcode)) {
        validationResult = false;
        errors.creditCardBillingAddress.zipcode = ['Zipcode is empty'];
    } else if (!zipCodeRegExp.test(creditCardBillingAddress.zipcode)) {
        validationResult = false;
        errors.creditCardBillingAddress.zipcode = ['Invalid zipcode format'];
    }

    if (_.isEmpty(creditCardBillingAddress.country)) {
        validationResult = false;
        errors.creditCardBillingAddress.country = ['Country is empty'];
    }

    //creditCardInformation:
    if (_.isEmpty(creditCardInformation.firstName)) {
        validationResult = false;
        errors.creditCardInformation.firstName = ['First Name is empty'];
    }

    if (_.isEmpty(creditCardInformation.lastName)) {
        validationResult = false;
        errors.creditCardInformation.lastName = ['Last Name is empty'];
    }

    if (_.isEmpty(creditCardInformation.name)) {
        validationResult = false;
        errors.creditCardInformation.name = ['Card Name is empty'];
    }

    if (_.isEmpty(creditCardInformation.number)) {
        validationResult = false;
        errors.creditCardInformation.number = ['Card Number is empty'];
    }

    if (_.isEmpty(creditCardInformation.cvv)) {
        validationResult = false;
        errors.creditCardInformation.cvv = ['Empty'];
    }

    if (_.isEmpty(creditCardInformation.expirationMonth)) {
        validationResult = false;
        errors.creditCardInformation.expirationMonth = ['Invalid'];
    }

    if (!yearRegExp.test(_.trim(creditCardInformation.expirationYear))) {
        validationResult = false;
        errors.creditCardInformation.expirationYear = ['Invalid'];
    }

    return validationResult;
}

function changedData(businessInformation, contactInformation, creditCardBillingAddress, creditCardInformation) {
    const newData = {};

    const newBusiness = {};
    const newUser = {};
    const newBilling = {};

    //parse:
    newBusiness.phone = businessInformation.phone;
    newBusiness.name = businessInformation.name;
    newBusiness.address = businessInformation.address;
    newBusiness.city = businessInformation.city;
    newBusiness.state = businessInformation.state;
    newBusiness.zipcode = businessInformation.zipcode;
    newBusiness.country = businessInformation.country;
    newBusiness.categoryId = _.get(_.last(businessInformation.category), 'id', null);

    newUser.email = contactInformation.email;
    newUser.firstName = contactInformation.firstName;
    newUser.lastName = contactInformation.lastName;
    newUser.password = contactInformation.password;
    newUser.passwordConfirm = contactInformation.passwordConfirm;
    newUser.mobilePhone = contactInformation.mobilePhone;


    newBilling.firstName = creditCardInformation.firstName;
    newBilling.lastName = creditCardInformation.lastName;
    newBilling.cardNumber = creditCardInformation.number;
    newBilling.cardholderName = creditCardInformation.name;
    newBilling.expirationMonth = creditCardInformation.expirationMonth;
    newBilling.expirationYear = creditCardInformation.expirationYear;
    newBilling.cvv = creditCardInformation.cvv;

    newBilling.companyName = creditCardBillingAddress.companyName;
    newBilling.address = creditCardBillingAddress.address;
    newBilling.city = creditCardBillingAddress.city;
    newBilling.state = creditCardBillingAddress.state;
    newBilling.zipcode = creditCardBillingAddress.zipcode;
    newBilling.country = creditCardBillingAddress.country;

    newData.business = newBusiness;
    newData.user = newUser;
    newData.billing = newBilling;

    return newData;
}

function parseErrors (responseErrors) {
    const parsedErrors = {businessInformation: {}, contactInformation: {}, creditCardBillingAddress: {}, creditCardInformation: {}};

    parsedErrors.businessInformation = responseErrors.business;

    parsedErrors.contactInformation.email = !_.isEmpty(responseErrors.user.email) ? [_.first(responseErrors.user.email)] : undefined;
    if (!_.isEmpty(responseErrors.user.user)) {
        parsedErrors.contactInformation.email = [_.first(responseErrors.user.user)];
    }

    parsedErrors.contactInformation.firstName = responseErrors.user.firstName;
    parsedErrors.contactInformation.lastName = responseErrors.user.lastName;
    if (!_.isEmpty(responseErrors.user.password) && responseErrors.user.password.length > 0) {
        if (responseErrors.user.password.length > 1) {
            parsedErrors.contactInformation.password = [_.first(responseErrors.user.password)];
            parsedErrors.contactInformation.passwordConfirm = [_.last(responseErrors.user.password)];
        } else {
            parsedErrors.contactInformation.password = responseErrors.user.password;
            parsedErrors.contactInformation.passwordConfirm = responseErrors.user.password;
        }
    }
    parsedErrors.contactInformation.mobilePhone = responseErrors.user.phone;

    parsedErrors.creditCardBillingAddress.companyName = responseErrors.billing.companyName;
    parsedErrors.creditCardBillingAddress.address = responseErrors.billing.address;
    parsedErrors.creditCardBillingAddress.city = responseErrors.billing.city;
    parsedErrors.creditCardBillingAddress.state = responseErrors.billing.state;
    parsedErrors.creditCardBillingAddress.zipcode = responseErrors.billing.zipcode;
    parsedErrors.creditCardBillingAddress.country = responseErrors.billing.country;

    parsedErrors.creditCardInformation.firstName = responseErrors.billing.firstName;
    parsedErrors.creditCardInformation.lastName = responseErrors.billing.lastName;
    parsedErrors.creditCardInformation.name = responseErrors.billing.cardholderName;
    parsedErrors.creditCardInformation.number = responseErrors.billing.cardNumber;
    parsedErrors.creditCardInformation.cvv = responseErrors.billing.cvv;
    parsedErrors.creditCardInformation.expirationMonth = responseErrors.billing.expirationMonth;
    parsedErrors.creditCardInformation.expirationYear = responseErrors.billing.expirationYear;

    return parsedErrors;
}
class PaymentCtrl {

    constructor($scope, $rootScope, $http, $window, $location, blockUI, CheckoutService, CommonUtils, CheckoutConst) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$window = $window;
        this.$location = $location;
        this.blockData = blockUI.instances.get('block-data');

        //models:
        this.businessInformation = {name: '', phone: '', category: {}, address: '', city: '', zipcode: '', state: '', country: 'US'};
        this.contactInformation = {firstName: '', lastName: '', email: '', password: '', passwordConfirm: '', mobilePhone: ''};
        this.creditCardBillingAddress = {isUseBusinessAddress: false, companyName: '', address: '', city: '', zipcode: '', state: '', country: 'US'};
        this.creditCardInformation = {firstName: '', lastName: '', name: '', number: '', cvv: '', isAgree: false};
        this.cardSummary = {originalPrice: 49, packageType: 'Premium Powerlistings', termType: 'Monthly'};
        this.businessSlug = '';

        this.states = [];
        this.categories = {};
        this.errors = {businessInformation: {}, contactInformation: {}, creditCardBillingAddress: {}, creditCardInformation: {}, category: {}};
        this.notValidationError = null;
        this.callUsMessage = null;
        this.bannerMessage = null;
        this.paymentSubmitted = false;

        //scopes:
        $scope.months = CheckoutConst.monthsValues;
        $scope.years = CheckoutService.genYears();
        $scope.countries = [
            { code: 'US', name: 'USA' },
            { code: 'CA', name: 'Canada' },
            { code: 'UK', name: 'United Kingdom' },
            { code: 'AU', name: 'Australia' },
        ];

        $scope.isMobile = () => {
            return CommonUtils.isMobile();
        };

        this.onSelectCategories = function(id) {
            this.loadCategories(id);
        }.bind(this);

        this.loadCategories = (parentId, level, limit)=>{
            let $this = this,
                $currentScope = $scope,
                params = {
                    limit: limit || 10000,
                    level: level,
                    parentId: parentId
                };

            $this.$http.get('/category', {params: params})
            .then(onSuccess)
            .catch(onError);

            function onSuccess(response) {
                const hideCategoriesNames = ['All'];
                if(response.data.count > 0){
                    let rows = response.data.rows.filter(item => hideCategoriesNames.indexOf(item.name) === -1);
                    $this.categories[_.get(_.head(rows), 'syphLevel', 0)] = rows;
                }
            }

            function onError(error) {
                console.log(error);
            }
        };

        this.loadCategories(null, 0);

        PaymentCtrl.init();
    }

    static init() {
    }

    setStates(states) {
        this.states = JSON.parse(states);
    }

    setBusinessAddress() {
        //if set as business address, update values for billing:
        if (this.creditCardBillingAddress.isUseBusinessAddress) {
            this.creditCardBillingAddress.companyName = this.businessInformation.name;
            this.creditCardBillingAddress.address = this.businessInformation.address;
            this.creditCardBillingAddress.city = this.businessInformation.city;
            this.creditCardBillingAddress.country = this.businessInformation.country;
            this.creditCardBillingAddress.state = this.businessInformation.state;
            this.creditCardBillingAddress.zipcode = this.businessInformation.zipcode;
        }
    }

    /**
     * Create request for adding business
     */
    sendPayments(businessInformation, contactInformation, creditCardBillingAddress, creditCardInformation) {
        var $this = this;

        $this.businessSlug = '';
        $this.businessInformation.category = _.map($this.businessInformation.category);
        $this.errors = {businessInformation: {}, contactInformation: {}, creditCardBillingAddress: {}, creditCardInformation: {}};
        $this.notValidationError = null;
        $this.callUsMessage = null;
        $this.bannerMessage = null;

        //Validate input fields:
        let isValidData = validateInputPayment(businessInformation, contactInformation, creditCardBillingAddress, creditCardInformation, $this.errors);

        if (isValidData) {
            const paymentData = changedData(businessInformation, contactInformation, creditCardBillingAddress, creditCardInformation);

            $this.blockData.start();

            $this.$http.post('/payments/save', paymentData)
            .then((response) => {

                console.log(response);
//                $this.notValidationError = null;
                if(response.status === 200) {
                    if (_.isString(response.data.salesforce)) {
                        $this.bannerMessage = response.data.salesforce;
                    }

                    if (_.isString(response.data.yext)) {
                        $this.bannerMessage = response.data.yext;
                    }
                    $this.paymentSubmitted = true;
                    $this.businessSlug = response.data.business.Slug.slug;
                    $this.blockData.stop();

                } else {
                    $this.blockData.stop();
                }
            })
            .catch(function (response) {
                if (response.data && response.data.details && _.isEqual(response.data.details.isValid, false)
                    && !!response.data.details.business && !!response.data.details.user && !!response.data.details.billing) {
                    $this.errors = parseErrors(_.get(response, 'data.details', $this.errors));
                }

                if (!_.isEmpty(response.statusText)) {
                    $this.notValidationError = response.statusText;
                } else {
                    $this.notValidationError = _.get(response, 'data.message', 'Something went wrong');
                }

                if (response.data && response.data.details && response.data.details.billing && response.data.details.billing.billing) {
                    $this.notValidationError = _.first(response.data.details.billing.billing);
                }

                if (response.data && response.data.details && response.data.details.business && response.data.details.business.business) {
                    $this.notValidationError = _.first(response.data.details.business.business);
                }

                if (response.data && response.data.details && response.data.details.user && response.data.details.user.user) {
                    $this.notValidationError = _.first(response.data.details.user.user);
                }

                //if No Reason Error Phrase:
                if (_.isEmpty($this.notValidationError)
                    || _.isEqual($this.notValidationError, 'Validation error')
                    || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                    $this.callUsMessage = 'Please call us at 800-266-0219 to get assistance with your order';
                }

                console.error(response);
                $this.blockData.stop();
            });
        } else {
            $this.notValidationError = 'Wrong inputs';
        }

    }

    goToListingPage() {
        var $this = this;
        console.log('#goToListingPage was clicked');

        $this.blockData.start();
        $this.notValidationError = null;
        const credentialsData = {login: $this.contactInformation.email, password: $this.contactInformation.password, businessSlug: $this.businessSlug};

        $this.$http.post('/payments/signin', credentialsData)
        .then((response) => {

            console.log(response);
//            $this.notValidationError = null;
            $this.$rootScope.authData = response.data.user;

            setTimeout(() => {
                if (response.data.redirect) {
                    $this.$window.location.href = response.data.redirect;
//                    $this.$window.open(response.data.redirect, '_blank');
//                    $this.blockData.stop();
                } else {
                    window.location.reload();
                }
            }, 100);
//            $this.blockData.stop();
        })
        .catch(function (response) {
            if (response.statusText) {
                $this.notValidationError = response.statusText;
            }
            console.error(response);
            $this.blockData.stop();
        });

    }

}

export default PaymentCtrl;
