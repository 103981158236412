'use strict';

class ContactPageController {
    constructor($scope, $http, $window/*, blockUI*/) {
        this.$scope = $scope;
        this.$http = $http;
        this.$window = $window;
        this.blockData = {};//blockUI.instances.get('block-data');
        this.$scope.errors = {};
        this.$scope.emailStatus = false;
        this.$scope.businesses = {};
    }

    /**
     *  Submit contact form
     */
    submit() {
        var ctrl = this;
        var business = ctrl.$scope.business;
        //clear validation errors
        this.$scope.errors = {};


        //validate
        var valid = ContactPageController.validate(business, this.$scope.errors);

        if (!valid) {
            return;
        }
        // business.captcha_response = this.$window.$("#captcha_response").val();
        business.captcha_response = $("#captcha_response").value;

        ctrl.$http.post('/contact/send-email', business)
            .success(function () {

                business.name = '';
                business.phone = '';
                business.email = '';
                business.contactName = '';
                business.comment = '';

                ctrl.$scope.emailStatus = true;
                ctrl.$window.grecaptcha.reset(rc);
            })
            .error(function (error) {
                ctrl.$scope.errors.name = ['Unexpected error. Please try again.'];
                console.error(error);
            })
    }

    /**
     * Validate contact form
     * @param business
     * @param errors
     */
    static validate(business, errors) {
        let isValid = true;
        if (!business.name || business.name.length < 3) {
            errors.name = ['You have given an answer shorter than 3 characters.'];
            isValid = false;
        }

        var RegExPattern = /[\w\-+.]{3,}@([\w-]{2,}\.)*([\w-]{2,}\.)[\w-]{2,4}/;
        if (!business.email || !business.email.match(RegExPattern)) {
            errors.email = ['You have not given a correct e-mail address.'];
            isValid = false;
        }

        return isValid;
    }
}

export default ContactPageController;