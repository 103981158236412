'use strict';

import _ from 'lodash';

//todo: https://trello.com/c/pZGPRb5I/756-add-business-page-v2-server-side
class AddBusinessCtrl {
    constructor($scope, $http, $window, blockUI) {
        this.$scope = $scope;
        this.$http = $http;
        this.$window = $window;
        this.blockData = blockUI.instances.get('block-data');

        this.submitted = false;
        this.isMyBusiness = false;
        this.authData = {};
        this.isNotMyBusiness = false;
        this.states = [];
        this.defCategory = {};
        this.categories = {};
        this.selectedCategories = {};
        this.selectedCategory = {};
        this.user = {email: ''};
        this.business = {validationEmail: '', email: '', countryCode: '', skipValidation: false};
        this.errors = {};


        this.onSelectCategories = function(id) {
            this.loadCategories(id);
        }.bind(this);


        this.isAuth = () => {
            let $this = this;
            $http.get('/auth')
                .then(function (response) {
                    if(!!response.data.authData){
                        $this.authData = response.data.authData;
                    }
                })
                .catch(function (error) {
                })
        };

        this.isAuth();

        this.loadCategories = (parentId, level, limit)=>{
            let $this = this,
                params = {
                    limit: limit || 10000,
                    level: level,
                    parentId: parentId
                };

            $this.$http.get('/category', {params: params})
                .then(onSuccess)
                .catch(onError);



            function onSuccess(response) {
                const hideCategoriesNames = ['All'];
                if(response.data.count > 0){
                    let rows = response.data.rows.filter(item => hideCategoriesNames.indexOf(item.name) === -1);
                    $this.categories[_.get(_.head(rows), 'syphLevel', 0)] = rows;
                }
            }

            function onError(error) {
                console.log(error);
            }
        };

        this.loadCategories(null, 0);
    }

    setDefCategory(id, name) {
        this.defCategory = {id: id, name: name};
    }

    scrollToBottom(time, height){
      const $this = this;
      const duration = time*1000;
      const scrollTo = window.pageYOffset + height;
      $("body, html").animate({scrollTop: scrollTo}, duration);
    }

    skipValidation(checked) {
        this.business.skipValidation = checked;
    }

    setStates(states) {
        this.states = JSON.parse(states);
    }

    /**
     * Create request for adding business
     */
    sendBusiness(ownBusiness) {
        var $this = this;

        this.blockData.start();

        $this.business.ownBusiness = ownBusiness;
        /*if (!ownBusiness) {
            $this.business.categories = [$this.defCategory, {}, {}, {}];
        }*/

        $this.business.categories = _.map($this.selectedCategory);

        $this.business.requestToAdd = true;
        $this.business.isOwner = ownBusiness;
        $this.business.approved = true;

        if($this.business.skipValidation){
            $this.business.additionalData = {
                skip: {
                    user : $this.authData.email,
                    reason : 'failed api check',
                    skipped : true
                }
            };
        }

        $this.owner = null;
        $this.errors = {};

        //Validate the email format for contact.
        var emailForValidationCheck = ownBusiness ? $this.user.email : $this.business.validationEmail;
        if (_.isEmpty(emailForValidationCheck)) {
            $this.errors.email = ['Email is empty'];
        } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailForValidationCheck)) {
            $this.errors.email = ['Invalid email format'];
        }

        var businessObject = _.clone($this.business);
        businessObject.sourceFile = 'PR-add-' + (ownBusiness ? 'own' : 'neighbor');
        if (ownBusiness) {
            businessObject.user = _.clone($this.user);

            //magic: we must have both first and last names
            var firstName = businessObject.user.firstName ? businessObject.user.firstName.trim() : '';
            if (firstName && firstName.indexOf(' ') > 0) {
                var index = firstName.indexOf(' ');
                businessObject.user.lastName = firstName.substring(index + 1, firstName.length);
                businessObject.user.firstName = firstName.substring(0, index);
            } else {
                businessObject.user.lastName = firstName;
            }
        }

        //needs validate business name for apostrophe:
        businessObject.isNeedCheckApostrophe = true;

        if (!_.isEmpty($this.errors)) {
            businessObject.errors = _.clone($this.errors);
        }

        $this.$http.post('/business/save', businessObject)
        .then((response) => {

            // Successfully added business, tag event in Localytics
            // ll('tagEvent', 'Add a Business', {'Business ID': response.data.businessId, 'Location': businessObject.zipcode});
            // Successfully added business, tag event in Hubspot
//            var _hsq = window._hsq = window._hsq || [];
//            _hsq.push(['trackEvent', {
//                id: '000005943354',
//                value: {'Business ID': response.data.businessId, 'Location': businessObject.zipcode}
//            }]);

            if (ownBusiness) {
                // Tag as a 'claim business' event if the user owns the business
                // ll('tagEvent', 'Claim a Business', {'Business ID': response.data.businessId, 'Location': businessObject.zipcode, 'Claim Type': 'AAB'});
//                _hsq.push(['trackEvent', {
//                    id: '000005941336',
//                    value: {'Business ID': response.data.businessId, 'Location': businessObject.zipcode, 'Claim Type': 'AAB'}
//                }]);
            }

//                //index new created business:
//                try {
//                    if (window.location.href.startsWith('https://pr.business' && response.data.businessId)) {
//                        $this.$http.post('/google-indexing/update', {businessId: response.data.businessId})
//                        .then(res => {
//                            console.log('Indexing is successful');
//                        })
//                        .catch(error => {
//                            console.error(error);
//                        });
//                    }
//                } catch (err) {}

            if(response.data.redirect){
                // $this.submitted =true;
                $this.$window.location.href = response.data.redirect;

            }else{
                $this.$window.location = '/';
            }

            $this.blockData.stop();
        })
        .catch(function (response) {
            $this.errors = _.get(response, 'data.details.errors', $this.errors);
            console.error(response);
            $this.blockData.stop();
        });

    }







}

export default AddBusinessCtrl;
