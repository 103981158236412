'use strict';

import _ from 'lodash';

function validateCreatePasswordInformation(createPasswordData, errors) {
    let validationResult = true;

    // PASSWORD VALIDATION
    if (_.isEmpty(createPasswordData.password)) {
        validationResult = false;
        errors.password = ['Password is empty'];
    }

    // PASSWORD CONFIRM VALIDATION
    if (_.isEmpty(createPasswordData.passwordConfirm)) {
        validationResult = false;
        errors.passwordConfirm = ['Confirm is empty'];
    } else if (createPasswordData.passwordConfirm !== createPasswordData.password) {
        validationResult = false;
        errors.passwordConfirm = ['Passwords are different'];
    }

    return validationResult;
}

export default class UserCtrl {

    constructor($scope, $rootScope, $http, $cookies, $window, $location, blockUI) {
        var $this = this;

        this.blockData = blockUI.instances.get('block-data');
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$cookies = $cookies;
        this.$window = $window;
        this.$location = $location;
        this.login = '';
        this.user = {};
        this.errors = {};

        //popup settings
        this.fromShow = {
            forgot: false,
            registration: false,
            login: false,
            claimLogin: false
        };


        var reviewToken = $location.search().token;

        if(this.$rootScope.authData && reviewToken  ){
            var window = $window;

                $http.post('/review/token', {token: reviewToken})
                    .then((response) => {
                        window.location.href = '/' + response.data.redirectUrl;
                }).catch(function (response) {

                });
        }else{
            this.changeFormPopup(this.$scope.typeForm);
        }

        /**
         * init create password data
         */
        this.initCreatePassword = (tokenUserData) => {
            let user = {},
                tokenId = null;

            try {
                const parsedTokenUserData = JSON.parse(tokenUserData);
                user = parsedTokenUserData.user;
                tokenId = _.get(parsedTokenUserData, 'token.id', null);
            } catch (err) {
                console.error(err);
            }

            $this.createPasswordData = {
                id: user.id,
                email: user.email,
                password: '',
                passwordConfirm: '',
                tokenId: tokenId
            }
        }

    }

    /**
     * Login user
     */
    signIn() {
        var $this = this;
        $this.blockData.start();
        $this.errors.login = null;
        var token = $this.$location.search().token;
        $this.$http.post('/signin', {login: $this.login, password: $this.password, token: token})
            .then(function (response) {

                // ll('tagEvent','Sign In',{'Method': 'Email','Successful':'Yes', 'Error': 'N/A'});
                // ll('setCustomerId', response.data.user.id);
                // ll('setCustomerName', response.data.user.firstName + ' ' + response.data.user.lastName);
                // ll('setCustomerEmail', response.data.user.email);
//                var _hsq = window._hsq = window._hsq || [];
//                _hsq.push(['trackEvent', {
//                    id: '000005943356',
//                    value: {'Method': 'Email','Successful':'Yes', 'Error': 'N/A'}
//                }]);
//                _hsq.push(['identify', {
//                    customerId: response.data.user.id,
//                    customerName: response.data.user.firstName + ' ' + response.data.user.lastName,
//                    customerEmail: response.data.user.email
//                }]);

                $this.$rootScope.authData = response.data.user;
                if ($this.$scope.credentialsCallback) {
                    return $this.$scope.credentialsCallback(response.data.user)
                }
                //hide sigin panel when we are in review's box
                if (!!_.get($this, '$scope.$parent.$parent.signInPanel')) {
                    $this.$scope.$parent.$parent.signInPanel = false;
                }

                $this.closePopup();
                setTimeout(() => {
                    if (angular.element('#reviewSubmitButton')
                        && angular.element('#reviewSubmitButton').scope()) {
                        angular.element('#reviewSubmitButton').scope().$parent.sendReview(true);
                    }
                    setTimeout(() => {
                        if (response.data.redirect) {
                            $this.$window.location.href = response.data.redirect;
                        } else {
                            window.location.reload();
                        }
                    }, 100);
                }, 100);
            })
            .catch(function (response) {
                // ll('tagEvent','Sign In',{'Method': 'Email','Successful':'No', 'Error': response.data.errors[0]});
//                var _hsq = window._hsq = window._hsq || [];
//                _hsq.push(['trackEvent', {
//                    id: '000005943356',
//                    value: {'Method': 'Email','Successful':'No', 'Error': response.data.errors[0]}
//                }]);
                $this.errors.login = response.data.errors;
                $this.blockData.stop();
            });
    }


    /**
     * Register new user
     */
    signUp() {
        var $this = this,
            userData = $this.user,
            token = $this.$location.search().token;
        $this.blockData.start();
        $this.errors = {};
        let isReviewSubmitForm = (angular.element('#reviewSubmitButton') && angular.element('#reviewSubmitButton').scope());
        $this.$http.post('/signup', {
                data: userData,
                token: token,
                fromReviewSubmit: (isReviewSubmitForm ? true : null)
            })
            .then((response) => {
                // ll('tagEvent','Registration',{'Method': 'Email','Successful':'Yes', 'Error': 'N/A'});
//                var _hsq = window._hsq = window._hsq || [];
//                _hsq.push(['trackEvent', {
//                    id: '000005943357',
//                    value: {'Method': 'Email','Successful':'Yes', 'Error': 'N/A'}
//                }]);

                $this.$rootScope.authData = response.data.user;
                if ($this.$scope.credentialsCallback) {
                    return $this.$scope.credentialsCallback(response.data.user)
                }
                setTimeout(() => {
                    if (isReviewSubmitForm) {
                        angular.element('#reviewSubmitButton').scope().$parent.sendReview(true);
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }, 100);
            })
            .catch(response => {
                // ll('tagEvent','Registration',{'Method': 'Email','Successful':'No', 'Error': response.data.errors[0]});
//                var _hsq = window._hsq = window._hsq || [];
//                _hsq.push(['trackEvent', {
//                    id: '000005943357',
//                    value: {'Method': 'Email','Successful':'No', 'Error': response.data.errors[0]}
//                }]);

                $this.errors = response.data.errors;
                $this.blockData.stop();
            });

    }

    /**
     * Reset password
     */
    forgotPassword() {
        var $this = this;
        var userEmail = $this.user.email;
        $this.errors = {};
        $this.$http.post('/forgotpass', {email: userEmail})
            .then(response => {
                $this.message = response.data.message;
                // ll('tagEvent', 'Forgot Password');

//                var _hsq = window._hsq = window._hsq || [];
//                _hsq.push(['trackEvent', {
//                    id: '000005941339',
//                    value: null
//                }]);
            })
            .catch(function (response) {
                $this.errors = response.data.errors;
            });
    }

    /**
     * New Password
     */
    resetPassword() {
        var $this = this;

        var paths = $this.$window.location.pathname.split('/'),
            token = paths[paths.length - 1];

        var user = {
            password: $this.user.password,
            resetToken: token,
            confirmPassword: $this.user.confirmPassword

        };
        $this.errors = {};
        $this.$http.post('/resetpass', user)
            .then(response => {
                $this.user = {};
                $this.message = response.data.message;
            })
            .catch(function (response) {
                $this.errors = response.data.errors;
            });

    }

    /**
     * Open from parameters
     * @param {string} typeFormOpen Can be (login | registration | forgot)
     * default open login
     */
    changeFormPopup(typeFormOpen) {
        var $this = this;
        $this.errors = {};
        if (!typeFormOpen) {
            typeFormOpen = 'login'
        }

        for (var field in  $this.fromShow) {
            if ($this.fromShow.hasOwnProperty(field)) {
                $this.fromShow[field] = field === typeFormOpen;
            }
        }
    };

    /**
     * Close popup after login
     */
    closePopup() {
        var $this = this;
        $this.$scope.modalInstance.close();
    };

    sendCreatePassword(createPasswordData) {
        var $this = this;

        $this.errors = null;
        $this.commonCreatePasswordError = null;

        const isValidData = validateCreatePasswordInformation(createPasswordData, $this.errors);

        if (isValidData) {
            $this.$http.post('/createpassword', createPasswordData)
            .then(response => {
                $this.message = response.data.message;
                $this.$window.location.href = response.data.dashRedirectUrl;
            })
            .catch(function (response) {
                console.error(response);
                $this.errors = response.data.errors;
                $this.commonCreatePasswordError = 'Please verify your entered information one more time.';
            });
        } else {
            $this.commonCreatePasswordError = 'Please verify entered information. Invalid fields are marked in red';
        }
    }

}
