import Chart from 'chart.js';
import _ from 'lodash';

function RatingsChart() {
    Chart.defaults.global.legend.display = false;
    Chart.defaults.global.defaultFontFamily = "Lato,sans-serif";
    Chart.defaults.global.defaultFontSize = 12;
    Chart.defaults.global.fontColor = '#333';

    return {
        template: `
            <div class="rating-bar" style="height: 508px; width:408px; margin: 0 auto;">
                <canvas id="ratingsChartId" style="height: 508px; width:408px"> </canvas>
                <div class="ratings-star">
                    <div class="start-first">5</div>
                    <div>4</div>
                    <div>3</div>
                    <div>2</div>
                    <div>1</div>
                </div>
            </div>`,
        restrict: 'E',
        scope: {
            allReviews: '=',
        },
        link: function(scope, element, attrs) {
            scope.dataViews = [0, 0, 0, 0, 0];

            let data = {
                    datasets: [
                        {
                            backgroundColor: '#0089e5',
                            borderWidth: 1,
                            data: scope.dataViews
                        }
                    ],
                },
                ctx = element.find('#ratingsChartId')[0],

                onCompleteAnimation = function () {
                    let chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.fillStyle = 'white';
                    ctx.textBaseline = 'bottom';

                    this.data.datasets.forEach(function (dataset, i) {
                        let meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            let data = dataset.data[index];
                            ctx.fillText(data, bar._model.x, bar._model.y);
                        });
                    });
                },

                options = {
                    events: false,
                    tooltips: {
                        enabled: false
                    },
                    hover: {
                        animationDuration: 0
                    },
                    animation: {
                        duration: 1,
                        onComplete: onCompleteAnimation
                    },

                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                steps: 10,
                                stepValue: 5,
                                max: 100
                            }
                        }],

                        xAxes: [{
                            display: true,
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }
                        }]
                    }
                };

            let ratingsChart = new Chart(ctx, {
                type: "bar",
                data: data,
                options: options
            });


            scope.$watch('allReviews', function (newValue, oldValue) {
                scope.dataViews.fill(0);

                _.map(scope.allReviews, item => {
                    item.reduce((prev, current)=>
                        scope.dataViews[current.rating - 1] = scope.dataViews[current.rating - 1] + current.quantity, scope.dataViews);
                });

                scope.dataViews.reverse();

                ratingsChart.update()
            }, true);
        }
    }
}

// class RatingsChart {
//     constructor($scope, $element, $interval) {
//         this.template = `
//             <div class="rating-bar" style="height: 508px; width:408px; margin: 0 auto;">
//                 <canvas id="ratingsChartId" style="height: 508px; width:408px"> </canvas>
//                 <div class="ratings-star">
//                     <div class="start-first">5</div>
//                     <div>4</div>
//                     <div>3</div>
//                     <div>2</div>
//                     <div>1</div>
//                 </div>
//             </div>
//             `;
//         this.restrict = 'E';
//         this.scope = {
//             allReviews: '=',
//         };
//
//         Chart.defaults.global.legend.display = false;
//         Chart.defaults.global.defaultFontFamily = "Lato,sans-serif";
//         Chart.defaults.global.defaultFontSize = 12;
//         Chart.defaults.global.fontColor = '#333';
//     }
//
//     link(scope, element, attrs) {
//         scope.dataViews = [0, 0, 0, 0, 0];
//
//         let data = {
//                 datasets: [
//                     {
//                         backgroundColor: '#0089e5',
//                         borderWidth: 1,
//                         data: scope.dataViews
//                     }
//                 ],
//             },
//             ctx = element.find('#ratingsChartId')[0],
//
//             onCompleteAnimation = function () {
//                 let chartInstance = this.chart,
//                     ctx = chartInstance.ctx;
//                 ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
//                 ctx.textAlign = 'center';
//                 ctx.fillStyle = 'white';
//                 ctx.textBaseline = 'bottom';
//
//                 this.data.datasets.forEach(function (dataset, i) {
//                     let meta = chartInstance.controller.getDatasetMeta(i);
//                     meta.data.forEach(function (bar, index) {
//                         let data = dataset.data[index];
//                         ctx.fillText(data, bar._model.x, bar._model.y);
//                     });
//                 });
//             },
//
//             options = {
//                 events: false,
//                 tooltips: {
//                     enabled: false
//                 },
//                 hover: {
//                     animationDuration: 0
//                 },
//                 animation: {
//                     duration: 1,
//                     onComplete: onCompleteAnimation
//                 },
//
//                 scales: {
//                     yAxes: [{
//                         display: true,
//                         ticks: {
//                             beginAtZero: true,
//                             steps: 10,
//                             stepValue: 5,
//                             max: 100
//                         }
//                     }],
//
//                     xAxes: [{
//                         display: true,
//                         gridLines: {
//                             color: "rgba(0, 0, 0, 0)",
//                         }
//                     }]
//                 }
//             };
//
//
//
//         let ratingsChart = new Chart(ctx, {
//             type: "bar",
//             data: data,
//             options: options
//         });
//
//
//         scope.$watch('allReviews', function (newValue, oldValue) {
//             scope.dataViews.fill(0);
//
//             _.map(scope.allReviews, item => {
//                 item.reduce((prev, current)=>
//                     scope.dataViews[current.rating - 1] = scope.dataViews[current.rating - 1] + current.quantity, scope.dataViews);
//             });
//
//             scope.dataViews.reverse();
//
//             ratingsChart.update()
//         }, true);
//
//     }
//
//     static directiveFactoryRatingsChart(){
//         RatingsChart.instance = new RatingsChart();
//         return RatingsChart.instance;
//     }
//
// }

export default RatingsChart;


