/**
 * Workaround for transpiling: pass 'angular' into 3rd party plugins
 */

require('angular');

module.exports = {
    blockUI: require("blockUI/dist/angular-block-ui.min"),
    bootstrapSelect: require('bootstrap-select/dist/js/bootstrap-select.min'),
    bowser: require('bowser/src/bowser'),
    slickCarousel: require('angular-slick-carousel/dist/angular-slick.min'),
    slickSlider: require('slick-slider/slick/slick.min'),
    ngMaterial :  require('angular-material'),
    ngMessages :  require("angular-messages/angular-messages.min.js"),
    ngAutocomplete: require("../search/autocomplete"),
    scoreGauge: require('./scoreGauge.module'),
    scoreBreakdownGauge: require('./scoreBreakdownGauge.module'),
    prRating: require('./rating.module'),
    hoursInfo: require('./hoursInfo.module'),
    adGoogleCustomSearch: require('./gsc'),
    renderGroupedReviewTable: require('./renderGroupedReviewTable'),
    renderGroupedReviewTableFoodMenu: require('./renderGroupedReviewTableFoodMenu'),
    renderGroupedReviewTableTextMessage: require('./renderGroupedReviewTableTextMessage'),
    renderGroupedReviewTableIMapping: require('./renderGroupedReviewTableIMapping'),
    renderGroupedReviewTableWebsitePerformance: require('./renderGroupedReviewTableWebsitePerformance'),
    reviews: require('./reviews.module'),
    scriptHelper: require('./scriptHelper.module'),
    checkout: require('./checkout.module'),
    numberLen: require('./numberLen.module'),
    agentVerify: require('./agentVerify.module')
};
// module.exports = {
//     blockUI: require("github:McNull/angular-block-ui@0.2.2/dist/angular-block-ui.min"),
//     bootstrapSelect: require('github:silviomoreto/bootstrap-select@1.12.2/dist/js/bootstrap-select.js'),
//     bowser: require('github:lancedikson/bowser@1.0.0/bowser.min.js'),
//     slickCarousel: require('github:devmark/angular-slick-carousel@3.1.7/dist/angular-slick.min'),
//     slickSlider: require('github:kenwheeler/slick@1.8.1/slick/slick.min'),
//     ngMaterial :  require('angular-material'),
//     ngMessages :  require("github:angular/bower-angular-messages@1.5.3/angular-messages.min.js"),
//     ngAutocomplete: require("../search/autocomplete"),
//     scoreGauge: require('./scoreGauge.module'),
//     scoreBreakdownGauge: require('./scoreBreakdownGauge.module'),
//     prRating: require('./rating.module'),
//     hoursInfo: require('./hoursInfo.module'),
//     adGoogleCustomSearch: require('./gsc'),
//     renderGroupedReviewTable: require('./renderGroupedReviewTable'),
//     renderGroupedReviewTableFoodMenu: require('./renderGroupedReviewTableFoodMenu'),
//     renderGroupedReviewTableTextMessage: require('./renderGroupedReviewTableTextMessage'),
//     renderGroupedReviewTableIMapping: require('./renderGroupedReviewTableIMapping'),
//     reviews: require('./reviews.module'),
//     scriptHelper: require('./scriptHelper.module'),
//     checkout: require('./checkout.module'),
//     numberLen: require('./numberLen.module'),
//     agentVerify: require('./agentVerify.module')
// };
