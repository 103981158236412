'use strict';

const GEOSEARCH_RADIUS = 100000; //100 miles

class SearchCtrl {

    /*@ngInject*/
    constructor($window, $scope, $rootScope, $http, $cookies, $uibModal, $location, CommonUtils, SearchService) {
        SearchCtrl.init();

        var $this = this;
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$cookies = $cookies;
        this.$uibModal = $uibModal;
        this.$window = $window;
        this.$scope = $scope;
        this.$location = $location;
        this.maxSize = 5;

        this.cookieDomain = (this.$window.location.host === 'www.pr2.local' || this.$window.location.host === 'pr2.local') ? '.pr2.local' : '.pr.business';

        this.isMobile = () => {
            return CommonUtils.isMobile();
        };
        this.isTablet = () => {
            return CommonUtils.isTablet();
        };

        this.normalizeUrl = (url) => {
            return CommonUtils.normalizeUrl(url);
        };

        /** @deprecated */
        this.trackingPixel = (business) => {
            if (business.YextListing) {
                SearchService.clickTracking(business.YextListing.yextId);
            }
        };

        this.iframeLogout = () => {
            $cookies.remove("access_token", {domain: $this.cookieDomain, path: '/'});
            $rootScope.authData = null;
        }

    }

    static init() {
        this.businesses = [];
        this.page = 1;
        this.openMenu = false;
        this.openIframeMenu = false;

        this.searchNearbyBusinesses = false;
    }

    initGeolocation() {
        var $this = this;

        //works only via https:
        if (window.location.href.match('https:') && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){

                if (!!position && position.coords) {
                    $this.geoCoordinates = `${position.coords.latitude},${position.coords.longitude}`;
                    // $this.searchNearbyBusinesses = true;
                }
            });
        }
       // else $this.geoCoordinates = `28.0337096,-97.0830851`; // only for testing, delete it
    }

    signInPopup(typeFormOpen) {
        // params across scope to popup
        this.$scope.typeForm = typeFormOpen;

        if (this.$scope.modalInstance) {
            this.$scope.modalInstance.close();
        }

        this.$scope.modalInstance = this.$uibModal
            .open({
                templateUrl: '/public/templates/signInPopup.tpl.html',
                size: 'login-panel-form',
                keyboard: true,
                bindToController: true,
                controller: 'UserCtrl',
                controllerAs: 'uc',
                scope: this.$scope
            });
    };

    toggleMenu($event, force) {
        if ($event) {
            if (typeof force === "undefined") {
                this.openMenu = !this.openMenu;
            } else {
                this.openMenu = force;
            }
        }
    };

    toggleIframeMenu($event, force) {
        const $this = this;
        if ($event) {
            if (typeof force === "undefined") {
                $this.openIframeMenu = !this.openIframeMenu;
            } else {
                $this.openIframeMenu = force;
            }
        }
    };

    toggleOffIframeMenu($event) {
        const $this = this;
        if ($event && (!$event.target || $event.target.localName !== "span")) {
            $this.openIframeMenu = false;
        }
    };

    /**
     * Init data for Search Controller
     */
    uiInit(businesses, page, limit, totalItems, query, location, category, geoCoordinates) {
        if (businesses) {
            this.businesses = businesses.list;
        }
        if (page) {
            this.page = page;
            this.initialPage = page;
        }
        if (limit) {
            this.limit = limit;
        }
        if (totalItems) {
            this.totalItems = totalItems;
        }
        if (query) {
            this.query = query.replace(/`/g, "'");
        }
        if (location) {
            this.location = location;
        }
        if (category) {
            this.category = category;
        }
        if (geoCoordinates) {
            this.geoCoordinates = geoCoordinates;
            this.searchNearbyBusinesses = true;
        }
    }

    watchUseNearbySearch() {
        return !!this.geoCoordinates;
    }

    /**
     * Prepare new search-URL and reload page
     */
    search() {
        var pathname = this.$window.location.pathname,
            query = !!this.query ? this.query.replace(/\//g, "-") : '';

        var geoSearchAppend = '';

        if (this.searchNearbyBusinesses && this.geoCoordinates) {
            geoSearchAppend = `geodata=true&latlng=${this.geoCoordinates}&radius=${GEOSEARCH_RADIUS}`;
        }

        var windowRedirectUrl = '';

        if (!pathname.startsWith('/c/')) {


//            this.$window.location = '/search/claim-results/' + (query || '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + (this.category ? ('?category=' + this.category) : '');
//            this.$window.location = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + (this.category ? ('?category=' + this.category) : '');
            windowRedirectUrl = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + (this.category ? ('?category=' + this.category) : '');
        } else {
            if (query) {
//                this.$window.location = '/search/claim-results/' + (query || '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + ( this.category ? ('?category=' + this.category) : '');
//                this.$window.location = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + ( this.category ? ('?category=' + this.category) : '');
                windowRedirectUrl = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + ( this.category ? ('?category=' + this.category) : '');
            } else {
//                this.$window.location = '/c/' + this.category + '/' + (this.location || '-') + '/page-' + (this.page || 1);
                windowRedirectUrl = '/c/' + this.category + '/' + (this.location || '-') + '/page-' + (this.page || 1);
            }
        }

        if (!!geoSearchAppend) {
            windowRedirectUrl = windowRedirectUrl.includes('?') ? `${windowRedirectUrl}&${geoSearchAppend}` : `${windowRedirectUrl}?${geoSearchAppend}`;
        }

        this.$window.location = windowRedirectUrl;
        this.page = this.initialPage;
    }


    searchNearby() {
        var pathname = this.$window.location.pathname,
            query = !!this.query ? this.query.replace(/\//g, "-") : '';

        var geoSearchAppend = '';

        if (this.geoCoordinates) {
            // this.searchNearbyBusinesses = true;
            geoSearchAppend = `geodata=true&latlng=${this.geoCoordinates}&radius=${GEOSEARCH_RADIUS}`;
        }

        var windowRedirectUrl = '';

        if (!pathname.startsWith('/c/')) {
            windowRedirectUrl = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + (this.category ? ('?category=' + this.category) : '');
        } else {
            if (query) {
                windowRedirectUrl = '/search/claim-results/' + (query ?  encodeURIComponent(query) : '-') + '/' + (this.location || '-') + '/page-' + (this.page || 1) + ( this.category ? ('?category=' + this.category) : '');
            } else {
                windowRedirectUrl = '/c/' + this.category + '/' + (this.location || '-') + '/page-' + (this.page || 1);
            }
        }

        if (!!geoSearchAppend) {
            windowRedirectUrl = windowRedirectUrl.includes('?') ? `${windowRedirectUrl}&${geoSearchAppend}` : `${windowRedirectUrl}?${geoSearchAppend}`;
        }

        this.$window.location = windowRedirectUrl;
    }

    safeApply() {
        if (this.$scope.$root.$$phase != '$apply' && this.$scope.$root.$$phase != '$digest') {
            this.$scope.$apply();
        }
    };

    /**
     * Find my location by GoogleAPI and put name of city into field 'Location'
     */
    findMyLocation() {
        let geocoder = new google.maps.Geocoder();
        var that = this;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
        }

        function successFunction(position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            codeLatLng(lat, lng)
        }

        function errorFunction() {
            alert('Geocoder failed');
        }

        function codeLatLng(lat, lng) {
            var latlng = new google.maps.LatLng(lat, lng);
            geocoder.geocode({'latLng': latlng}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        var add = results[0].formatted_address;
                        var value = add.split(',');
                        var count = value.length;
                        //var country = value[count - 1];
                        var state = value[count - 2];
                        var city = value[count - 3];
                        if (city && state) {
                            that.location = city.trim();
                            that.safeApply();
                        }
                    }
                } else {
                    console.log('Geocoder failed due to: ' + status);
                }
            });
        }
    }

    logout() {
        // ll('tagEvent', 'Log Out');
//        var _hsq = window._hsq = window._hsq || [];
//        _hsq.push(['trackEvent', {
//            id: '000005941338',
//            value: null
//        }]);
        this.$window.location.href = '/logout';
    }
}

export default SearchCtrl;
