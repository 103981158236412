'use strict';
import _ from "lodash";

class Salesfctrlunnel {
  constructor($scope, $uibModal, $location, ListingService, CommonUtils) {
    var $this = this;

    this.businessId = '01ff11bd-e1c2-455f-88c9-cc681e44f0d6';
    this.$scope = $scope;
    this.sfctrl = $scope.sfctrl;
    this.sfctrl.reportData = { reports: [] };

    this.$scope.isMobile = () => {
      return CommonUtils.isMobile();
    };

    ListingService.loadReport(this.businessId, 'baseline')
      .then(response => {
        let report = _.head(response.data.report);

        //set filter not to use WhitePages and 411.com reports
        if (report && !_.isEmpty(report.report)) {
          report.report = _.filter(report.report,
            currentResult => (currentResult.siteId
              && currentResult.siteId !== 'WhitePages' && currentResult.siteId !== '411.com' ));
        }

        // $this.sfctrl.reviewsQuantity.prReviewsQuantity = response.data.prReviewsQuantity;
        $this.sfctrl.reportData = report;
        // $this.sfctrl.createdDateReport = response.data;
        $this.sfctrl.visibilityScore = ListingService.getVisibilityScore(report, $this.sfctrl.isBusUpgraded);
        // $this.sfctrl.mainScore = _.round($this.sfctrl.visibilityScore.score);
        // $this.sfctrl.napConsistancy = ~~_.round($this.sfctrl.visibilityScore.napConsistancy);
        // $this.sfctrl.napScoreColor = ListingService.getNAPScoreColor($this.sfctrl.mainScore);

        // $this.sfctrl.year = moment(report.createdAt).format('YYYY');
        // $this.sfctrl.month = moment(report.createdAt).format('MM');
        // $this.sfctrl.host = window.location.host;

        // $this.blockData.stop();
        console.log('response');
        console.log(this.sfctrl.reportData);
      })
      .catch(err => {
        $this.blockData.stop();
        console.log(err)
      });

    $this.sfctrl.isNotError = report => {
      return report && _.isEmpty(report.errors);
    };

    $this.sfctrl.checkStatus = (report)=> {
      let image = 'attention', color = 'red', fontWeight = 'bold';

      if (!report.match_address && !report.match_name && !report.match_phone) {
        image = 'close';
      } else if (report.match_address && report.match_phone && report.match_name) {
        image = 'check';
        color = 'black';
        fontWeight = ''
      }

      return {image: image, color: color, fontWeight: fontWeight};

    };

    $this.sfctrl.isUpgraded = isUpdated => {
      return isUpdated === 'true';
    };

    Salesfctrlunnel.init();
  }

  static init() {}
}

export default Salesfctrlunnel
