class DefaultReviewsData {

    emptyReviewDataV2() {
        return {
            "report": [
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "pr.business", "position": 1},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Amazon Alexa", "position": 2},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Apple Siri", "position": 3},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Microsoft Cortana", "position": 4},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Google Assistant", "position": 5},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Samsung Bixby", "position": 6},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bing", "position": 7},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Google", "position": 8},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yahoo", "position": 9},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GoogleMyBusiness", "position": 10},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yelp", "position": 11},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Foursquare", "position": 13},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "2FindLocal", "position": 14},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "8Coupons", "position": 15},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ABLocal", "position": 16},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AirYell", "position": 17},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Allonesearch", "position": 18},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "American Towns", "position": 19},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Banks.com", "position": 20},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bizwiki.com", "position": 21},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Brownbook.net", "position": 22},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ChamberofCommerce.com", "position": 23},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySearch", "position": 24},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySquares", "position": 25},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Credibility.com", "position": 26},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Cylex", "position": 27},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "eLocal", "position": 28},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ezLocal", "position": 29},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "FindOpen", "position": 30},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GetFave", "position": 31},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GoLocal247", "position": 32},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "HotFrog", "position": 33},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iBegin", "position": 34},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iGlobal", "position": 35},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "InsiderPages", "position": 36},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalDatabase", "position": 37},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Local.com", "position": 38},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalPages", "position": 39},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalStack", "position": 40},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MerchantCircle", "position": 41},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MyLocalServices", "position": 42},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "n49CA", "position": 43},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "OpenDI", "position": 44},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Point.com", "position": 45},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Postmates", "position": 46},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "PropertyCapsule", "position": 47},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ShowMeLocal", "position": 48},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "SuperPages", "position": 49},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "The Internet Chamber of Commerce", "position": 50},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Tupalo.com", "position": 51},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "USCity.net", "position": 52},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "VoteForTheBest", "position": 53},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "White & Yellow Pages", "position": 53},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yalwa", "position": 55},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yandex", "position": 56},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yasabe", "position": 57},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowMoxie.com", "position": 58},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPageCity.com", "position": 59},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPagesGoesGreen", "position": 60},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Zomato", "position": 61},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Apple Maps","position": 62},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Google Maps","position": 63},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bing Maps","position": 64},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MapQuest","position": 65},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Waze","position": 66},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AroundMe","position": 67},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "HERE","position": 68},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "NavMII","position": 69},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Telenav","position": 70},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "TomTom","position": 71},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "WhereTo","position": 72},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CarPlay","position": 73},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "D & B Database", "position": 72},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Factual", "position": 73},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Pitney Bowes", "position": 74},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Soleo", "position": 75}
            ]
        };
    }

    emptyFoodMenusReviewDataV2() {
        return {
            "foodMenusReport": [
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "pr.business", "position": 1},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Google Maps", "position": 2},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Facebook", "position": 3},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yelp", "position": 4},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "TripAdvisor", "position": 5},

                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AllergyEats", "position": 6},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Allmenus", "position": 7},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MenuPix", "position": 8},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Zomato", "position": 9},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bing", "position": 10},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Foursquare", "position": 11},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MapQuest", "position": 12},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YP", "position": 13},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "2FindLocal", "position": 14},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "8Coupons", "position": 15},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ABLocal", "position": 16},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "American Towns", "position": 17},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AroundMe", "position": 18},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bizwiki.com", "position": 19},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Brownbook.net", "position": 20},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ChamberofCommerce.com", "position": 21},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySearch", "position": 22},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySquares", "position": 23},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "D & B Database", "position": 24},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Cylex", "position": 25},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "eLocal", "position": 26},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ezLocal", "position": 27},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "FindOpen", "position": 28},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GetFave", "position": 29},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GoLocal247", "position": 30},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "HotFrog", "position": 31},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iBegin", "position": 32},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iGlobal", "position": 33},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "InsiderPages", "position": 34},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalDatabase", "position": 35},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Local.com", "position": 36},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalPages", "position": 37},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MerchantCircle", "position": 38},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MyLocalServices", "position": 39},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "n49CA", "position": 40},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "OpenDI", "position": 41},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ShowMeLocal", "position": 42},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "SuperPages", "position": 43},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "The Internet Chamber of Commerce", "position": 44},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "VoteForTheBest", "position": 45},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "WhereTo", "position": 46},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "WhiteYellowPages", "position": 47},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yalwa", "position": 48},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yasabe", "position": 49},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowMoxie.com", "position": 50},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPageCity.com", "position": 51},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPagesGoesGreen", "position": 52}
            ]
        };
    }

    emptyReviewData() {
        return {
            "report": [
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "pr.business", "position": 1},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Amazon Alexa", "position": 2},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Apple Siri", "position": 3},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Waze", "position": 4},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GoogleMyBusiness", "position": 5},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bing", "position": 6},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yelp", "position": 7},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yahoo", "position": 8},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "2FindLocal", "position": 9},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "8Coupons", "position": 10},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AirYell", "position": 11},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "White & Yellow Pages", "position": 53},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "abLocal", "position": 12},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "American Towns", "position": 13},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "AroundMe", "position": 14},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Bizwiki.com", "position": 15},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Brownbook.net", "position": 16},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ChamberofCommerce.com", "position": 17},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySearch", "position": 18},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CitySquares", "position": 19},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "CoPilot", "position": 20},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Cylex", "position": 22},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "eLocal", "position": 23},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ezLocal", "position": 24},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GetFave", "position": 25},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "GoLocal247", "position": 26},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "HotFrog", "position": 27},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iBegin", "position": 28},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "iGlobal", "position": 29},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "InsiderPages", "position": 30},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalDatabase", "position": 31},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalPages", "position": 32},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "LocalStack", "position": 33},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MapQuest", "position": 34},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MerchantCircle", "position": 35},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "MyLocalServices", "position": 36},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "n49CA", "position": 37},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "NavMII", "position": 38},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "OpenDI", "position": 39},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Point.com", "position": 40},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "ShowMeLocal", "position": 41},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Topix", "position": 42},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Tupalo", "position": 43},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "USCity.net", "position": 44},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "WhereTo", "position": 45},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Switchboard", "position": 60},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yalwa", "position": 47},
                // {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yasabe", "position": 48},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "Yellowise", "position": 49},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowMoxie.com", "position": 50},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPageCity.com", "position": 51},
                {"report": {"errors": [{"message": "Invalid API Key", "errorCode": 1}]}, "siteId": "YellowPagesGoesGreen", "position": 52}
            ]
        };
    }

    emptyYextReportData() {
        return [
            {
                "url": null,
                "siteId": "pr.business",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 1
            },
            {
                "url": null,
                "siteId": "Facebook",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 2
            },
            {
                "url": null,
                "siteId": "Yelp",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 3
            },
            {
                "url": null,
                "siteId": "Yahoo",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 4
            },
            {
                "url": null,
                "siteId": "2FindLocal",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 5
            },
            {
                "url": null,
                "siteId": "8Coupons",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 6
            },
            {
                "url": "",
                "siteId": "AirYell",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 7
            },
            {
                "url": "",
                "siteId": "White & Yellow Pages",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 8
            },
            {
                "url": "",
                "siteId": "abLocal",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 9
            },
            {
                "url": '',
                "siteId": "American Towns",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 10
            },
            {
                "url": null,
                "siteId": "AroundMe",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 11
            },
            {
                "url": null,
                "siteId": "Bizwiki.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 12
            },
            {
                "url": null,
                "siteId": "Brownbook.net",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 13
            },
            {
                "url": null,
                "siteId": "ChamberofCommerce.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 14
            },
            {
                "url": null,
                "siteId": "CitySearch",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 15
            },
            {
                "url": null,
                "siteId": "CitySquares",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 16
            },
            {
                "url": null,
                "siteId": "eLocal",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 17
            },
            {
                "url": null,
                "siteId": "ezLocal",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 18
            },
            {
                "url": null,
                "siteId": "GetFave",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 19
            },
            {
                "url": null,
                "siteId": "GoLocal247",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 20
            },
            {
                "url": null,
                "siteId": "HotFrog",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 21
            },
            {
                "url": null,
                "siteId": "InsiderPages",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 22
            },
            {
                "url": null,
                "siteId": "LocalDatabase",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 23
            },
            {
                "url": null,
                "siteId": "LocalPages",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 24
            },
            {
                "url": null,
                "siteId": "LocalStack",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 25
            },
            {
                "url": null,
                "siteId": "MapQuest",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 26
            },
            // {
            //     "url": null,
            //     "siteId": "MerchantCircle",
            //     "reviewCount": 0,
            //     "reviewRating": 0,
            //     "reviewScorePercentile": null,
            //     "reviewCountPercentile": null,
            //     "status": "LISTING_FOUND",
            //     "position": 27
            // },
            {
                "url": null,
                "siteId": "MyLocalServices",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 28
            },
            {
                "url": null,
                "siteId": "NavMII",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 29
            },
            {
                "url": null,
                "siteId": "OpenDI",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 30
            },
            {
                "url": null,
                "siteId": "Point.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 31
            },
            {
                "url": null,
                "siteId": "ShowMeLocal",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 32
            },
            {
                "siteId": "Topix",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 33
            },
            {
                "url": null,
                "siteId": "Tupalo",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 34
            },
            {
                "url": null,
                "siteId": "USCity.net",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 35
            },
            {
                "url": null,
                "siteId": "WhereTo",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 36
            },
            {
                "url": null,
                "siteId": "Yalwa",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 37
            },
            // {
            //     "url": null,
            //     "siteId": "Yasabe",
            //     "reviewCount": 0,
            //     "reviewRating": 0,
            //     "reviewScorePercentile": null,
            //     "reviewCountPercentile": null,
            //     "status": "LISTING_FOUND",
            //     "position": 38
            // },
            {
                "url": null,
                "siteId": "YellowMoxie.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 39
            },
            {
                "url": null,
                "siteId": "YellowPageCity.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 40
            },
            {
                "url": null,
                "siteId": "YellowPagesGoesGreen",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 41
            },
            {
                "url": null,
                "siteId": "Local.com",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 42
            },
            {
                "url": null,
                "siteId": "SuperPages",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 43
            },
            {
                "url": null,
                "siteId": "FourSquare",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 44
            },
            {
                "url": null,
                "siteId": "Bing",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 45
            },
            {
                "url": null,
                "siteId": "VoteForTheBest",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 46
            },
            {
                "url": null,
                "siteId": "CredibilityReview",
                "reviewCount": 0,
                "reviewRating": 0,
                "reviewScorePercentile": null,
                "reviewCountPercentile": null,
                "status": "LISTING_FOUND",
                "position": 47
            },
            // {
            //     "url": null,
            //     "siteId": "Kudzu",
            //     "reviewCount": 0,
            //     "reviewRating": 0,
            //     "reviewScorePercentile": null,
            //     "reviewCountPercentile": null,
            //     "status": "LISTING_FOUND",
            //     "position": 48
            // },
            // {
            //     "url": null,
            //     "siteId": "FindOpen",
            //     "reviewCount": 0,
            //     "reviewRating": 0,
            //     "reviewScorePercentile": null,
            //     "reviewCountPercentile": null,
            //     "status": "LISTING_FOUND",
            //     "position": 49
            // }
        ];
    }

    getPublisherNames() {
        return [
            {"siteId": "pr.business"},
            {"siteId": "Facebook"},
            {"siteId": "Yelp"},
            {"siteId": "Yahoo"},
            {"siteId": "2FindLocal"},
            {"siteId": "8Coupons"},
            {"siteId": "AirYell"},
            {"siteId": "White & Yellow Pages"},
            {"siteId": "abLocal"},
            {"siteId": "American Towns"},
            {"siteId": "AroundMe"},
            {"siteId": "Bizwiki.com"},
            {"siteId": "Brownbook.net"},
            {"siteId": "ChamberofCommerce.com"},
            {"siteId": "CitySearch"},
            {"siteId": "CitySquares"},
            {"siteId": "eLocal"},
            {"siteId": "ezLocal"},
            {"siteId": "GetFave"},
            {"siteId": "GoLocal247"},
            {"siteId": "HotFrog"},
            {"siteId": "InsiderPages"},
            {"siteId": "LocalDatabase"},
            {"siteId": "LocalPages"},
            {"siteId": "LocalStack"},
            {"siteId": "MapQuest"},
            // {"siteId": "MerchantCircle"},
            {"siteId": "MyLocalServices"},
            {"siteId": "NavMII"},
            {"siteId": "OpenDI"},
            {"siteId": "Point.com"},
            {"siteId": "ShowMeLocal"},
            {"siteId": "Topix"},
            {"siteId": "Tupalo"},
            {"siteId": "USCity.net"},
            {"siteId": "WhereTo"},
            {"siteId": "Yalwa"},
            // {"siteId": "Yasabe"},
            {"siteId": "YellowMoxie.com"},
            {"siteId": "YellowPageCity.com"},
            {"siteId": "YellowPagesGoesGreen"},
            {"siteId": "Local.com"},
            {"siteId": "SuperPages"},
            {"siteId": "FourSquare"},
            {"siteId": "Bing"},
            {"siteId": "VoteForTheBest"},
            {"siteId": "CredibilityReview"},
            {"siteId": "Amazon Alexa"},
            {"siteId": "Apple Siri"},
            {"siteId": "Waze"},

//            ,{"siteId": "Kudzu"},
//            {"siteId": "FindOpen"}
        ];
    }
}

export default DefaultReviewsData;
