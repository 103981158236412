import _ from 'lodash';

class SelectCategory {
    constructor($scope, $element, $interval) {
        this.template = `
            <div>
                <div class="styled-select_for_state" data-ng-repeat="(key, value) in categories">
                    <select data-ng-model="selectedCat" data-ng-options="item as item.name for item in value track by item.id" 
                     data-ng-change="onChangeCategory(selectedCat)">
                     <option selected="selected" style="display:none" value="">Select Category</option>
                    </select>
                </div>
            </div>
           
            `;
        this.restrict = 'E';
        this.scope = {
            categories: '=',
            selectedCategories: '=',
            onChangeAction: '&'
        };
    }

    link(scope, element, attrs) {

        scope.onChangeCategory = (selected)=>{
            let {syphLevel, id} = selected;

            scope.cleanPrev(syphLevel, scope.selectedCategories);
            scope.cleanPrev(syphLevel, scope.categories);
            scope.selectedCategories[syphLevel] = selected;
            scope.onChangeAction()(id);
        };

        scope.cleanPrev = (level, categories)=>{

            Object.keys(categories).forEach(key => {
                if((~~key) > level){
                    delete categories[key];
                }
            });
        }

    }


}

export default SelectCategory;


