/**
 * Attribute to make masked input: 10-digits phone number with format (xxx) xxx-xxxx
 */
import StringMask from 'string-mask';

const maskedPhone = new StringMask('(000) 000-0000');

class MaskInputPhone {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }

    link(scope, element, attrs, ctrl) {
        function formatter(value) {
            if (ctrl.$isEmpty(value)) {
                return value;
            }

            return applyPhoneMask(removeNonDigits(value));
        }

        function parser(value) {
            if (ctrl.$isEmpty(value)) {
                return value;
            }

            let formattedValue = applyPhoneMask(removeNonDigits(value));
            let actualValue = removeNonDigits(formattedValue);

            if (ctrl.$viewValue !== formattedValue) {
                ctrl.$setViewValue(formattedValue);
                ctrl.$render();
            }

            return actualValue;
        }

        function validator(value) {
            let valid = ctrl.$isEmpty(value) || value.length === 10;
            ctrl.$setValidity('phone', valid);
            return value;
        }

        /**
         * Remove not allowed characters
         * @param value phone number
         * @returns {*}
         */
        function removeNonDigits(value) {
            return value.replace(/[^0-9]/g, '');
        }

        /**
         * Apply format for phone number
         * @param value phone number
         * @returns {string}
         */
        function applyPhoneMask(value) {

            let formattedValue = maskedPhone.apply(value) || '';
            return formattedValue.trim().replace(/[^0-9]$/, '');
        }

        ctrl.$formatters.push(formatter);
        ctrl.$formatters.push(validator);
        ctrl.$parsers.push(parser);
        ctrl.$parsers.push(validator);
    }

}

export default MaskInputPhone;