class VideooModal {
    /* @ngInject */
    constructor() {
        this.templateUrl = '/public/templates/videoo.tpl.html';
        this.restrict = 'E';
        this.transclude= true;
        this.replace=true;
        this.scope = true;
    }

    link(scope, element, attrs) {
        scope.title = attrs.title;

        scope.$watch(attrs.visible, function(value){
            if(value == true)
                $(element).modal('show');
            else
                $(element).modal('hide');
        });

        $(element).on('shown.bs.modal', function(){
            scope.$apply(function(){
                scope.$parent[attrs.visible] = true;
            });
        });

        $(element).on('hidden.bs.modal', function(){
            scope.$apply(function(){
                scope.$parent[attrs.visible] = false;

                element.find('#modalPlayer').each(function(){
                    var src= $(VideooModal.instance).attr('src');
                    $(VideooModal.instance).attr('src',src);
                });
            });
        });
    }

    static directiveFactoryVideooModal(){
        VideooModal.instance = new VideooModal();
        return VideooModal.instance;
    }


}

export default VideooModal;