/**
 * Using this directive you can bind snippet which contains <script> tags
 * How to use:
 * 1) process you snippet with: script-utils.processSnippet(your_snippet)
 * 2) bind result using data-ng-bind-html
 */
angular.module("scriptHelper", [])
    .directive('script', function () {
        return {
            restrict: 'E',
            scope: false,
            link: function (scope, elem, attr) {
                if (attr.type === 'text/javascript-lazy') {
                    var code = elem.text();
                    var f = new Function(code);
                    f();
                }
            }
        };
    })
    .factory('ScriptUtils', function ($sce) {
        return {
            processSnippet: function (src) {
                if (src) {
                    var i = src.indexOf('type="text/javascript"');
                    if (i == -1) {
                        src = src.replace('<script', '<script type="text/javascript-lazy"')
                    } else {
                        src = src.replace('type="text/javascript"', 'type="text/javascript-lazy"')
                    }
                }

                return $sce.trustAsHtml(src);
            }
        }
    });