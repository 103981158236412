import _ from 'lodash';

angular.module('renderGroupedReviewTableWebsitePerformance', [])
    .directive('renderGroupedReviewTableWebsitePerformance', ['$window', '$http', '$timeout', 'CommonUtils', function ($window, $http, $timeout, CommonUtils) {
        return {
            restrict: 'E',
            scope: {
                website: '=',
                lighthouseResult: '=',
                isLighthouseLoaded: '=?',
                isMobile: '=?',
            },
            template: `
                <table class="yext-table yext-table-mobile table table-curved new-report-table" data-ng-if="isMobile">
                    <thead>
                        <tr>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-ng-click="lighthouseResult.show = !lighthouseResult.show" class="dropdown-field">
                            <td>
                                <div class="left-row-part">
                                    <span>
                                        <img data-ng-src="/public/images/business_profile_page/yext/directories/AccessiBe.svg" alt="AccessiBe.com">
                                    </span>
                                    <span>Accessibility</span>
                                </div>
                                <div class="right-row-part">
                                    <span class="mark-mobile">
                                        <img data-ng-if="!isLighthouseLoaded" src="/public/images/business_profile_page/yext/status/loading.svg" alt="loading"/>
        
                                        <span data-ng-if="isLighthouseLoaded && !isNumber(lighthouseResult.score)" style="font-size: 18px; line-height: 50px;">N/A</span>
        
                                        <canvas score-gauge score-value="lighthouseResult.score" width="50" height="50"
                                                disable-colors="false" is-lighthouse="true"
                                                fonts-small="20" fonts-big="20" line-width="6"
                                                data-ng-cloak data-ng-if="isLighthouseLoaded && isNumber(lighthouseResult.score)">
                                        </canvas>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr class="blue-dropdown" data-ng-show="lighthouseResult.show">
                            <td>
                                <ul class="left">
                                    <li>
                                        <span class="blue-circle">W</span>
                                        <span class="text">{{website || 'No Website Configured'}}</span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="yext-table table table-curved new-report-table" data-ng-if="!isMobile">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Website</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="first-column">
                                    <img data-ng-src="/public/images/business_profile_page/yext/directories/AccessiBe.svg" alt="AccessiBe.com">
                                    <span>
                                        <div>Accessibility</div>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div class="middle-column">
                                    <ul>
                                        <li>
                                            <span class="blue-circle">W</span>
                                            <span class="text">{{website || 'No Website Configured'}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="image">
                                <img data-ng-if="!isLighthouseLoaded" src="/public/images/business_profile_page/yext/status/loading.svg" alt="loading"/>

                                <span data-ng-if="isLighthouseLoaded && !isNumber(lighthouseResult.score)" style="font-size: 18px;">N/A</span>

                                <canvas score-gauge score-value="lighthouseResult.score" width="60" height="60"
                                        disable-colors="false" is-lighthouse="true"
                                        fonts-small="24" fonts-big="24" line-width="6"
                                        data-ng-cloak data-ng-if="isLighthouseLoaded && isNumber(lighthouseResult.score)">
                                </canvas>
                                <div data-ng-if="isLighthouseLoaded && isNumber(lighthouseResult.score)" class='black'>{{lighthouseResult.score >= 90 ? 'Compliant' : 'Non-Compliant'}}</div>
                             </td>
                        </tr>            
                    </tbody>
                </table>
            `,
            controller: function ($scope) {
                $scope.isNumber = _.isNumber;
            }
        };
    }]);
