'use strict';

import _ from 'lodash';

function validateThumbsFeedback(senderEmail, senderMessage, errors) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validationResult = true;

    // EMAIL VALIDATION
    if (_.isEmpty(senderEmail)) {
        validationResult = false;
        errors.senderEmail = ['Email is empty'];
    } else if (!emailRegExp.test(senderEmail)) {
        validationResult = false;
        errors.senderEmail = ['Invalid email format'];
    }

    if (_.isEmpty(senderMessage)) {
        validationResult = false;
        errors.senderMessage = ['Feedback is empty'];
    }

    return validationResult;
}

class ManageThumbsDownReviewCtrl {
    constructor ($window, $scope, $http, $uibModal, blockUI, CommonUtils) {
        var $this = this;

        $this.$window = $window;
        $this.$scope = $scope;
        $this.$http = $http;
        $this.blockData = blockUI.instances.get('block-data');

        $this.slugUrl = null;
        $this.businessName = "";
        $this.recipientEmail = "";
        $this.recipientName = "";
        $this.senderEmail = "";
        $this.senderMessage = "";
        $this.errors = {};
        $this.commonErrorMessage = null;
        $this.isSent = false;
        $this.isDisabled = true;

        $scope.isMobile = () => CommonUtils.isMobile();
        $scope.isTablet = () => CommonUtils.isTablet();

        $this.init = (slugUrl, recipientEmail, recipientName, businessName) => {
            $this.slugUrl = slugUrl;
            $this.businessName = businessName;
            $this.recipientEmail = recipientEmail;
            $this.recipientName = recipientName;
        };

        $this.submitThumbsDownFeedback = () => {
            $this.errors = {};
            $this.commonErrorMessage = null;
            $this.isSent = false;

            let isValid = validateThumbsFeedback($this.senderEmail, $this.senderMessage, $this.errors);
            if (isValid) {
                $this.blockData.start();
                $this.isSent = false;

                $http.post('/thumbsdown/send-feedback', {
                    recipientEmail: $this.recipientEmail,
                    recipientName: $this.recipientName,
                    senderEmail: _.trim($this.senderEmail),
                    senderMessage: $this.senderMessage,
                    businessName: $this.businessName
                })
                .then(function (response) {
                    $this.isSent = true;

                    //clear the inputs:
                    $this.senderEmail = "";
                    $this.senderMessage = "";

//                    var contactForm = jQuery('#submit-thumbs-down-form-button');
//                    contactForm.prop('disabled', true);
                    grecaptcha.reset();

                    $this.blockData.stop();
                })
                .catch(function (response) {
                    console.error(response);
                    $this.commonErrorMessage = _.get(response, 'data.message', 'Something went wrong');
                    $this.errors = _.get(response, 'data.details.errors', {});

                    if ($this.commonErrorMessage.startsWith("Validation error")) $this.commonErrorMessage = "Wrong Feedback Data";
                    $this.blockData.stop();
                });
            } else {
                $this.commonErrorMessage = 'Please, check entered information one more time.';
            }
        };

        ManageThumbsDownReviewCtrl.init();
    }

    static init() {}
}

export default ManageThumbsDownReviewCtrl;