angular.module('prRating', [])
    .directive('prRating', function () {
        return {
            restrict: 'EA',
            require: ['prRating', 'ngModel'],
            scope: {
                readonly: '=?',
                onHover: '&',
                type: '=?',
                onLeave: '&',
                disablestars: '=?'
            },
            controller: 'RatingController',
            template: '<span data-ng-mouseleave=\'reset()\' data-ng-keydown=\'onKeydown($event)\' tabindex=\'0\' role=\'slider\' aria-valuemin=\'0\' aria-valuemax=\'{{range.length}}\' aria-valuenow=\'{{value || 0}}\'>\r\n            <i data-ng-repeat=\'r in range track by $index\' title=\"{{priceRangeTooltip}}\"\r\n               data-ng-mouseenter=\'enter($index + 1)\' data-ng-click=\'rate($index + 1)\'\r\n               class=\'icon\'\r\n               data-ng-class=\"type === \'dollarSign\' ? ($index < value ? styleClass : \'fa-usd price-range-sign\') : (($index < value) ? ($index + 0.5 < value ? styleClass : \'ion-ios-star-half\') :\'ion-ios-star-outline\') \">\r\n                <span class=\'sr-only\'>({{ $index < value ? \'*\' : \' \' }})<\/span>\r\n            <\/i>\r\n        <\/span>',
            replace: true,
            link: function (scope, element, attrs, ctrls) {
                var ratingCtrl = ctrls[0], ngModelCtrl = ctrls[1];

                scope.styleClass = scope.type === 'dollarSign' ? 'fa-usd' : 'ion-ios-star';

                if (ngModelCtrl) {
                    ratingCtrl.init(ngModelCtrl);
                }
            }
        };
    })
    .controller('RatingController', ['$scope', '$attrs', function ($scope, $attrs) {

        var ratingConfig = {
            max: 5,
            stateOn: null,
            stateOff: null
        };

        var ngModelCtrl = {$setViewValue: angular.noop};

        this.init = function (ngModelCtrl_) {
            if ($scope.disablestars) {
                ngModelCtrl_.$setViewValue(0);
            }
            ngModelCtrl = ngModelCtrl_;
            ngModelCtrl.$render = this.render;

            this.stateOn = angular.isDefined($attrs.stateOn) ? $scope.$parent.$eval($attrs.stateOn) : ratingConfig.stateOn;
            this.stateOff = angular.isDefined($attrs.stateOff) ? $scope.$parent.$eval($attrs.stateOff) : ratingConfig.stateOff;

            var ratingStates = angular.isDefined($attrs.ratingStates) ? $scope.$parent.$eval($attrs.ratingStates) :
                new Array(angular.isDefined($attrs.max) ? $scope.$parent.$eval($attrs.max) : ratingConfig.max);
            $scope.range = this.buildTemplateObjects(ratingStates);
        };

        this.buildTemplateObjects = function (states) {
            for (var i = 0, n = states.length; i < n; i++) {
                states[i] = angular.extend({index: i}, {stateOn: this.stateOn, stateOff: this.stateOff}, states[i]);
            }
            return states;
        };

        // $scope.isDisabled = false;
        $scope.rate = function (value) {
            if (!$scope.readonly && value >= 0 && value <= $scope.range.length) {
                // This logic allows us cancel value of selected sign
                if (ngModelCtrl.$viewValue === value && $scope.type === 'dollarSign') {
                    value = 0;
                }

                ngModelCtrl.$setViewValue(value);
                ngModelCtrl.$render();
            }
        };

        $scope.enter = function (value) {
            if (!$scope.readonly) {
                $scope.value = value;
            }

            // Analyze what tooltip message we show to user:
            // - if $scope.readonly == true - we ignore getting values by mouse hover and use value from business profile
            // (e.g static information on 'Additional info' page)
            // - if $scope.readonly == false - we create tooltip message by value getting by mouse hover (e.g on 'Edit Business' page)
            var signs = $scope.readonly ? parseInt($scope.value) : value;

            $scope.priceRangeTooltip = $scope.type === 'dollarSign' ? $scope.createTooltipMessage(signs) : '';
            $scope.onHover({value: value});
        };

        $scope.reset = function () {
            $scope.value = ngModelCtrl.$viewValue;
            $scope.onLeave();
        };

        $scope.onKeydown = function (evt) {
            if (/(37|38|39|40)/.test(evt.which)) {
                evt.preventDefault();
                evt.stopPropagation();
                $scope.rate($scope.value + (evt.which === 38 || evt.which === 39 ? 1 : -1));
            }
        };

        this.render = function () {
            $scope.value = ngModelCtrl.$viewValue;
        };

        $scope.createTooltipMessage = function (value) {
            var priceRangeTooltip = '';

            switch (value) {
                case 1:
                    priceRangeTooltip = '$10 or less per person';
                    break;
                case 2:
                    priceRangeTooltip = '$11 - $25 per person';
                    break;
                case 3:
                    priceRangeTooltip = '$26 - $50 per person';
                    break;
                case 4:
                    priceRangeTooltip = '$50+ per person';
                    break;
            }

            return priceRangeTooltip;
        }

    }]);
;
