'use strict';

class StaticPagesCtrl {

    /*@ngInject*/
    constructor($window, $scope, $rootScope, $http, $cookies, $uibModal, $location, $sce, blockUI) {
        StaticPagesCtrl.init();

        var $this = this;
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$cookies = $cookies;
        this.$uibModal = $uibModal;
        this.blockData = blockUI.instances.get('static-block-data');
        this.$window = $window;
        this.$scope = $scope;
        this.$location = $location;

        this.initStaticContent = pageName => {
            $this.pageName = pageName;

            //get pageContent:
            const params = { templateName: $this.pageName };
            $this.blockData.start();
            $http.post('/test-static-page/get-static-page', params)
                .then(function (response) {
                    const {contentData} = response.data || '';
                    $this.pageContent = $sce.trustAsHtml(contentData);
                    $this.blockData.stop();
                })
                .catch(function (error) {
                    console.error(error);
                    $this.pageContent = $sce.trustAsHtml(`${error.data.errorHtml || "<div>Error on content fetching<div/>"}`);
                    $this.blockData.stop();
                });
        };
    }

    static init() {
        const $this = this;
        $this.pageContent = '';
        $this.pageName = 'index';
    }

}

export default StaticPagesCtrl;
