'use strict';

import _ from 'lodash';
import moment from "moment";
const _$http = new WeakMap();

class ListingService {


    constructor($http) {
        _$http.set(this, $http);
    }

    loadReport(businessId, type) {

        return _$http.get(this).get(`/report/${businessId}/${type}`)
            .then(onSuccess)
            .catch(onError);


        function onSuccess(response) {
            response.data.createdDateReport = ListingService.formatViewDate(_.head(response.data.report).createdAt);
            return response;
        }

        function onError(error) {
            return error
        }
    }


    getNAPScoreColor(score) {
        var style;
        switch (true) {
            case score <= 30:
                style = '#E54141';
                break;
            case score <= 31 <= score && score <= 34:
                style = '#E8513A';
                break;
            case 35 <= score && score <= 38:
                style = '#EE712B';
                break;
            case 39 <= score && score <= 42:
                style = '#EE712B';
                break;
            case 43 <= score && score <= 46:
                style = '#F18124';
                break;
            case 47 <= score && score <= 50:
                style = '#F3911D';
                break;
            case 51 <= score && score <= 54:
                style = '#F3911D';
                break;
            case 55 <= score && score <= 58:
                style = '#F9B10E';
                break;
            case 59 <= score && score <= 62:
                style = '#FCC107';
                break;
            case 63 <= score && score <= 66:
                style = '#FFD100';
                break;
            case 67 <= score && score <= 70:
                style = '#F4D108';
                break;
            case 71 <= score && score <= 74:
                style = '#E8D110';
                break;
            case 75 <= score && score <= 78:
                style = '#DDD117';
                break;
            case 79 <= score && score <= 82:
                style = '#D1D11F';
                break;
            case 83 <= score && score <= 86:
                style = '#C6D127';
                break;
            case 87 <= score && score <= 90:
                style = '#BAD12F';
                break;
            case 91 <= score && score <= 94:
                style = '#AFD136';
                break;
            case 95 <= score && score <= 98:
                style = '#A3D13E';
                break;
            case score >= 99:
                style = '#98D146';
                break;
        }
        return style;
    };

    getVisibilityScoreV2Business(synupResult, isUpgraded, business, prScoreRange) {
        const filteredSynupResult = synupResult;

        let visibilityScore = {
                listingsPresent: 0,
                napConsistancyTotalCount: 0,
                napConsistancy: 0,
                listingsChecked: 0,
                actionRequired: 0,
                score: 10,
                isShowPartnerTag: false,
                notListedYet: 0,
                textMessageConsistancy: 0,
            },
            matched = 0,
            additionalPoints = 0;

        _.forEach(filteredSynupResult.report, (yext) => {
            let report = yext.report,
                address = report.match_address,
                name = report.match_name,
                phone = report.match_phone,
                isName = _.isEqual((_.get(report, 'name') || '').toLowerCase(), (_.get(business, 'name') || '').toLowerCase()),
                isCity = _.isEqual((_.get(report, 'city') || '').toLowerCase(), (_.get(business, 'city') || '').toLowerCase()),
                isState = _.isEqual((_.get(report, 'state') || '').toLowerCase(), (_.get(business, 'state') || '').toLowerCase()),
                isZip = _.isEqual(_.get(report, 'zip'), _.get(business, 'zipcode')),
                isPublicReputation = _.isEqual(yext.siteId, 'pr.business'),
                // isGoogle = _.isEqual(yext.siteId, 'GoogleMyBusiness'),
                // isSynced = isPublicReputation || report.status === 'complete' || !isGoogle && isUpgraded && ((name && address && phone) || (isName && isCity && isState && isZip));
                isSynced = isPublicReputation || report.status === 'complete' || isUpgraded && ((name && address && phone) || (isName && isCity && isState && isZip));

            if (report.has_powerlistings && !visibilityScore.isShowPartnerTag) {
                visibilityScore.isShowPartnerTag = report.has_powerlistings;
            }

            if ((!address && !phone && !name) || (!isName && !isState && !isCity && !isZip)) {
                ++visibilityScore.notListedYet;
            } else if ((address && phone && name) || (isName && isState && isCity && isZip)) {
                ++visibilityScore.listingsPresent;
                matched += 3;
            } else {
                ++visibilityScore.actionRequired;

                matched = address ? ++matched : matched;
                matched = name ? ++matched : matched;
                matched = phone ? ++matched : matched;
            }
            additionalPoints = isSynced ? ++additionalPoints : additionalPoints;
        });

        visibilityScore.listingsChecked = filteredSynupResult.report.length;
        visibilityScore.napConsistancyMatchedCount = matched;
        visibilityScore.napConsistancyTotalCount = filteredSynupResult.report.length * 3;

        if (visibilityScore.listingsPresent > 0 && visibilityScore.listingsChecked > 0) {
            visibilityScore.napConsistancy = _.round((visibilityScore.napConsistancyMatchedCount / visibilityScore.napConsistancyTotalCount) * 100);
            visibilityScore.score += (((visibilityScore.listingsPresent + additionalPoints) / 2) / filteredSynupResult.report.length) * 90;
        }

        visibilityScore.listingsPresent += visibilityScore.actionRequired;

        //as a stopgap:
        if (_.get(business, 'Membership.title') === 'Synup Subscription' || _.get(business, 'Membership.title') === 'Synup - Managed Manually') {
            visibilityScore.score = 83;
        }

        // if (_.get(business, 'BusinessSettings.isUseDefaultScanScore')) {
        //     const defaultPrScore = _.get(business, 'BusinessSettings.defaultScanScore', 0);
        //     if (visibilityScore.score < defaultPrScore) {
        //         visibilityScore.score = defaultPrScore;
        //     }
        // }
        if (_.get(business, 'BusinessSettings.isDefaultPrScore')) {
            // const defaultPrScore = this.getRandomScore(prScoreRange);
            const defaultPrScore = _.get(business, 'BusinessSettings.defaultScanScore', 0);
            if (visibilityScore.score < defaultPrScore) {
                visibilityScore.score = defaultPrScore;
            }
        }

        return visibilityScore;
    };

    // getRandomScore(prScoreRange = {min: 60, max: 100}) {
    //     return Math.floor(Math.random() * (prScoreRange.max - prScoreRange.min + 1)) + prScoreRange.min;
    // };

    getVisibilityScoreV2(synupResult, isUpgraded) {

        const filteredSynupResult = synupResult;

        let visibilityScore = {
                listingsPresent: 0,
                napConsistancyTotalCount: 0,
                napConsistancy: 0,
                listingsChecked: 0,
                actionRequired: 0,
                score: 10,
                isShowPartnerTag: false,
                notListedYet: 0,
                textMessageConsistancy: 0,
            },
            matched = 0,
            additionalPoints = 0;

        _.forEach(filteredSynupResult.report, (yext) => {
            let report = yext.report,
                address = report.match_address,
                name = report.match_name,
                phone = report.match_phone,
                isPublicReputation = _.isEqual(yext.siteId,'pr.business'),
                isSynced = isPublicReputation || report.has_powerlistings || (isUpgraded) && name && address && phone;

            if(report.has_powerlistings && !visibilityScore.isShowPartnerTag){
                visibilityScore.isShowPartnerTag = report.has_powerlistings;
            }

            if (!address && !phone && !name) {
                ++visibilityScore.notListedYet;
            } else if (address && phone && name) {
                ++visibilityScore.listingsPresent;
                matched += 3;
            } else {
                ++visibilityScore.actionRequired;

                matched = address ? ++matched : matched;
                matched = name ? ++matched : matched;
                matched = phone ? ++matched : matched;
            }
            additionalPoints = isSynced ? ++additionalPoints : additionalPoints;
        });

        visibilityScore.listingsChecked = filteredSynupResult.report.length;
        visibilityScore.napConsistancyMatchedCount = matched;
        visibilityScore.napConsistancyTotalCount = filteredSynupResult.report.length * 3;

        if (visibilityScore.listingsPresent > 0 && visibilityScore.listingsChecked > 0) {
            visibilityScore.napConsistancy = _.round((visibilityScore.napConsistancyMatchedCount / visibilityScore.napConsistancyTotalCount) * 100);
            visibilityScore.score += (((visibilityScore.listingsPresent + additionalPoints) / 2) / filteredSynupResult.report.length) * 90;
        }

        visibilityScore.listingsPresent += visibilityScore.actionRequired;

        return visibilityScore;
    };

    getVisibilityScore(synupResult, isUpgraded) {

        const filteredReports = _.filter(synupResult.report, r => r.siteId !== 'Amazon Alexa' && r.siteId !== 'Apple Siri' && r.siteId !== 'Waze') || [];
        const filteredSynupResult = {report: filteredReports};

        let visibilityScore = {
                listingsPresent: 0,
                napConsistancyTotalCount: 0,
                napConsistancy: 0,
                listingsChecked: 0,
                actionRequired: 0,
                score: 10,
                isShowPartnerTag: false,
                notListedYet: 0
            },
            matched = 0,
            additionalPoints = 0;

        _.forEach(filteredSynupResult.report, (yext) => {
            let report = yext.report,
                address = report.match_address,
                name = report.match_name,
                phone = report.match_phone,
                isPublicReputation = _.isEqual(yext.siteId,'pr.business'),
                isSynced = isPublicReputation || report.has_powerlistings || (isUpgraded) && name && address && phone;

            if(report.has_powerlistings && !visibilityScore.isShowPartnerTag){
                visibilityScore.isShowPartnerTag = report.has_powerlistings;
            }

            if (!address && !phone && !name) {
                ++visibilityScore.notListedYet;
            } else if (address && phone && name) {
                ++visibilityScore.listingsPresent;
                matched += 3;
            } else {
                ++visibilityScore.actionRequired;

                matched = address ? ++matched : matched;
                matched = name ? ++matched : matched;
                matched = phone ? ++matched : matched;
            }
            additionalPoints = isSynced ? ++additionalPoints : additionalPoints;
        });

        visibilityScore.listingsChecked = filteredSynupResult.report.length;
        visibilityScore.napConsistancyMatchedCount = matched;
        visibilityScore.napConsistancyTotalCount = filteredSynupResult.report.length * 3;

        if (visibilityScore.listingsPresent > 0 && visibilityScore.listingsChecked > 0) {
            additionalPoints *= 0.5;
            visibilityScore.napConsistancy = ~~_.round((visibilityScore.napConsistancyMatchedCount / visibilityScore.napConsistancyTotalCount) * 100);
            visibilityScore.score += (visibilityScore.listingsPresent + additionalPoints) ;
        }

        visibilityScore.listingsPresent += visibilityScore.actionRequired;

        return visibilityScore;
    };


    static formatViewDate(stringDate) {
        return `${moment(stringDate).format('MMMM DD, YYYY')}`;
    };

    getGroupedResults(result, groupName) {

        const PRSites = ['pr.business'];
        const AISites = ['Amazon Alexa', 'Apple Siri', 'Microsoft Cortana', 'Google Assistant', 'Samsung Bixby'];
        const SESites = ['Bing', /*'Google',*/ 'Yahoo'];
        const NMGSites = ['Apple Maps', 'Google Maps', 'Bing Maps', 'MapQuest', 'Waze', 'AroundMe', 'HERE', 'NavMII', 'Telenav', 'TomTom', 'WhereTo', 'CarPlay'];
        const ASites = ['D & B Database', 'Factual', 'Pitney Bowes', 'Soleo'];
        const FMBSites = ['pr.business', 'Google Maps', 'Facebook', 'Yelp', 'TripAdvisor'];
        const FMESites = ['AllergyEats', 'Allmenus', 'MenuPix', 'Zomato', 'Bing', 'Foursquare', 'MapQuest', 'YP', '2findlocal', '8coupons', 'ABLocal', 'AmericanTowns.com', 'AroundMe', 'Bizwiki', 'Brownbook.net', 'ChamberofCommerce.com', 'Citysearch', 'CitySquares', 'Credibility Review', 'Cylex', 'eLocal', 'EZlocal', 'FindOpen', 'Get Fave', 'GoLocal247', 'Hotfrog', 'iBegin', 'iGlobal', 'Insider Pages', 'Local Database', 'Local.com', 'LocalPages.com', /*'MerchantCircle',*/ 'My Local Services', 'n49', 'Opendi', 'ShowMeLocal.com', 'Superpages', 'The Internet Chamber of Commerce', 'VotefortheBest.com', 'Where To?', 'White & Yellow Pages', 'Yalwa', 'YaSabe', 'YellowMoxie.com', 'YellowPageCity.com', 'YellowPagesGoesGreen.org'];

        switch (groupName) {
            case 'PR':
                return _.filter(result, s => _.includes(PRSites, s.siteId));

            case 'AI':
                return _.filter(result, s => _.includes(AISites, s.siteId));

            case 'SE':
                return _.filter(result, s => _.includes(SESites, s.siteId));

            case 'D':
                return _.filter(result, s => !_.includes(PRSites, s.siteId)
                    && !_.includes(AISites, s.siteId)
                    && !_.includes(SESites, s.siteId)
                    && !_.includes(NMGSites, s.siteId)
                    && !_.includes(ASites, s.siteId)
                    && s.siteId !== 'Google');

            case 'NMG':
                return _.filter(result, s => _.includes(NMGSites, s.siteId));

            case 'A':
                return _.filter(result, s => _.includes(ASites, s.siteId));

            case 'FMB':
                return _.filter(result, s => _.includes(FMBSites, s.siteId));

            case 'FME':
                return _.filter(result, s => _.includes(FMESites, s.siteId));

            default:
                return result;
        }
    }

}


export default ListingService;
