import _ from 'lodash';

/**
 * agentVerifyCtrl
 */
 angular.module('agentVerify', ['blockUI', 'ngCookies'])
     .controller('agentVerifyCtrl', ['$scope', '$http', '$window', '$timeout', '$interval', '$location', '$cookieStore', '$rootScope', 'blockUI', '$uibModal', 'CommonUtils', function ($scope, $http, $window, $timeout, $interval, $location, $cookieStore, $rootScope, blockUI, $uibModal, CommonUtils) {
 		$scope.searchLeadiumStatus = function() {
 	    	var params = {
 	    		phone: $scope.phone
 			};
 			var handleSuccess = function(response) {
 					console.log(response);

            $scope.showMessage = true;
            if((response.data.businessList).length){
              $scope.businessList =response.data.businessList;
              $scope.IsVisible = true;
              $scope.message = '';
            }else{
               console.log($scope.businessList);
              $scope.message = 'No records are found.';
            }
 					// display error message
 			};

 			var handleError = function(response){
 					console.log(response);
           $scope.message ='Searching failed.'
 					$scope.showMessage = true;
 			};

 			console.log(params);
 			$http.post('/agent-verified-business-search', params)
 				.then(handleSuccess, handleError);
 		};


     $scope.selection=[];
     $scope.businessSelection = function businessSelection(businessName){
       var idx = $scope.selection.indexOf(businessName);
      // is currently selected
       if (idx > -1) {
          $scope.selection.splice(idx, 1);
       }
      // is newly selected
       else {
         $scope.selection.push(businessName);
       }
     };


    $scope.removeLeadiumStatus =function(){
      var params = {
        businessList :$scope.selection,
        agentName: $scope.agentName
      };

      var handleError = function(response){
          console.log(response);
          $scope.message = 'Update failed.';
          $scope.showMessage = true;
      };

      var handleSuccess = function(response){
        if(response.data.success){
          $scope.IsVisible = false;
         let aName = "";
           _.forEach(response.data.businessInfo, function(value) {
             // ll("tagEvent", "Sales Agent Verified",{'Business ID' : value.id});
             if(aName.length > 0) aName +=",";
             aName += value.name ;
           });
             $scope.phone = '';
             $scope.agentName = '';
             $scope.businessList = [];
             $scope.showMessage = true;
             $scope.selection=[];
             $scope.message = response.data.message + aName;
         }else{
           $scope.message = response.data.message;
         }

          // display error message
      }; console.log(params);
      $http.post('/agent-verified', params)
       .then(handleSuccess, handleError);
    }

 }]);
