'use strict';

var _$http = new WeakMap();
var _commonUtils = new WeakMap();

class CategoriesBreadcrumbs {
    /* @ngInject */
    constructor($http, CommonUtils) {
        this.template = `
            <div data-ng-if="!small">
                <span><a data-ng-href="/">Home</a></span>
                <span data-ng-repeat="category in categories"> > <a data-ng-href="/c/{{category.link | decodeURIComponent }}/{{city}}/page-1">{{category.name}}</a></span>
                <span> > {{businessName}}</span>
            </div>
            <div data-ng-if="small">
                <span data-ng-repeat="category in categories track by $index">
                    <a data-ng-href="/c/{{category.link | decodeURIComponent }}/{{city}}/page-1">{{category.name}}</a>
                    <span data-ng-if="!$last">, </span>
                </span>
            </div>
`;
        this.restrict = 'E';
        this.scope = {
            childCategoryId: '@',
            categoryResponse: '@',
            businessName: '@',
            city: '@',
            small: '@',
            level: '@'
        };

        _$http.set(this, $http);
        _commonUtils.set(this, CommonUtils);
    }

    link(scope) {

        if(scope.categoryResponse){
            scope.categoryResponse = JSON.parse(scope.categoryResponse);
            scope.city = _commonUtils.get(CategoriesBreadcrumbs.instance).changeValueForUrlSearchParam(scope.city);

            var categoriesArray = scope.categoryResponse.categories.map(category => {
                if (!category) {
                    return null;
                } else {
                    return {
                        name: category.name,
                        link: _commonUtils.get(CategoriesBreadcrumbs.instance).changeValueForUrlSearchParam(category.name)
                    }
                }
            });
            if (scope.level) {
                var endOfElements = scope.level > categoriesArray.length ? categoriesArray.length : scope.level;
                categoriesArray = categoriesArray.slice(categoriesArray.length - endOfElements, categoriesArray.length);
            }
            scope.categories = categoriesArray.filter(cat => !!cat);
        }

    }

    static categoriesBreadcrumbsFactory($http, CommonUtils) {
        CategoriesBreadcrumbs.instance = new CategoriesBreadcrumbs($http, CommonUtils);
        return CategoriesBreadcrumbs.instance;
    }

}

CategoriesBreadcrumbs.categoriesBreadcrumbsFactory.$inject = ['$http', 'CommonUtils'];

export default CategoriesBreadcrumbs.categoriesBreadcrumbsFactory;