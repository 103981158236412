'use strict';

class AddthisToolbox {
    constructor($timeout) {
        this.$timeout = $timeout;
        this.restrict = 'A';
        this.transclude = true;
        this.replace = true;
        this.template = '<div data-ng-transclude></div>';
    }

    link(scope, element, attrs) {
        // this.$timeout(function () {
        AddthisToolbox.instance.$timeout(function () {
            window.addthis.init();
            window.addthis.toolbox($(element).get(), {}, {
                url: attrs.postHref
            });
        });
    }

    static directiveFactory1($timeout){
        AddthisToolbox.instance = new AddthisToolbox($timeout);
        return AddthisToolbox.instance;
    }
}

AddthisToolbox.directiveFactory1.$inject = ['$timeout'];

export default AddthisToolbox;