'use strict';

import _ from 'lodash';
import DefaultReviewsData from '../utils/defaultReviewsData'

function validateInputScan(scanInformation, errors) {
  const phoneRegExp = /^[0-9]{10}$/;
  let validationResult = true;

  // NAME VALIDATION
  if (_.isEmpty(scanInformation.name)) {
    validationResult = false;
    errors.scanInformation.name = ['Business Name is empty'];
  }

  if (!_.isEmpty(scanInformation.name) && scanInformation.name.includes("\'")) {
      validationResult = false;
      errors.scanInformation.name = ["Business Name cannot contain \' (Apostrophe), please, replace it with \`(Acute accent)"];
  }

  // FIRST NAME VALIDATION
  if (_.isEmpty(scanInformation.firstName)) {
    validationResult = false;
    errors.scanInformation.firstName = ['First Name is empty'];
  }

  // LAST NAME VALIDATION
  if (_.isEmpty(scanInformation.lastName)) {
    validationResult = false;
    errors.scanInformation.lastName = ['Last Name is empty'];
  }

  // PHONE VALIDATION
  if (_.isEmpty(scanInformation.phone)) {
    validationResult = false;
    errors.scanInformation.phone = ['Phone is empty'];
  } else if (!phoneRegExp.test(_.trim(scanInformation.phone))) {
    validationResult = false;
    errors.scanInformation.phone = ['Invalid phone format'];
  }

  return validationResult;
}

function validateDetermineEmail(email, errors) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validationResult = true;

    // EMAIL VALIDATION
    if (_.isEmpty(email)) {
        validationResult = false;
        errors.determineUserInformation.email = ['Email is empty'];
    } else if (!emailRegExp.test(email)) {
        validationResult = false;
        errors.determineUserInformation.email = ['Invalid email format'];
    }

    return validationResult;
}

function validateBusinessInformation(buildInformation, errors) {
  const phoneRegExp = /^[0-9]{10}$/,
        websiteRegExp = /^((ftp|http|https):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
//        emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;

  let validationResult = true;

  // NAME VALIDATION
  if (_.isEmpty(buildInformation.name)) {
    validationResult = false;
    errors.buildInformation.name = ['Business Name is empty'];
  }
  if (!_.isEmpty(buildInformation.name) && buildInformation.name.includes("\'")) {
      validationResult = false;
      errors.scanInformation.name = ["Business Name cannot contain \' (Apostrophe), please, replace it with \`(Acute accent)"];
  }

  // PHONE VALIDATION
  if (_.isEmpty(buildInformation.phone)) {
    validationResult = false;
    errors.buildInformation.phone = ['Phone is empty'];
  } else if (!phoneRegExp.test(_.trim(buildInformation.phone))) {
    validationResult = false;
    errors.buildInformation.phone = ['Invalid phone format'];
  }

  // WEBSITE VALIDATION
//  if (_.isEmpty(buildInformation.website)) {
//    validationResult = false;
//    errors.buildInformation.website = ['Website is empty'];
//  } else if (!websiteRegExp.test(_.trim(buildInformation.website))) {
//    validationResult = false;
//    errors.buildInformation.website = ['Invalid website format'];
//  }
    if (!_.isEmpty(buildInformation.website) && !websiteRegExp.test(_.trim(buildInformation.website))) {
        validationResult = false;
        errors.buildInformation.website = ['Invalid website format'];
    }

  // COUNTRY VALIDATION
  if (_.isEmpty(buildInformation.country)) {
    validationResult = false;
    errors.buildInformation.country = ['Country is empty'];
  }

  // STATES VALIDATION
  if (_.isEmpty(buildInformation.state)) {
    validationResult = false;
    errors.buildInformation.state = ['State is empty'];
  }

  // ZIPCODE VALIDATION
  if (_.isEmpty(buildInformation.zipcode)) {
    validationResult = false;
    errors.buildInformation.zipcode = ['Zipcode is empty'];
  } else if (!zipCodeRegExp.test(buildInformation.zipcode)) {
    validationResult = false;
    errors.buildInformation.zipcode = ['Invalid zipcode format'];
  }

  // CITY VALIDATION
  if (_.isEmpty(buildInformation.city)) {
    validationResult = false;
    errors.buildInformation.city = ['City is empty'];
  }

  // ADDRESS VALIDATION
  if (_.isEmpty(buildInformation.address)) {
    validationResult = false;
    errors.buildInformation.address = ['Address is empty'];
  }

  // CATEGORY VALIDATION
  if (_.isEmpty(buildInformation.category)) {
    validationResult = false;
    errors.buildInformation.category = ['Category is empty'];
  }

//  // MESSAGE VALIDATION
//  if (_.isEmpty(buildInformation.description)) {
//    validationResult = false;
//    errors.buildInformation.description = ['Message is empty'];
//  }

  return validationResult;
}

function validateContactInformation(contactInformation, errors) {
  const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phoneRegExp = /^[0-9]{10}$/;

  let validationResult = true;

  // FIRST NAME VALIDATION
  if (_.isEmpty(contactInformation.firstName)) {
    validationResult = false;
    errors.contactInformation.firstName = ['First Name is empty'];
  }

  // LAST NAME VALIDATION
  if (_.isEmpty(contactInformation.lastName)) {
    validationResult = false;
    errors.contactInformation.lastName = ['Last Name is empty'];
  }

  // EMAIL VALIDATION
  if (_.isEmpty(contactInformation.email)) {
    validationResult = false;
    errors.contactInformation.email = ['Email is empty'];
  } else if (!emailRegExp.test(contactInformation.email)) {
    validationResult = false;
    errors.contactInformation.email = ['Invalid email format'];
  }

  // PASSWORD VALIDATION
  if (_.isEmpty(contactInformation.password)) {
    validationResult = false;
    errors.contactInformation.password = ['Password is empty'];
  }

  // PASSWORD CONFIRM VALIDATION
  if (_.isEmpty(contactInformation.passwordConfirm)) {
    validationResult = false;
    errors.contactInformation.passwordConfirm = ['Confirm is empty'];
  } else if (contactInformation.passwordConfirm !== contactInformation.password) {
    validationResult = false;
    errors.contactInformation.passwordConfirm = ['Passwords are different'];
  }

  // PHONE VALIDATION
  if (!_.isEmpty(contactInformation.mobilePhone) && !phoneRegExp.test(_.trim(contactInformation.mobilePhone))) {
    validationResult = false;
    errors.contactInformation.mobilePhone = ['Invalid phone format'];
  }

  return validationResult;
}

function validateCheckoutInformation(checkoutInformation, errors) {
    let validationResult = true;

    const zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    const yearRegExp = /^\d{4}$/;
    const commonAddressError = 'Check data';

    //billing address:
    if (_.isEmpty(checkoutInformation.companyName)) {
        validationResult = false;
        errors.checkoutInformation.companyName = ['Name is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.address)) {
        validationResult = false;
        errors.checkoutInformation.address = ['Address is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.city)) {
        validationResult = false;
        errors.checkoutInformation.city = ['City is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.state)) {
        validationResult = false;
        errors.checkoutInformation.state = ['State is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.zipcode)) {
        validationResult = false;
        errors.checkoutInformation.zipcode = ['Zipcode is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    } else if (!zipCodeRegExp.test(checkoutInformation.zipcode)) {
        validationResult = false;
        errors.checkoutInformation.zipcode = ['Invalid zipcode format'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.country)) {
        validationResult = false;
        errors.checkoutInformation.country = ['Country is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    //billing Information:
    if (_.isEmpty(checkoutInformation.firstName)) {
        validationResult = false;
        errors.checkoutInformation.firstName = ['First Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.lastName)) {
        validationResult = false;
        errors.checkoutInformation.lastName = ['Last Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.name)) {
        validationResult = false;
        errors.checkoutInformation.name = ['Card Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.number)) {
        validationResult = false;
        errors.checkoutInformation.number = ['Card Number is empty'];
    }

    if (_.isEmpty(checkoutInformation.cvv)) {
        validationResult = false;
        errors.checkoutInformation.cvv = ['Empty'];
    }

    if (!_.isNumber(checkoutInformation.expirationMonth)) {
        validationResult = false;
        errors.checkoutInformation.expirationMonth = ['Invalid'];
    }

    if (!yearRegExp.test(_.trim(checkoutInformation.expirationYear))) {
        validationResult = false;
        errors.checkoutInformation.expirationYear = ['Invalid'];
    }

    return validationResult;
}

function validatePastDueInformation(pastDueInformation, errorsType, errors) {
    let validationResult = true;

    const zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    const yearRegExp = /^\d{4}$/;
    const commonAddressError = 'Check data';

    //billing address:
    if (_.isEmpty(pastDueInformation.companyName)) {
        validationResult = false;
        errors[errorsType].companyName = ['Name is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    if (_.isEmpty(pastDueInformation.address)) {
        validationResult = false;
        errors[errorsType].address = ['Address is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    if (_.isEmpty(pastDueInformation.city)) {
        validationResult = false;
        errors[errorsType].city = ['City is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    if (_.isEmpty(pastDueInformation.state)) {
        validationResult = false;
        errors[errorsType].state = ['State is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    if (_.isEmpty(pastDueInformation.zipcode)) {
        validationResult = false;
        errors[errorsType].zipcode = ['Zipcode is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    } else if (!zipCodeRegExp.test(pastDueInformation.zipcode)) {
        validationResult = false;
        errors[errorsType].zipcode = ['Invalid zipcode format'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    if (_.isEmpty(pastDueInformation.country)) {
        validationResult = false;
        errors[errorsType].country = ['Country is empty'];
        errors[errorsType].commonAddressError = commonAddressError;
    }

    //billing Information:
    if (_.isEmpty(pastDueInformation.firstName)) {
        validationResult = false;
        errors[errorsType].firstName = ['First Name is empty'];
    }

    if (_.isEmpty(pastDueInformation.lastName)) {
        validationResult = false;
        errors[errorsType].lastName = ['Last Name is empty'];
    }

    if (_.isEmpty(pastDueInformation.cardholderName)) {
        validationResult = false;
        errors[errorsType].cardholderName = ['Card Name is empty'];
    }

    if (_.isEmpty(pastDueInformation.cardNumber)) {
        validationResult = false;
        errors[errorsType].cardNumber = ['Card Number is empty'];
    }

    if (_.isEmpty(pastDueInformation.cvv)) {
        validationResult = false;
        errors[errorsType].cvv = ['Empty'];
    }

    if (!_.isNumber(pastDueInformation.expirationMonth)) {
        validationResult = false;
        errors[errorsType].expirationMonth = ['Invalid'];
    }

    if (!yearRegExp.test(_.trim(pastDueInformation.expirationYear))) {
        validationResult = false;
        errors[errorsType].expirationYear = ['Invalid'];
    }

    return validationResult;
}

function changedBuildData(businessInformation, contactInformation) {
    const newData = {};

    const newBusiness = {};
    const newUser = {};

    //add optional values if necessary:
    if (!_.isEmpty(businessInformation.phone)) {
        contactInformation.mobilePhone = businessInformation.phone;
    }

    //if business is exist:
    if (businessInformation.id) {
        newBusiness.id = businessInformation.id;
    }

    //parse:
    newBusiness.phone = businessInformation.phone;
    newBusiness.name = businessInformation.name;
    newBusiness.address = businessInformation.address;
    newBusiness.city = businessInformation.city;
    newBusiness.state = businessInformation.state;
    newBusiness.zipcode = businessInformation.zipcode;
    newBusiness.country = businessInformation.country;
    newBusiness.description = businessInformation.description;
    newBusiness.website = businessInformation.website;
    newBusiness.categoryId = _.last(businessInformation.category) || null;

    newUser.email = contactInformation.email;
    newUser.firstName = contactInformation.firstName;
    newUser.lastName = contactInformation.lastName;
    newUser.password = contactInformation.password;
    newUser.passwordConfirm = contactInformation.passwordConfirm;
    newUser.phone = contactInformation.mobilePhone;

    newData.business = newBusiness;
    newData.user = newUser;

    return newData;
}

function changedBuildDataWithUserId(businessInformation, userId) {
    const newData = {};

    const newBusiness = {};

    //if business is exist:
    if (businessInformation.id) {
        newBusiness.id = businessInformation.id;
    }

    //parse:
    newBusiness.phone = businessInformation.phone;
    newBusiness.name = businessInformation.name;
    newBusiness.address = businessInformation.address;
    newBusiness.city = businessInformation.city;
    newBusiness.state = businessInformation.state;
    newBusiness.zipcode = businessInformation.zipcode;
    newBusiness.country = businessInformation.country;
    newBusiness.description = businessInformation.description;
    newBusiness.website = businessInformation.website;
    newBusiness.categoryId = _.last(businessInformation.category) || null;

    newData.business = newBusiness;

    //set only userId:
    newData.user = userId;

    return newData;
}

function changedCheckoutData(userId, businessId, membershipId, checkoutInformation, leadId) {
    const newData = {};

    if (checkoutInformation.number) {
        checkoutInformation.cardNumber = checkoutInformation.number;
    }

    if (checkoutInformation.name) {
        checkoutInformation.cardholderName = checkoutInformation.name;
    }

    newData.userId = userId;
    newData.businessId = businessId;
    newData.membershipId = membershipId;
    newData.billing = checkoutInformation;
    newData.leadId = leadId;

    return newData;
}

function changedPastDueData(pastDueInformation, opportunityId, updateBusinessMembershipData) {
    const newData = {};

    newData.billing = pastDueInformation;
    // newData.amount = _.get(pastDueInformation, 'oneTimePrice', 0); //is not actual for Prod
    newData.opportunityId = opportunityId;
    newData.id = opportunityId;

    //set phone for /newuser:
    if (pastDueInformation.phone) {
        newData.billing.phone = pastDueInformation.phone;
    }

    if (updateBusinessMembershipData) {
        newData.updateBusinessMembershipData = updateBusinessMembershipData;
    }

    return newData;
}

function parseErrors (responseErrors) {
  const parsedErrors = {buildInformation: {}, contactInformation: {}};

  if (responseErrors.business.existBusiness) {
      parsedErrors.buildInformation.name = ['Such business is exist'];
  } else {
      parsedErrors.buildInformation = responseErrors.business;
  }

  parsedErrors.contactInformation.email = !_.isEmpty(responseErrors.user.email) ? [_.first(responseErrors.user.email)] : undefined;
  if (!_.isEmpty(responseErrors.user.user)) {
    parsedErrors.contactInformation.email = [_.first(responseErrors.user.user)];
  }
  parsedErrors.contactInformation.firstName = responseErrors.user.firstName;
  parsedErrors.contactInformation.lastName = responseErrors.user.lastName;
  parsedErrors.contactInformation.mobilePhone = responseErrors.user.phone;
  if (!_.isEmpty(responseErrors.user.password) && responseErrors.user.password.length > 0) {
    if (responseErrors.user.password.length > 1) {
      parsedErrors.contactInformation.password = [_.first(responseErrors.user.password)];
      parsedErrors.contactInformation.passwordConfirm = [_.last(responseErrors.user.password)];
    } else {
      parsedErrors.contactInformation.password = responseErrors.user.password;
      parsedErrors.contactInformation.passwordConfirm = responseErrors.user.password;
    }
  }

  return parsedErrors;
}

function parseCheckoutErrors (errorMessages) {
    const parsedErrors = {checkoutInformation: {}};
    let notValidationError = null;

    //billing address:
    const billingAddressErrors = _.get(errorMessages, 'billingAddress.base[0].message', '');
    if (!_.isEmpty(billingAddressErrors)) {
        notValidationError = 'Wrong Billing Address';
    }

    //billing information:
    const billingNameErrors = _.get(errorMessages, 'billingInformation.base[0].message', '');
    if (!_.isEmpty(billingNameErrors)) {
        notValidationError = 'Wrong Billing Information';
    }
    const billingCvvErrors = _.get(errorMessages, 'billingInformation.cvv[0].message', '');
    if (!_.isEmpty(billingCvvErrors)) {
        parsedErrors.checkoutInformation.cvv = [billingCvvErrors];
    }
    const expirationMonthErrors = _.get(errorMessages, 'billingInformation.expirationMonth[0].message', '');
    if (!_.isEmpty(expirationMonthErrors)) {
        parsedErrors.checkoutInformation.expirationMonth = [expirationMonthErrors];
    }
    const expirationYearErrors = _.get(errorMessages, 'billingInformation.expirationYear[0].message', '');
    if (!_.isEmpty(expirationYearErrors)) {
        parsedErrors.checkoutInformation.expirationYear = [expirationYearErrors];
    }
    const numberErrors = _.get(errorMessages, 'billingInformation.number[0].message', '');
    if (!_.isEmpty(numberErrors)) {
        parsedErrors.checkoutInformation.number = [numberErrors];
    }

    return {parsedErrors, notValidationError};
}

function parsePastDueErrors (errorMessages) {
    const parsedErrors = {pastDueInformation: {}};

    parsedErrors.pastDueInformation.address = !_.isEmpty(errorMessages.address) ? [_.first(errorMessages.address)] : undefined;
    parsedErrors.pastDueInformation.cardNumber = !_.isEmpty(errorMessages.cardNumber) ? [_.first(errorMessages.cardNumber)] : undefined;
    parsedErrors.pastDueInformation.cardholderName = !_.isEmpty(errorMessages.cardholderName) ? [_.first(errorMessages.cardholderName)] : undefined;
    parsedErrors.pastDueInformation.city = !_.isEmpty(errorMessages.city) ? [_.first(errorMessages.city)] : undefined;
    parsedErrors.pastDueInformation.companyName = !_.isEmpty(errorMessages.companyName) ? [_.first(errorMessages.companyName)] : undefined;
    parsedErrors.pastDueInformation.cvv = !_.isEmpty(errorMessages.cvv) ? [_.first(errorMessages.cvv)] : undefined;
    parsedErrors.pastDueInformation.expirationMonth = !_.isEmpty(errorMessages.expirationMonth) ? [_.first(errorMessages.expirationMonth)] : undefined;
    parsedErrors.pastDueInformation.expirationYear = !_.isEmpty(errorMessages.expirationYear) ? [_.first(errorMessages.expirationYear)] : undefined;
    parsedErrors.pastDueInformation.firstName = !_.isEmpty(errorMessages.firstName) ? [_.first(errorMessages.firstName)] : undefined;
    parsedErrors.pastDueInformation.lastName = !_.isEmpty(errorMessages.lastName) ? [_.first(errorMessages.lastName)] : undefined;
    parsedErrors.pastDueInformation.state = !_.isEmpty(errorMessages.state) ? [_.first(errorMessages.state)] : undefined;
    parsedErrors.pastDueInformation.zipcode = !_.isEmpty(errorMessages.zipcode) ? [_.first(errorMessages.zipcode)] : undefined;

    return parsedErrors;
}

function parseNewAccountErrors (errorMessages) {
    const parsedErrors = {newAccountInformation: {}};

    parsedErrors.newAccountInformation.address = !_.isEmpty(errorMessages.address) ? [_.first(errorMessages.address)] : undefined;
    parsedErrors.newAccountInformation.cardNumber = !_.isEmpty(errorMessages.cardNumber) ? [_.first(errorMessages.cardNumber)] : undefined;
    parsedErrors.newAccountInformation.cardholderName = !_.isEmpty(errorMessages.cardholderName) ? [_.first(errorMessages.cardholderName)] : undefined;
    parsedErrors.newAccountInformation.city = !_.isEmpty(errorMessages.city) ? [_.first(errorMessages.city)] : undefined;
    parsedErrors.newAccountInformation.companyName = !_.isEmpty(errorMessages.companyName) ? [_.first(errorMessages.companyName)] : undefined;
    parsedErrors.newAccountInformation.cvv = !_.isEmpty(errorMessages.cvv) ? [_.first(errorMessages.cvv)] : undefined;
    parsedErrors.newAccountInformation.expirationMonth = !_.isEmpty(errorMessages.expirationMonth) ? [_.first(errorMessages.expirationMonth)] : undefined;
    parsedErrors.newAccountInformation.expirationYear = !_.isEmpty(errorMessages.expirationYear) ? [_.first(errorMessages.expirationYear)] : undefined;
    parsedErrors.newAccountInformation.firstName = !_.isEmpty(errorMessages.firstName) ? [_.first(errorMessages.firstName)] : undefined;
    parsedErrors.newAccountInformation.lastName = !_.isEmpty(errorMessages.lastName) ? [_.first(errorMessages.lastName)] : undefined;
    parsedErrors.newAccountInformation.state = !_.isEmpty(errorMessages.state) ? [_.first(errorMessages.state)] : undefined;
    parsedErrors.newAccountInformation.zipcode = !_.isEmpty(errorMessages.zipcode) ? [_.first(errorMessages.zipcode)] : undefined;

    return parsedErrors;
}

// functions for additional fetching for scan data:
// function getWaitingForDirectories (parsedLiveReport) {
//     const iwaitedForData = [];
//
//     if (_.isEmpty(parsedLiveReport.report)) return [];
//     _.forEach(parsedLiveReport.report, (directoryData, index) => {
//         if (_.get(directoryData, 'report.status') === "in-progress" && _.size(directoryData.report) === 1) iwaitedForData.push(directoryData.siteId);
//     });
//
//     return iwaitedForData;
// }
//
// function makeAdditionalCall(businessId, scanId, parsedLiveReport, $this, $http, ListingService, triesLeft) {
//     if (triesLeft < 1 || !scanId) {
//         $this.payc.isReportLoaded = true;
//         replaceAllInProgressDirectivesWithNotFound(parsedLiveReport);
//         return updateReportActualInformation(parsedLiveReport, $this, ListingService);
//     }
//
//     setTimeout(() => {
//         $http.get(`/payments/fetch-webhook-data/${businessId}/${scanId}`)
//             .then(function (response) {
//
//                 if (_.isEmpty(response.data)) {
//                     console.log('Data is not ready!');
//                     return makeAdditionalCall(businessId, scanId, parsedLiveReport, $this, $http, ListingService, triesLeft - 1);
//                 }
//
//                 const responseReport = response.data.report;
//                 parsedLiveReport.report = responseReport;
//
//                 updateReportActualInformation(parsedLiveReport, $this, ListingService);
//
//                 const waitingForDirectories = getWaitingForDirectories(parsedLiveReport);
//                 if (_.isEmpty(waitingForDirectories)) {
//                     $this.payc.isReportLoaded = true;
//                     return;
//                 }
//
//                 return makeAdditionalCall(businessId, scanId, parsedLiveReport, $this, $http, ListingService, triesLeft - 1);
//             })
//             .catch(err => {
//                 console.error('Cannot make extra fetch data for reports', err);
//                 $this.payc.isReportLoaded = true;
//                 replaceAllInProgressDirectivesWithNotFound(parsedLiveReport);
//                 return updateReportActualInformation (parsedLiveReport, $this, ListingService);
//             });
//     }, 5000); // set for 5 sec
// }

// function replaceAllInProgressDirectivesWithNotFound(parsedLiveReport) {
//     // make all of the directories with in-progress status have:
//     parsedLiveReport.report = _.map(parsedLiveReport.report, resultObj => {
//         if (resultObj.report.status === "in-progress" && _.size(resultObj.report) === 1) {
//             resultObj.report = {"status": "no-result","errors": [{"message": "Not supported This Listing"}]};
//         }
//         return resultObj;
//     });
//
//     return parsedLiveReport;
// }
//
// function updateReportActualInformation (parsedLiveReport, $this, ListingService) {
//     let FMBGroupedResult, FMEGroupedResult;
//     if (parsedLiveReport.foodMenusReport) {
//         const foodMenuReport = parsedLiveReport.foodMenusReport;
//         FMBGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FMB');
//         FMEGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FME');
//     }
//
//     parsedLiveReport = parsedLiveReport.report;
//     let prBusinessGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PR'),
//         AIGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'AI'),
//         SEGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'SE'),
//         DGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'D'),
//         NMGGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'NMG'),
//         AGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'A');
//
//     $this.payc.reportData = {prBusinessGroupedResult, AIGroupedResult, SEGroupedResult, DGroupedResult, NMGGroupedResult, AGroupedResult, FMBGroupedResult, FMEGroupedResult};
// }

class PaymentCtrl {

  constructor($scope, $rootScope, $http, $uibModal, $cookies, $timeout, $window, $location, blockUI, CheckoutService, CommonUtils, CheckoutConst, ListingService, DefaultReviewsData) {
    var $this = this;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$cookies = $cookies;
    this.$http = $http;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$location = $location;
    this.blockData = blockUI.instances.get('block-data');

    //cookie domain:
    this.cookieDomain = (this.$window.location.host === 'www.pr2.local' || this.$window.location.host === 'pr2.local') ? '.pr2.local' : '.pr.business';

    //models:
//    this.authData = {};
    this.states = [];
    this.categories = {};
    this.errors = {businessInformation: {}, contactInformation: {}, creditCardBillingAddress: {}, creditCardInformation: {}, category: {}};
    this.notValidationError = null;
    this.callUsMessage = null;
    this.bannerMessage = null;
    this.paymentSubmitted = false;

    //scopes:
    this.payc = $scope.payc;
    $scope.months = CheckoutConst.monthsValues;
    $scope.years = CheckoutService.genYears();
    $scope.countries = [
        { code: 'US', name: 'USA' },
        { code: 'CA', name: 'Canada' },
        { code: 'UK', name: 'United Kingdom' },
        { code: 'AU', name: 'Australia' },
    ];

    //init steps:
    this.payc.initFirstStep = (stepNumber, listingsCompany, scanCookieData) => {
        $this.$scope.step = Number(stepNumber);
        $this.payc.listingsCompany = JSON.parse(listingsCompany);

        $this.payc.listingsCompanyTop = _.slice($this.payc.listingsCompany, 1, 13);

        //get authData:
        $http.get('/auth')
        .then(function (response) {
            if(!!response.data.authData){
                $this.authData = response.data.authData;
                $this.scanInformation.firstName = response.data.authData.firstName;
                $this.scanInformation.lastName = response.data.authData.lastName;
            }
        })
        .catch(function (error) {
            console.error(error);
        });

        try {
            const parsedScanCookieData = JSON.parse(scanCookieData);
            if (!_.isEmpty(parsedScanCookieData)) {
                $this.scanInformation = {name: parsedScanCookieData.name,
                    firstName: parsedScanCookieData.firstName,
                    lastName: parsedScanCookieData.lastName,
                    phone: parsedScanCookieData.phone};
            } else {
                $this.scanInformation = {name: '', firstName: '', lastName: '', phone: ''};
            }

            $this.determineUserInformation = {email: ''};
            if (!_.isEmpty(parsedScanCookieData) && parsedScanCookieData.userEmail) {
                $this.determineUserInformation.email = parsedScanCookieData.userEmail;
            }

        } catch (err) {
            console.log('Parsed cookie error', err);
            $this.scanInformation = {name: '', firstName: '', lastName: '', phone: ''};
        }
    };

    this.payc.initSecondStep = (stepNumber, isShowFoodMenu, website, businessId) => {
        $this.$scope.step = Number(stepNumber);
        $this.payc.website = website;

        //generate review:
        $this.blockData.start();
        $this.blockData.message('Scanning...');

        //get authData:
        $http.get('/auth')
        .then(function (response) {
            if(!!response.data.authData){
                $this.authData = response.data.authData;
            }
        })
        .catch(function (error) {
            console.error(error);
        });

        let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');

        if (businessId) {
            cookieFunnelPaymentData = {
                scanssoStep: { businessId },
            };
        }

        //if cookie has scanssoStep:
        if (cookieFunnelPaymentData.scanssoStep) {
            this._initScanssoStepPart(cookieFunnelPaymentData, isShowFoodMenu);
        } else {
            //if cookie has scanStep:
            this._initScanStepPart(cookieFunnelPaymentData, isShowFoodMenu);
        }
    };

    this._initScanssoStepPart = (cookieFunnelPaymentData, isShowFoodMenu) => {
        $this.businessId = _.get(cookieFunnelPaymentData, 'scanssoStep.businessId', null);

        //generate reviews by business id:
        $http.post(`/payments/generate-reviews-by-id/${$this.businessId}`)
        .then(function (response) {
            let parsedLiveReport = response.data.liveReportData || {};
            const features = response.data.features;
            const currentBusiness = response.data.currentBusiness;
            $this.payc.currentBusiness = currentBusiness;

            // check parsedLiveReport:
            $this.scanId = parsedLiveReport.scanId;
            $this.payc.isReportLoaded = true;
            // const waitingForDirectories = getWaitingForDirectories(parsedLiveReport);
            // if (!_.isEmpty(waitingForDirectories)) {
            //     $this.payc.isReportLoaded = false;
            //     makeAdditionalCall($this.businessId, $this.scanId, parsedLiveReport, $this, $http, ListingService, 36);
            // }

            const isUpgraded = _.includes(features, 'upgraded');

            $this.payc.isSynced = isUpgraded && _.includes(features, 'paid');

            $this.payc.visibilityScore = _.isEmpty(currentBusiness)
                ? ListingService.getVisibilityScoreV2(parsedLiveReport, isUpgraded)
                : ListingService.getVisibilityScoreV2Business(parsedLiveReport, isUpgraded, response.data.currentBusiness, response.data.prScoreRange);

            const rawMainScore = $this.payc.visibilityScore.score || 10;
            $this.payc.mainScore = _.round(rawMainScore);
            $this.payc.scoreColor = ListingService.getNAPScoreColor(rawMainScore);

            let FMBGroupedResult, FMEGroupedResult;
            if (parsedLiveReport.foodMenusReport) {
                const foodMenuReport = parsedLiveReport.foodMenusReport;
                FMBGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FMB');
                FMEGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FME');
            }

            parsedLiveReport = parsedLiveReport.report;
            let prBusinessGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PR'),
                AIGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'AI'),
                SEGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'SE'),
                DGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'D'),
                NMGGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'NMG'),
                AGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'A');

            $this.payc.reportData = {prBusinessGroupedResult, AIGroupedResult, SEGroupedResult, DGroupedResult, NMGGroupedResult, AGroupedResult, FMBGroupedResult, FMEGroupedResult};

            const membership = _.get(response, 'data.membership') || {};
            $this.payc.isSyncedBusiness = $this.payc.isSynced && (_.isEqual(membership.title, "Review Generator Plus") || _.isEqual(membership.title, "Synup Subscription"));

            $this.payc.isReviewsUploaded = true;
            $this.blockData.stop();
        })
        .catch(function (errors) {
            console.error(`Cannot generate business reviews by id: ${errors}`);

            let
                parsedLiveReport = DefaultReviewsData.emptyReviewDataV2(),
                features = [];

            if(isShowFoodMenu) {
                parsedLiveReport.foodMenusReport = DefaultReviewsData.emptyFoodMenusReviewDataV2().foodMenusReport;
            }

            const isUpgraded = _.includes(features, 'upgraded');
            $this.payc.isSynced = isUpgraded && _.includes(features, 'paid');
            $this.payc.currentBusiness = {};

            $this.businessId = null;

            $this.payc.visibilityScore = ListingService.getVisibilityScoreV2(parsedLiveReport, isUpgraded);
            const rawMainScore = $this.payc.visibilityScore.score || 10;
            $this.payc.mainScore = _.round(rawMainScore);
            $this.payc.scoreColor = ListingService.getNAPScoreColor(rawMainScore);

            let FMBGroupedResult, FMEGroupedResult;
            if (parsedLiveReport.foodMenusReport) {
                const foodMenuReport = parsedLiveReport.foodMenusReport;
                FMBGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FMB');
                FMEGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FME');
            }

            parsedLiveReport = parsedLiveReport.report;
            let prBusinessGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PR'),
                AIGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'AI'),
                SEGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'SE'),
                DGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'D'),
                NMGGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'NMG'),
                AGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'A');

            $this.payc.reportData = {prBusinessGroupedResult, AIGroupedResult, SEGroupedResult, DGroupedResult, NMGGroupedResult, AGroupedResult, FMBGroupedResult, FMEGroupedResult};

            if (isShowFoodMenu) {
                $this.payc.reportData.PRFGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PRF');
                $this.payc.reportData.FGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'F');
            }

            $this.payc.isSyncedBusiness = false;

            $this.payc.isReviewsUploaded = true;
            $this.blockData.stop();
        });

        if ($this.payc.website) {
            const options = {
                params: {
                    url: $this.payc.website,
                },
                headers: {
                    Authorization: $this.$cookies.get('access_token'),
                },
            };
            $http.get(`https://${envConfigs.lighthouseServer}/scan`, options)
                .then((response) => {
                    $this.payc.isLighthouseLoaded = true;
                    $this.payc.lighthouseResult = response.data || {};
                })
                .catch((err) => {
                    console.error('Cannot get lighthouse scan:', err);
                    $this.payc.isLighthouseLoaded = true;
                    $this.payc.lighthouseResult = {};
                });
        } else {
            $this.payc.isLighthouseLoaded = true;
            $this.payc.lighthouseResult = {};
        }

        //update lead status:
        var leadSource = _.get(cookieFunnelPaymentData, 'scanssoStep.leadSource', null);
        $this.$http.get('/payments/get-business-lead/' + $this.businessId)
        .then((response) => {
            if(response.status === 200 && response.data) {
                $this.scanssoLeadId = response.data;

                const leadData = {
                    id: response.data,
                    leadStatus: 'Ecommerce Review Step'
                }

                if (leadSource) {
                    leadData.leadSource = leadSource;
                }

                $this.$http.post('/payments/update-saleforce-lead', leadData)
                .then((response) => {
                    console.log('Lead is updated!');
                })
                .catch(function (response) {
                    console.error(response);
                });
            }
        })
        .catch(function (response) {
            console.error(response);
        });
    }

    this._initScanStepPart = (cookieFunnelPaymentData, isShowFoodMenu) => {

        //generate reviews:
        $http.post('/payments/generate-reviews')
        .then(function (response) {
            $this.businessId = response.data.businessId;
            let parsedLiveReport = response.data.liveReportData || {};
            const features = response.data.features;
            const currentBusiness = response.data.currentBusiness;
            $this.payc.currentBusiness = currentBusiness;

            $this.scanId = parsedLiveReport.scanId;
            $this.payc.isReportLoaded = true;
            // const waitingForDirectories = getWaitingForDirectories(parsedLiveReport);
            // if (!_.isEmpty(waitingForDirectories)) {
            //     $this.payc.isReportLoaded = false;
            //     makeAdditionalCall($this.businessId, $this.scanId, parsedLiveReport, $this, $http, ListingService, 36);
            // }

            const isUpgraded = _.includes(features, 'upgraded');

            $this.payc.isSynced = isUpgraded && _.includes(features, 'paid');

            $this.payc.visibilityScore = _.isEmpty(currentBusiness)
                ? ListingService.getVisibilityScoreV2(parsedLiveReport, isUpgraded)
                : ListingService.getVisibilityScoreV2Business(parsedLiveReport, isUpgraded, response.data.currentBusiness, response.data.prScoreRange);

            const rawMainScore = $this.payc.visibilityScore.score || 10;
            $this.payc.mainScore = _.round(rawMainScore);
            $this.payc.scoreColor = ListingService.getNAPScoreColor(rawMainScore);

            let FMBGroupedResult, FMEGroupedResult;
            if (parsedLiveReport.foodMenusReport) {
                const foodMenuReport = parsedLiveReport.foodMenusReport;
                FMBGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FMB');
                FMEGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FME');
            }

            parsedLiveReport = parsedLiveReport.report;
            let prBusinessGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PR'),
                AIGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'AI'),
                SEGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'SE'),
                DGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'D'),
                NMGGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'NMG'),
                AGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'A');

            $this.payc.reportData = {prBusinessGroupedResult, AIGroupedResult, SEGroupedResult, DGroupedResult, NMGGroupedResult, AGroupedResult, FMBGroupedResult, FMEGroupedResult};

            const membership = _.get(response, 'data.membership') || {};
            $this.payc.isSyncedBusiness = $this.payc.isSynced && (_.isEqual(membership.title, "Review Generator Plus") || _.isEqual(membership.title, "Synup Subscription"));

            $this.payc.isReviewsUploaded = true;
            $this.blockData.stop();
        })
        .catch(function (errors) {
            console.error(`Cannot generate business reviews: ${errors}`);

            let
                parsedLiveReport = DefaultReviewsData.emptyReviewDataV2(),
                features = [];

            if (isShowFoodMenu) {
                parsedLiveReport.foodMenusReport = DefaultReviewsData.emptyFoodMenusReviewDataV2().foodMenusReport;
            }

            const isUpgraded = _.includes(features, 'upgraded');
            $this.payc.isSynced = isUpgraded && _.includes(features, 'paid');
            $this.payc.currentBusiness = {};

            $this.businessId = null;

            $this.payc.visibilityScore = ListingService.getVisibilityScoreV2(parsedLiveReport, isUpgraded);
            const rawMainScore = $this.payc.visibilityScore.score || 10;
            $this.payc.mainScore = _.round(rawMainScore);
            $this.payc.scoreColor = ListingService.getNAPScoreColor(rawMainScore);

            let FMBGroupedResult, FMEGroupedResult;
            if (parsedLiveReport.foodMenusReport) {
                const foodMenuReport = parsedLiveReport.foodMenusReport;
                FMBGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FMB');
                FMEGroupedResult = ListingService.getGroupedResults(foodMenuReport, 'FME');
            }

            parsedLiveReport = parsedLiveReport.report;
            let prBusinessGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PR'),
                AIGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'AI'),
                SEGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'SE'),
                DGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'D'),
                NMGGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'NMG'),
                AGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'A');

            $this.payc.reportData = {prBusinessGroupedResult, AIGroupedResult, SEGroupedResult, DGroupedResult, NMGGroupedResult, AGroupedResult, FMBGroupedResult, FMEGroupedResult};

            if (isShowFoodMenu) {
                $this.payc.reportData.PRFGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'PRF');
                $this.payc.reportData.FGroupedResult = ListingService.getGroupedResults(parsedLiveReport, 'F');
            }

            $this.payc.isSyncedBusiness = false;

            $this.payc.isReviewsUploaded = true;
            $this.blockData.stop();
        });

        if ($this.payc.website) {
            const options = {
                params: {
                    url: $this.payc.website,
                },
                headers: {
                    Authorization: $this.$cookies.get('access_token'),
                },
            };
            $http.get(`https://${envConfigs.lighthouseServer}/scan`, options)
                .then((response) => {
                    $this.payc.isLighthouseLoaded = true;
                    $this.payc.lighthouseResult = response.data || {};
                })
                .catch((err) => {
                    console.error('Cannot get lighthouse scan:', err);
                    $this.payc.isLighthouseLoaded = true;
                    $this.payc.lighthouseResult = {};
                });
        } else {
            $this.payc.isLighthouseLoaded = true;
            $this.payc.lighthouseResult = {};
        }

        //update lead status:
        if (cookieFunnelPaymentData && cookieFunnelPaymentData.scanStep && cookieFunnelPaymentData.scanStep.leadId) {
            const leadData = {
                id: cookieFunnelPaymentData.scanStep.leadId,
                leadStatus: 'Ecommerce Review Step'
            }

            $this.$http.post('/payments/update-saleforce-lead', leadData)
            .then((response) => {
                console.log('Lead is updated!');
            })
            .catch(function (response) {
                console.error(response);
            });
        }
    }

    this.payc.initThirdStep = (stepNumber) => {
        $this.$scope.step = Number(stepNumber);
        $this.loadCategories(null, 0);

        $this.buildInformation = {
            name: '',
            phone: '',
            website: '',
            country: 'US',
            state: '',
            zipcode: '',
            city: '',
            address: '',
            category: [],
            description: ''
        };

        $this.contactInformation = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            mobilePhone: ''
        };

//        $this.isUserExists = false;
        $this.hasBusinessOwner = false;

        //get authData:
        $http.get('/auth')
        .then(function (response) {
            if(!!response.data.authData){
                $this.authData = response.data.authData;
                $this.contactInformation.email = response.data.authData.email.replace(/@.+/, '*****');
            }
        })
        .catch(function (error) {
            console.error(error);
        });

        let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');

//        $this._initBusinessDataIfExists(cookieFunnelPaymentData);
//        $this._checkIfUserExistsByEmail(cookieFunnelPaymentData);

        return new Promise(resolve =>
            resolve($this._checkIfBusinessHasOwner(cookieFunnelPaymentData)))
//            resolve($this._checkIfUserExistsByEmail(cookieFunnelPaymentData)))
        .then(() => $this._initBusinessDataIfExists(cookieFunnelPaymentData));
    }

    this._changeBusinessIfIsNotOwner = () => {
        let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');

        const authData = _.get($this, '$rootScope.authData', null);
        let userId = null;

        if (authData) {
            //if user is logged in - set contactInformation.email
            $this.contactInformation.email = authData.email.replace(/@.+/, '*****');
            userId = authData.id;

            //if there is error in email field clear it:
            const emailContactInformationError = _.get($this,'errors.contactInformation.email', null);
            if (emailContactInformationError) {
                $this.errors.contactInformation = {};
            }
        }

        //check if business is exists and user is exists:
        if (cookieFunnelPaymentData && cookieFunnelPaymentData.reviewStep
            && cookieFunnelPaymentData.reviewStep.businessId && $this.businessOwnerId) {
            $this.blockData.start();

            $http.get('/payments/check-user-if-owner/' + userId + '/' + cookieFunnelPaymentData.reviewStep.businessId)
            .then(function (response) {
                if (response.data.redirectUrl) {
                    $this.$window.location.href = response.data.redirectUrl || '/business/add';
                }

                if (response.data.newBusiness) {
                    cookieFunnelPaymentData.reviewStep.businessId = response.data.newBusiness.id;
                    $this.$cookies.putObject('funnelPaymentData', cookieFunnelPaymentData, {domain: $this.cookieDomain, path: '/'});

                    //update business data on page:
                    $this._initBusinessDataIfExists(cookieFunnelPaymentData);
                }
            })
            .catch(function (errors) {
                console.error(errors);
            });
            $this.blockData.stop();
        }
    }

    this._checkIfBusinessHasOwner = (cookieFunnelPaymentData) => {
        const businessId = _.get(cookieFunnelPaymentData, 'reviewStep.businessId', null);
        if (businessId) {
            $this.blockData.start();
            $http.get('/payments/get-business-owner-by-id/' + businessId)
            .then(function (response) {
                if (!_.isEmpty(response.data)) {
                    $this.hasBusinessOwner = response.data.hasBusinessOwner;
                    $this.businessOwnerId = response.data.id;
                }
            })
            .catch(function (errors) {
                console.error(`Cannot check user with email[${cookieFunnelPaymentData.scanStep.email}]: ${errors}`);
            });
            $this.blockData.stop();
        }
    }

    this._initBusinessDataIfExists = (cookieFunnelPaymentData) => {

        if (cookieFunnelPaymentData) {

            if (cookieFunnelPaymentData.scanStep) {
                $this.contactInformation.firstName = cookieFunnelPaymentData.scanStep.firstName || '';
                $this.contactInformation.lastName = cookieFunnelPaymentData.scanStep.lastName || '';
                $this.contactInformation.email = cookieFunnelPaymentData.scanStep.userEmail || '';
                $this.buildInformation.name = cookieFunnelPaymentData.scanStep.name || '';
                $this.buildInformation.phone = cookieFunnelPaymentData.scanStep.phone || '';
            }

            const businessId = _.get(cookieFunnelPaymentData, 'reviewStep.businessId', null) || _.get(cookieFunnelPaymentData, 'scanssoStep.businessId');
            if (businessId) {
                $this.blockData.start();

                $http.get('/business/get/' + businessId)
                .then(function (response) {
                    $this.buildInformation.id = response.data.id;
                    $this.buildInformation.name = response.data.name;
                    $this.buildInformation.phone = response.data.phone;
                    $this.buildInformation.website = response.data.website;
                    $this.buildInformation.country = response.data.countryCode || response.data.country || 'US';
                    $this.buildInformation.state = response.data.state;
                    $this.buildInformation.zipcode = response.data.zipcode;
                    $this.buildInformation.city = response.data.city;
                    $this.buildInformation.address = response.data.address;
                    $this.buildInformation.description = response.data.description;

                    if (response.data.Category && response.data.Category.Parent) {
                        $this.buildInformation.category.push(response.data.Category.Parent.id);
                        $this.buildInformation.category.push(response.data.Category.id);
                        $this.loadCategories(response.data.Category.Parent.id);
                    }
                    $this.business = response.data;
                })
                .catch(function (errors) {
                    console.error(`Cannot load business with id[${cookieFunnelPaymentData.reviewStep.businessId}]: ${errors}`);
                });
                $this.blockData.stop();
            }
        }

        const currentLeadId = _.get(cookieFunnelPaymentData, 'reviewStep.leadId');
        if (currentLeadId) {
            const leadData = {
                id: currentLeadId,
                leadStatus: 'Ecommerce Build Step'
            }

            $this.$http.post('/payments/update-saleforce-lead', leadData)
            .then((response) => {
                console.log('Lead is updated!');
            })
            .catch(function (response) {
                console.error(response);
            });
        }
    }

//    this._checkIfUserExistsByEmail = (cookieFunnelPaymentData) => {
//        if (cookieFunnelPaymentData.scanStep && cookieFunnelPaymentData.scanStep.userEmail) {
//
//            $this.blockData.start();
//            $http.get('/payments/check-user-by-email/' + cookieFunnelPaymentData.scanStep.userEmail)
//            .then(function (response) {
//                if (!_.isEmpty(response.data)) {
//                    $this.isUserExists = true;
//                    $this.businessOwnerId = response.data.id;
//                }
//            })
//            .catch(function (errors) {
//                console.error(`Cannot check user with email[${cookieFunnelPaymentData.scanStep.email}]: ${errors}`);
//            });
//            $this.blockData.stop();
//        }
//    }

    this.signInPopupPayment = (typeFormOpen)=> {
       $this.$scope.typeForm = typeFormOpen;
       $this.$scope.login = $this.contactInformation.email;
       $this.$scope.modalInstance = $uibModal
       .open({
          templateUrl: '/public/templates/signInPopupWithoutRedirect.tpl.html',
          size: 'login-panel-form',
          keyboard: true,
          bindToController: true,
          controller: 'PaymentUserCtrl',
          controllerAs: 'puc',
          scope: $this.$scope
       });
    };

      this.iframeSignInPopupWithoutRedirect = (typeFormOpen)=> {
          $this.$scope.typeForm = typeFormOpen;
          $this.$scope.modalInstance = $uibModal
          .open({
              templateUrl: '/public/templates/iframeSignInPopup.tpl.html',
              size: 'login-panel-form',
              keyboard: true,
              bindToController: true,
              controller: 'PaymentUserCtrl',
              controllerAs: 'puc',
              scope: $this.$scope
          });
      };

    this.payc.initFourthStep = (stepNumber, listingsCompany) => {
        $this.$scope.step = Number(stepNumber);
        $this.payc.listingsCompany = JSON.parse(listingsCompany);

        let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData') || {};
        const currentLeadId = _.get(cookieFunnelPaymentData, 'buildStep.leadId');
        if (currentLeadId) {
            const leadData = {
                id: currentLeadId,
                leadStatus: 'Ecommerce Fix Step'
            }

            $this.$http.post('/payments/update-saleforce-lead', leadData)
            .then((response) => {
                console.log('Lead is updated!');
            })
            .catch(function (response) {
                console.error(response);
            });
        }
    }

    this.payc.initFifthStep = (stepNumber) => {
        $this.$scope.step = Number(stepNumber);
        $this.isUseBusinessAddress = false;

        $this.checkoutInformation = {
            companyName: '',
            country: '',
            state: '',
            zipcode: '',
            city: '',
            address: '',

            firstName: '',
            lastName: '',
            name: '',
            number: '',
            cvv: '',
            expirationMonth: '',
            expirationYear: '',
            isAgree: false
        };

        $this.blockData.start();

        let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');
        let leadId = null;

        //try to get lead id from build step:
        if (cookieFunnelPaymentData.buildStep && cookieFunnelPaymentData.buildStep.leadId) {
            leadId = cookieFunnelPaymentData.buildStep.leadId;
        }

        //try to get lead id from checkout step:
        if (cookieFunnelPaymentData.checkoutStep && cookieFunnelPaymentData.checkoutStep.leadId) {
            leadId = cookieFunnelPaymentData.checkoutStep.leadId;
        }

        $http.post('/payments/init-checkout')
        .then(function (response) {

            $this.business = response.data.business;
            $this.userId = response.data.userId;
            $this.planData = response.data.planData;

            if ($this.planData && !_.isEmpty($this.planData.PaymentPlans)) {
                const paymentsPlan = _.first($this.planData.PaymentPlans) || {};
                $this.cardSummary = {originalPrice: paymentsPlan.subscriptionCost || 49, packageType: `${paymentsPlan.name || ''}`, termType: 'Monthly'};
            } else {
                $this.cardSummary = {originalPrice: 49, packageType: 'Starter', termType: 'Monthly'};
            }

            //clear previous cookie data and set data for current step:
            const checkoutCookieDataStep = {
                checkoutStep: {
                    businessId: $this.business.id,
                    userId: $this.userId,
                    leadId: leadId,
                    membershipId: _.get($this, 'planData.id')
                }
            };

            $this.isReviewGeneratorPlan = _.isEqual($this.planData.title, 'Review Generator Plus');

            $this.$cookies.putObject('funnelPaymentData', checkoutCookieDataStep, {domain: $this.cookieDomain, path: '/'});
        })
        .catch(function (errors) {
            console.error(`Cannot load business data: ${errors}`);
            $this.cardSummary = {originalPrice: 49, packageType: 'Starter', termType: 'Monthly'};
            $this.business = {};
        });
        $this.blockData.stop();

        $this._updateLeadInCheckoutPage(leadId);
    }

    this.initSixthStep = (stepNumber) => {
        $this.$scope.step = Number(stepNumber);
        const thankyouStepDataSlug = _.get($this.$cookies.getObject('funnelPaymentData'), 'thankyouStep.businessSlug', null);
        $this.businessSlug = thankyouStepDataSlug || '';
        $this.bannerMessage = _.get($this.$cookies.getObject('funnelPaymentData'), 'thankyouStep.bannerMessage', null);
    }

    this.initSeventhStep = (stepNumber, businessUrl) => {
        $this.$scope.step = Number(stepNumber);
        $this.businessSlug = businessUrl || '';
    }

    this.initPastDueStep = (stepNumber, opportunityId, opportunityInformation, states, isDirectBraintree, createPayment) => {
        $this.$scope.step = Number(stepNumber);
        $this.opportunityId = opportunityId;
        $this.createPayment = !!createPayment;
        $this.states = JSON.parse(states);

        let parsedOpportunityInformation = null;

        try {
            parsedOpportunityInformation = JSON.parse(opportunityInformation);

            if (parsedOpportunityInformation && parsedOpportunityInformation.address && parsedOpportunityInformation.address.businessName) {
                parsedOpportunityInformation.address.businessName = parsedOpportunityInformation.address.businessName.replace(/&#39;/g, "\'");
            }

        } catch (err) {
            console.error(err);
        }

        //init data:
        $this.pastDueInformation = {
            isDirectBraintree,

            companyName: '',
            country: 'US',
            state: '',
            zipcode: '',
            city: '',
            address: '',

            firstName: '',
            lastName: '',
            name: '',
            number: '',
            cvv: '',
            expirationMonth: '',
            expirationYear: '',
            isAgree: false,

            customerName: '',
            bppUrl: '',

            oneTimePrice: 0,
            todayCharges: 0,
        };

        if (parsedOpportunityInformation) {
            $this.pastDueInformation.oneTimePrice = parsedOpportunityInformation.amount || 0;
            $this.pastDueInformation.todayCharges = parsedOpportunityInformation.amount || 0;

            $this.pastDueInformation.companyName = (_.get(parsedOpportunityInformation, 'address.businessName', '') ||'').replace(/`/g, "'");
            $this.pastDueInformation.bppUrl = _.last(parsedOpportunityInformation.bppURL.split('/')) || '';
            $this.pastDueInformation.customerName = parsedOpportunityInformation.customerName || '';

            if (parsedOpportunityInformation.address) {
                $this.pastDueInformation.state = parsedOpportunityInformation.address.state;
                $this.pastDueInformation.zipcode = parsedOpportunityInformation.address.zipCode;
                $this.pastDueInformation.city = parsedOpportunityInformation.address.city;
                $this.pastDueInformation.address = parsedOpportunityInformation.address.street;

                if (!_.isEmpty($this.states)) {
                    $this.pastDueInformation.country = (_.first(_.filter($this.states, state => state.initials === $this.pastDueInformation.state)) || {}).countryCode || 'US';
                }
            }
        }
    }

    this.initNewAccountStep = (stepNumber, opportunityId, opportunityInformation, states, businessMembershipData) => {
        $this.$scope.step = Number(stepNumber);
        $this.opportunityId = opportunityId;
        $this.states = JSON.parse(states);

        let parsedOpportunityInformation = null,
            updateBusinessMembershipData = null;

        try {
            parsedOpportunityInformation = JSON.parse(opportunityInformation);
            updateBusinessMembershipData = JSON.parse(businessMembershipData);
        } catch (err) {
            console.error(err);
        }

        //init data:
        $this.newAccountInformation = {
            companyName: '',
            country: 'US',
            state: '',
            zipcode: '',
            city: '',
            address: '',
            phone: null,

            firstName: '',
            lastName: '',
            name: '',
            number: '',
            cvv: '',
            expirationMonth: '',
            expirationYear: '',
            isAgree: false,

            customerName: '',
            bppUrl: '',

            oneTimePayment: 0,
            recurringPayment: 0,
            todayCharges: 0,
        };

        //we set business annd membership Ids only if it is exist and only for newaccountsingleopp:
        if (!_.isEmpty(updateBusinessMembershipData)) {
            $this.newAccountInformation.updateBusinessMembershipData = updateBusinessMembershipData;
        }

        if (parsedOpportunityInformation) {
            $this.newAccountInformation.oneTimePayment = parsedOpportunityInformation.totalCollectedUpfront || 0;
            $this.newAccountInformation.recurringPayment = parsedOpportunityInformation.finalMonthlyAmount || 0;
            $this.newAccountInformation.todayCharges = $this.newAccountInformation.oneTimePayment + $this.newAccountInformation.recurringPayment;

            if ($this.$scope.step === 12) {
                $this.newAccountInformation.todayCharges = $this.newAccountInformation.oneTimePayment;
            }

            $this.newAccountInformation.companyName = (_.get(parsedOpportunityInformation, 'address.businessName', '') ||'').replace(/`/g, "'");
            $this.newAccountInformation.bppUrl = _.last(parsedOpportunityInformation.bppURL.split('/')) || '';
            $this.newAccountInformation.customerName = parsedOpportunityInformation.customerName || '';

            if (parsedOpportunityInformation.address) {
                $this.newAccountInformation.state = parsedOpportunityInformation.address.state;
                $this.newAccountInformation.zipcode = parsedOpportunityInformation.address.zipCode;
                $this.newAccountInformation.city = parsedOpportunityInformation.address.city;
                $this.newAccountInformation.address = parsedOpportunityInformation.address.street;
                $this.newAccountInformation.phone = parsedOpportunityInformation.address.phone;

                if (!_.isEmpty($this.states)) {
                    $this.newAccountInformation.country = (_.first(_.filter($this.states, state => state.initials === $this.newAccountInformation.state)) || {}).countryCode || 'US';
                }
            }
        }
    }

    this.initScanssoStep = (tokenData) => {
        var $this = this;

        try {
            const parsedTokenData = JSON.parse(tokenData);

            const authData = parsedTokenData.token;
            //attach user data to token:
            authData.user = parsedTokenData.user;

            $this.blockData.start();
            $this.$http.post('/scansso/sign-in-with-token', {authData: authData})
            .then((response) => {
                console.log('Logged in is successful');

                const cookieData = {
                    scanssoStep: {
                        businessId: parsedTokenData.businessId,
                        userId: parsedTokenData.user.id
                    }
                };

                $this.$cookies.putObject('funnelPaymentData', cookieData, {domain: $this.cookieDomain, path: '/'});
                $this.$window.location.href = '/review';
            })
            .catch(function (error) {
                console.error(error);
                $this.$window.location.href = '/not-found-scan';
            });
        } catch (err) {
            console.error(err);
            $this.$window.location.href = '/not-found-scan';
        }
    }

    this._updateLeadInCheckoutPage = (leadId) => {
          if (leadId) {
              const leadData = {
                  id: leadId,
                  leadStatus: 'Ecommerce Checkout Step'
              }

              $this.$http.post('/payments/update-saleforce-lead', leadData)
              .then((response) => {
                  console.log('Lead is updated!');
              })
              .catch(function (response) {
                  console.error(response);
              });
          }
    }

    $scope.isNumber = (value) => {
        return _.isNumber(value);
    };

    $scope.getCeilValue = (value) => {
        return Math.ceil(value);
    }

    $scope.isMobile = () => {
      return CommonUtils.isMobile();
    };

    this.onSelectCategories = function(id) {
      this.loadCategories(id);
    }.bind(this);

    this.addWebsiteUrl = function(url){
      let validUrl = url;
      if (url.indexOf('http') === -1) {
        validUrl = `http://${url}`;
      }
      return validUrl ;
    };

    this.loadCategories = (parentId, level, limit)=>{
      let $this = this,
        params = {
          limit: limit || 10000,
          level: level,
          parentId: parentId
        };

      $this.$http.get('/category', {params: params})
        .then(onSuccess)
        .catch(onError);

      function onSuccess(response) {
        const hideCategoriesNames = ['All'];
        if(response.data.count > 0){
          let rows = response.data.rows.filter(item => hideCategoriesNames.indexOf(item.name) === -1);
          $this.categories[_.get(_.head(rows), 'syphLevel', 0)] = rows;
        }
      }

      function onError(error) {
        console.error(error);
      }
    };

    $this.payc.isNotError = report => {
        return report && _.isEmpty(report.errors);
    };

    $this.payc.checkStatus = (report)=> {
      let image = 'attention', color = 'red', fontWeight = 'bold';

      if (!report.match_address && !report.match_name && !report.match_phone) {
        image = 'close';
      } else if (report.match_address && report.match_phone && report.match_name) {
        image = 'check';
        color = 'black';
        fontWeight = ''
      }

      return {image: image, color: color, fontWeight: fontWeight};

    };

    $this.payc.isUpgraded = isUpdated => {
      return isUpdated === 'true';
    };

    PaymentCtrl.init();
  }

  static init() {
  }

  setStates(states) {
    this.states = JSON.parse(states);
  }

  setAsBusinessAddress() {
    //if set as business address, update values for billing:
    if (this.isUseBusinessAddress) {
      this.checkoutInformation.companyName = this.business.name;
      this.checkoutInformation.address = this.business.address;
      this.checkoutInformation.city = this.business.city;
//      this.checkoutInformation.country = this.business.country;
      this.checkoutInformation.country = this.business.country || this.business.countryCode || 'US';
      this.checkoutInformation.state = this.business.state;
      this.checkoutInformation.zipcode = this.business.zipcode;
    }
  }

  /**
   * Create request for adding business
   */

  //separated step sending:

  sendScanStep() {
      const $this = this;
      $this.errors = {scanInformation: {}, determineUserInformation: {}};
      $this.notValidationError = null;

      let isValidData = validateInputScan($this.scanInformation, $this.errors);
      let isValidDetermineEmail = true;

      let userEmail = null;
      if ($this.authData && $this.authData.email) {
          userEmail = $this.authData.email;
      } else {
          userEmail = $this.determineUserInformation.email;
          isValidDetermineEmail = validateDetermineEmail(userEmail, $this.errors);
      }

      const inputData = $this.scanInformation;
      inputData.userEmail = userEmail;

      // set no_google param:
      inputData.noGoogle = true;

      if (isValidData && isValidDetermineEmail) {
          const leadData = {
              name: $this.scanInformation.firstName,
              lastName: $this.scanInformation.lastName,
              businessName: $this.scanInformation.name,
              phone: $this.scanInformation.phone,
              leadSource: 'Organic BPP Scan',
              service: ["websites"],
              Status: 'Ecommerce Scan Step'
          };

          $this.blockData.start();
          $this.$http.post('/payments/create-saleforce-lead', leadData)
          .then((response) => {
              console.log(response);
              if(response.status === 200) {
                  inputData.leadId = response.data.id;
                  $this.$cookies.putObject('funnelPaymentData', {scanStep: inputData}, {domain: $this.cookieDomain, path: '/'});

                  // send to /payments/review:
                  $this.$window.location.href = '/review';
              } else {
                  $this.blockData.stop();
              }
          })
          .catch(function (response) {
              console.error(response);

              $this.$cookies.putObject('funnelPaymentData', {scanStep: inputData}, {domain: $this.cookieDomain, path: '/'});

              // send to /payments/review:
              $this.$window.location.href = '/review';

//              if (response.data && response.data.details) {
//                  $this.notValidationError = response.data.details;
//              } else {
//                  $this.notValidationError = 'Cannot create lead in this step. Please, try again.';
//              }
//              $this.blockData.stop();
          });
      } else {
          $this.notValidationError = 'Wrong inputs';
      }
  }

//  sendPrepareReviewData(businessId, businessName, businessPhone, membershipId) {
  sendPrepareReviewData(businessId, businessName, businessPhone) {
      const $this = this;
      const authData = _.get($this, '$rootScope.authData', {}) || {};

//      if (_.isEmpty(authData)) {
//          //if user is not logged in we will redirect to scan page
//          return $this.$window.location.href = '/scan';
//      }

      var preparedData = {
          name: businessName,
          phone: businessPhone,
          firstName: authData.firstName || '',
          lastName: authData.lastName || '',
          userEmail: authData.email || '',
//          membershipId,
          businessId,
      };

      //try to get leadId from previous steps:
      $this.blockData.start();
      $this.$http.get('/payments/get-business-lead/' + businessId)
      .then((response) => {
          console.log(response);
          if(response.status === 200) {
              preparedData.leadId = response.data;

              $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});

              // send to /payments/review:
              $this.$window.location.href = '/review';
          } else {
              $this.blockData.stop();
          }
      })
      .catch(function (response) {
          console.error(response);
//          $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
//
//          $this.$window.location.href = '/review';
          const leadData = {
              name: authData.firstName,
              lastName: authData.lastName,
              businessName: businessName,
              phone: businessPhone,
              leadSource: 'Organic BPP Scan',
              service: ["websites"],
              Status: 'Ecommerce Scan Step'
          };

          $this.blockData.start();
          $this.$http.post('/payments/create-saleforce-lead', leadData)
          .then((response) => {
              console.log(response);
              if(response.status === 200) {
                  preparedData.leadId = response.data.id;

                  $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
                  $this.$window.location.href = '/review';
              } else {
                  $this.blockData.stop();
              }
          })
          .catch(function (response2) {
              console.log(response2);
              $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
              $this.$window.location.href = '/review';
          });
      });

//      if (leadId) {
//          preparedData.leadId = leadId;
//      }

//      $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
//      $this.$window.location.href = '/review';

//      const leadData = {
//          name: authData.firstName,
//          lastName: authData.lastName,
//          businessName: businessName,
//          phone: businessPhone,
//          leadSource: 'Organic BPP Scan',
//          service: ["websites"],
//          Status: 'Ecommerce Scan Step'
//      };
//
//      $this.blockData.start();
//      $this.$http.post('/payments/create-saleforce-lead', leadData)
//      .then((response) => {
//          console.log(response);
//          if(response.status === 200) {
//              preparedData.leadId = response.data.id;
//
//              $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
//
//              // send to /payments/review:
//              $this.$window.location.href = '/review';
//          } else {
//              $this.blockData.stop();
//          }
//      })
//      .catch(function (response) {
//          console.log(response);
//          $this.$cookies.putObject('funnelPaymentData', {scanStep: preparedData}, {domain: $this.cookieDomain, path: '/'});
//
//          $this.$window.location.href = '/review';
//      });
  }

  sendReviewStep() {
      const $this = this;
      console.log('was click #sendReviewStep');

      let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');

      let userId = null;
      if ($this.authData) {
          userId = $this.authData.id;
      }

      const reviewData = {businessId: $this.businessId, userId: userId};

      if (_.isEmpty(cookieFunnelPaymentData)) {
          cookieFunnelPaymentData = {reviewStep: reviewData};
      } else {
          cookieFunnelPaymentData.reviewStep = reviewData;
      }

      const stepLeadId = _.get(cookieFunnelPaymentData, 'scanStep.leadId', null) || $this.scanssoLeadId;

      if (stepLeadId) {
          cookieFunnelPaymentData.reviewStep.leadId = stepLeadId;
      }

      $this.$cookies.putObject('funnelPaymentData', cookieFunnelPaymentData, {domain: $this.cookieDomain, path: '/'});
      $this.$window.location.href = '/build';
  }

  sendBuildStep(buildInformation, contactInformation) {
      var $this = this;

      $this.errors = {buildInformation: {}, contactInformation: {}};
      $this.notValidationError = null;
      $this.notValidationErrorInputs = null;
      $this.isUserEmailExist = false;

      const isValidBusinessData = validateBusinessInformation(buildInformation, $this.errors);
      let isValidContactData = true;

      const authData = $this.authData || $this.$rootScope.authData;

      //if user is not log in:
      if (_.isEmpty(authData)) {
          isValidContactData = validateContactInformation(contactInformation, $this.errors)
      }

      if (isValidBusinessData && isValidContactData) {
          let formParams = {};

          //if user is exist - sent only userId, if not - sent whole user contactInformation data:
          if (_.isEmpty(authData)) {
              formParams = changedBuildData(buildInformation, contactInformation);
          } else {
              formParams = changedBuildDataWithUserId(buildInformation, authData.id);
          }

          $this.blockData.start();
          $this.$http.post('/payments/build', formParams)
          .then((response) => {
              console.log(response);

              if(response.status === 200) {
//                  $this.paymentSubmitted = true;
                  $this.businessId = response.data.business.id;
                  $this.userId = response.data.user.id;

                  let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');

//                  //index business if is new:
//                  try {
//                      const existBusinessId = _.get(cookieFunnelPaymentData, 'reviewStep.businessId', null);
//
//                      if (window.location.href.startsWith('https://pr.business' && !existBusinessId)) {
//                          $this.$http.post('/google-indexing/update', {businessId: $this.businessId})
//                          .then(res => {
//                              console.log('Indexing is successful');
//                          })
//                          .catch(error => {
//                              console.error(error);
//                          });
//                      }
//                  } catch (err) {}

                  //create or update lead and put cookie, and send to next step:
                  $this.createOrUpdateBuildLeadStep($this, cookieFunnelPaymentData, authData);

              } else {
                  $this.blockData.stop();
              }
          })
          .catch(function (response) {

              if (!_.isEmpty(response.statusText)) {
                  $this.notValidationError = response.statusText;
              } else {
                  $this.notValidationError = _.get(response, 'data.message', 'Something went wrong');
              }

              const errorDetails = _.get(response, 'data.details', {}) || {};

              if (!!errorDetails.business && !!errorDetails.user) {
                  $this.notValidationError = 'Wrong entered information';
                  $this.errors = parseErrors(_.get(response, 'data.details', $this.errors));
              }

              if (errorDetails.business && errorDetails.business.business) {
                  $this.notValidationError = _.first(errorDetails.business.business);
              }

              if (errorDetails.user && !_.isEmpty(errorDetails.user.user)) {
                  const firstUserErrorDetail = _.first(errorDetails.user.user);
                  $this.notValidationError = firstUserErrorDetail;

                  $this.isUserEmailExist = _.endsWith(firstUserErrorDetail, 'already exists');
              }

              //if mobile phone is not valid by salesforce:
              const wrongPhone = _.get(response, 'data.details.possibleWrongPhoneError.phone', []);
              if (!_.isEmpty(wrongPhone)) {
                  const possibleWrongPhoneErrorMessage = _.first(wrongPhone);
                  if (possibleWrongPhoneErrorMessage.startsWith("Phone number")) {
                      $this.errors.buildInformation.phone = wrongPhone;
                      $this.notValidationError = 'Please, verify your Business Number one more time';
                  }
              }

              //if has already owner:
              if (errorDetails.message && errorDetails.message.startsWith("Business has an owner already")) {
                  $this.notValidationError = errorDetails.message;
              }

              //if No Reason Error Phrase:
              if (_.isEmpty($this.notValidationError)
                  || _.isEqual($this.notValidationError, 'Validation error')
                  || _.isEqual($this.notValidationError, 'No Reason Phrase')
                  || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                  $this.notValidationError = 'Please call us at 800-266-0219 to get assistance with your order';
              }

              console.error(response);
              $this.blockData.stop();
          });

      } else {
          $this.notValidationErrorInputs = 'Please verify entered information. Invalid entries are marked in red.';
      }
  }

  setAndUpdateBuildCookieData($this, cookieFunnelPaymentData, authData) {
      if (_.isEmpty(cookieFunnelPaymentData)) {
          cookieFunnelPaymentData = {buildStep: {businessId: $this.businessId, userId: $this.userId}};
      } else {
          cookieFunnelPaymentData.buildStep = {businessId: $this.businessId, userId: $this.userId};
      }

      if (cookieFunnelPaymentData.reviewStep && cookieFunnelPaymentData.reviewStep.leadId) {
          cookieFunnelPaymentData.buildStep.leadId = cookieFunnelPaymentData.reviewStep.leadId;
      }

      //if we get response with leadId, set this value:
      if ($this.responseLeadId) {
          cookieFunnelPaymentData.buildStep.leadId = $this.responseLeadId;
      }

      $this.$cookies.putObject('funnelPaymentData', cookieFunnelPaymentData, {domain: $this.cookieDomain, path: '/'});

      //if success and not log in, log in user:
      if (_.isEmpty(authData)) {
          $this.loginUser($this);
      } else {
//          $this.$window.location.href = '/checkout';
          $this.$window.location.href = '/fix';
      }
  }

  createOrUpdateBuildLeadStep($this, cookieFunnelPaymentData, authData) {
      if (cookieFunnelPaymentData && cookieFunnelPaymentData.reviewStep && cookieFunnelPaymentData.reviewStep.leadId) {
          const leadData = {
              id: cookieFunnelPaymentData.reviewStep.leadId,
              leadStatus: 'Ecommerce Build Step',
              email: !!authData ? authData.email : $this.contactInformation.email
          }

          $this.$http.post('/payments/update-saleforce-lead', leadData)
          .then((response) => {
              console.log('Lead is updated!');
              $this.setAndUpdateBuildCookieData($this, cookieFunnelPaymentData, authData);
          })
          .catch(function (response) {
              console.error(response);
              $this.setAndUpdateBuildCookieData($this, cookieFunnelPaymentData, authData);
          });
      } else {
          $this.$http.get('/payments/get-business-lead/' + $this.businessId)
          .then((response) => {
              console.log(response);
              if(response.status === 200) {
                  $this.responseLeadId = response.data;
                  $this.setAndUpdateBuildCookieData($this, cookieFunnelPaymentData, authData);
              }
          })
          .catch(function (response) {
              console.error('cannot get or create lead');
              console.error(response);
              $this.setAndUpdateBuildCookieData($this, cookieFunnelPaymentData, authData);
          });
      }
  }

  loginUser($this) {
      const credentialsData = {login: $this.contactInformation.email, password: $this.contactInformation.password};

      $this.$http.post('/payments/signin', credentialsData)
      .then((response) => {
          $this.$rootScope.authData = response.data.user;

          //redirect:
//          $this.$window.location.href = '/checkout';
          $this.$window.location.href = '/fix';
      })
      .catch(function (response) {
          if (response.statusText) {
              $this.notValidationError = 'BusinessCannot log in:' + response.statusText;
          } else {
              $this.notValidationError = 'BusinessCannot log in';
          }
          console.error(response);
          $this.blockData.stop();
      });
  }

  sendFixStep(planType) {
      var $this = this;

      let planData = {}

      let cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');
      if (!_.isEmpty(cookieFunnelPaymentData) && !_.isEmpty(cookieFunnelPaymentData.buildStep)) {
          planData = {
              plan: planType,
              userId: cookieFunnelPaymentData.buildStep.userId,
              businessId: cookieFunnelPaymentData.buildStep.businessId
          };
      }

      $this.blockData.start();
      $this.$http.post('/payments/choose-plan', planData)
      .then((response) => {
          console.log(response);

          if (_.isEmpty(cookieFunnelPaymentData)) {
              cookieFunnelPaymentData = {fixStep: response.data};
          } else {
              cookieFunnelPaymentData.fixStep = response.data;
          }

          $this.$cookies.putObject('funnelPaymentData', cookieFunnelPaymentData, {domain: $this.cookieDomain, path: '/'});
          $this.$window.location.href = '/checkout';
          $this.blockData.stop();
      })
      .catch(function (response) {
          if (response.statusText) {
              $this.notValidationError = response.statusText;
          }

          if (response.data.message) {
              $this.notValidationError = response.data.message;
          }

          console.error(response);
          $this.blockData.stop();
      });
  }

  sendCheckoutStep(checkoutInformation) {
      var $this = this;

      $this.errors = {checkoutInformation: {}};
      $this.notValidationError = null;
      $this.bannerMessage = null;

      const isValidCheckoutData = validateCheckoutInformation(checkoutInformation, $this.errors);
      const membershipId = $this.planData.id;

      const cookieFunnelPaymentData = $this.$cookies.getObject('funnelPaymentData');
      const leadId = _.get(cookieFunnelPaymentData, 'checkoutStep.leadId', null);

      if (isValidCheckoutData) {

          console.log("#Valid!");

          const formParams = changedCheckoutData($this.userId, $this.business.id, membershipId, checkoutInformation, leadId);
          $this.blockData.start();
          $this.$http.post('/payments/make-payments-charge', formParams)
          .then((response) => {
              console.log(response);
              if(response.status === 200 && !_.isEmpty(_.get(response.data, 'business.Slug.slug', null))) {

                  //get banner message if it is present:
                  if (_.isString(response.data.salesforce)) {
                      $this.bannerMessage = response.data.salesforce;
                  }
                  if (_.isString(response.data.yext)) {
                      $this.bannerMessage = response.data.yext;
                  }

                  const businessSlug = _.get(response, 'data.business.Slug.slug', '');
                  const checkoutCookieResultData = {thankyouStep: {businessSlug: businessSlug}};

                  //do we need to show red banner in thankyou page:
                  if ($this.bannerMessage) {
                      checkoutCookieResultData.thankyouStep.bannerMessage = $this.bannerMessage;
                  }

                  $this.$cookies.putObject('funnelPaymentData', checkoutCookieResultData, {domain: $this.cookieDomain, path: '/'});
//                  $this.businessSlug = response.data.business.Slug.slug;

//                  //update lead status:
//                  $this.updateCheckoutSoldLeadStep($this, leadId);

                  $this.$window.location.href = '/thankyou';
//                  $this.blockData.stop();
              } else {
                  $this.notValidationError = 'Something went wrong';
                  $this.blockData.stop();
              }
          })
          .catch(function (response) {
              if (response.data && !_.isEmpty(response.data.errorMessages)) {
                  $this.notValidationError = 'Wrong inputs';
                  const parsedErrors = parseCheckoutErrors(_.get(response, 'data.errorMessages', $this.errors));
                  $this.errors = parsedErrors.parsedErrors;
                  if (parsedErrors.notValidationError) {
                      $this.notValidationError = parsedErrors.notValidationError;
                  }
              } else {
                  $this.notValidationError = response.statusText;
              }

              //if cvv has error message:
              const possibleCVVError = _.get(response, 'data.errors.data.message');
              if (_.isEqual(possibleCVVError, 'Gateway Rejected: cvv')) {
                  $this.notValidationError = 'Please, verify CVV value and try again.';
                  $this.errors.checkoutInformation.cvv = ['Invalid cvv'];
              }

              //if No Reason Error Phrase:
              if (_.isEmpty($this.notValidationError)
                  || _.isEqual($this.notValidationError, 'Validation error')
                  || _.isEqual($this.notValidationError, 'No Reason Phrase')
                  || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                  $this.notValidationError = 'Something went wrong. Please call us at 800-266-0219 to get assistance with your order';
              }

              console.error(response);
              $this.blockData.stop();
          });

      } else {
          $this.notValidationError = 'Wrong inputs';
      }
  }

  sendPastDueStep(pastDueInformation) {
      var $this = this;

      $this.errors = {pastDueInformation: {}};
      $this.notValidationError = null;

      const isValidPastDueData = validatePastDueInformation(pastDueInformation, 'pastDueInformation', $this.errors);

      if (isValidPastDueData) {
          console.log("#Valid!");

          const formParams = changedPastDueData(pastDueInformation, $this.opportunityId);
          $this.blockData.start();
          $this.$http.post('/onlinepay/make-pastdue-charge', formParams, {withCredentials : true})
          .then((response) => {
              console.log(response);
              if(response.status === 200 && response.data) {
                  const businessSlug = '/' + $this.pastDueInformation.bppUrl;
                  $this.$cookies.putObject('funnelPaymentData', {thankyouStep: {businessSlug: businessSlug}}, {domain: $this.cookieDomain, path: '/'});
                  $this.$window.location.href = '/onlinepay/pastdue/thankyou';
              } else {
                  $this.notValidationError = 'Something went wrong';
                  $this.blockData.stop();
              }
          })
          .catch(function (response) {
              $this.notValidationError = response.statusText;

              if (response.data && response.data.details && !_.isEmpty(response.data.details.billing)) {
                  $this.notValidationError = 'Wrong inputs';
                  $this.errors = parsePastDueErrors(_.get(response, 'data.details.billing', $this.errors));
              }

              //if wrong opportuniny:
              const possibleOppotunityErrorMessage = _.get(response, 'data.message');
              if (possibleOppotunityErrorMessage && _.includes(possibleOppotunityErrorMessage, 'There is no Opportunity with ID')) {
                  $this.notValidationError = `Wrong opportunity ID: ${$this.opportunityId}`;
              }

              //if No Reason Error Phrase:
              if (_.isEmpty($this.notValidationError)
                  || _.isEqual($this.notValidationError, 'Validation error')
                  || _.isEqual($this.notValidationError, 'No Reason Phrase')
                  || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                  $this.notValidationError = 'Something went wrong. Please call us at 800-266-0219 to get assistance with your order';
              }

              console.error(response);
              $this.blockData.stop();
          });

      } else {
          $this.notValidationError = 'Wrong inputs';
      }
  }

  sendPastDueBraintreeStep(pastDueInformation) {
      var $this = this;

      $this.errors = {pastDueInformation: {}};
      $this.notValidationError = null;

      const isValidPastDueData = validatePastDueInformation(pastDueInformation, 'pastDueInformation', $this.errors);

      if (isValidPastDueData) {
          console.log("#Valid!");

          const formParams = changedPastDueData(pastDueInformation, $this.opportunityId);
          formParams.createPayment = $this.createPayment;

          $this.blockData.start();
          $this.$http.post('/onlinepay/braintree-make-pastdue-charge', formParams, {withCredentials : true})
              .then((response) => {
                  console.log(response);
                  if(response.status === 200 && response.data) {
                      const businessSlug = '/' + $this.pastDueInformation.bppUrl;
                      $this.$cookies.putObject('funnelPaymentData', {thankyouStep: {businessSlug}}, {domain: $this.cookieDomain, path: '/'});
                      $this.$window.location.href = '/onlinepay/braintree-pastdue/thankyou';
                  } else {
                      $this.notValidationError = 'Something went wrong';
                      $this.blockData.stop();
                  }
              })
              .catch((response) => {
                  if (response.data && response.data.details && !_.isEmpty(response.data.details.billing)) {
                      $this.notValidationError = 'Wrong inputs';
                      $this.errors = parsePastDueErrors(_.get(response, 'data.details.billing', $this.errors));
                  } else {
                      $this.notValidationError = 'Your credit card could not be successfully charged. Please contact our customer service department at 800-266-0219.';

                      if ($this.$scope.modalInstance) {
                          $this.$scope.modalInstance.close();
                      }

                      $this.$scope.modalInstance = $this.$uibModal.open({
                          templateUrl: '/public/templates/pastDuePaymentFailedPopup.tpl.html',
                          size: 'login-panel-form',
                          keyboard: true,
                          bindToController: true,
                          controller: 'UserCtrl',
                          controllerAs: 'uc',
                          scope: $this.$scope,
                      });
                  }

                  console.error(response);
                  $this.blockData.stop();
              });
      } else {
          $this.notValidationError = 'Wrong inputs';
      }
  }

  sendNewAccountStep(newAccountInformation) {
      var $this = this;

      $this.errors = {newAccountInformation: {}};
      $this.notValidationError = null;

      const isValidNewAccountData = validatePastDueInformation(newAccountInformation, 'newAccountInformation', $this.errors);

      if (isValidNewAccountData) {
          console.log("#Valid!");

          const formParams = changedPastDueData(newAccountInformation, $this.opportunityId, newAccountInformation.updateBusinessMembershipData);

          let postURL = '/onlinepay/make-newaccount-charge';
          let successfulRedirectLink = '/onlinepay/newaccount/thankyou';

          const stepNumber = _.get($this, '$scope.step') || 11;
          if (stepNumber === 12) {
              postURL = '/onlinepay/make-newaccountsingleopp-charge';
              successfulRedirectLink = '/onlinepay/newaccountsingleopp/thankyou';

              //delete Final Monthly Amount Pitched field:
              if (formParams && formParams.billing && formParams.billing.recurringPayment) {
                  delete formParams.billing.recurringPayment;
              }
          }

          $this.blockData.start();
          $this.$http.post(postURL, formParams, {withCredentials : true})
          .then((response) => {
              console.log(response);
              if(response.status === 200 && response.data) {
                  const businessSlug = '/' + $this.newAccountInformation.bppUrl;
                  $this.$cookies.putObject('funnelPaymentData', {thankyouStep: {businessSlug: businessSlug}}, {domain: $this.cookieDomain, path: '/'});
                  $this.$window.location.href = successfulRedirectLink;
              } else {
                  $this.notValidationError = 'Something went wrong';
                  $this.blockData.stop();
              }
          })
          .catch(function (response) {
              $this.notValidationError = response.statusText;

              if (response.data && response.data.details && !_.isEmpty(response.data.details.billing)) {
                  $this.notValidationError = 'Wrong inputs';
                  $this.errors = parseNewAccountErrors(_.get(response, 'data.details.billing', $this.errors));
              }

              //if wrong opportuniny:
              const possibleOppotunityErrorMessage = _.get(response, 'data.message');
              if (possibleOppotunityErrorMessage && _.includes(possibleOppotunityErrorMessage, 'There is no Opportunity with ID')) {
                  $this.notValidationError = `Wrong opportunity ID: ${$this.opportunityId}`;
              }

              //if cvv has error message:
              const possibleCVVError = _.get(response, 'data.message');
              if (possibleCVVError && _.isString(possibleCVVError) && possibleCVVError.includes('Gateway Rejected: cvv')) {
                  $this.notValidationError = 'Please, verify CVV value and try again.';
                  $this.errors.newAccountInformation.cvv = ['Invalid cvv'];
              }

              //if No Reason Error Phrase:
              if (_.isEmpty($this.notValidationError)
                  || _.isEqual($this.notValidationError, 'Validation error')
                  || _.isEqual($this.notValidationError, 'No Reason Phrase')
                  || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                  $this.notValidationError = 'Something went wrong. Please call us at 800-266-0219 to get assistance with your order';
              }

              console.error(response);
              $this.blockData.stop();
          });

      } else {
          $this.notValidationError = 'Wrong inputs';
      }
  }

//  updateCheckoutSoldLeadStep($this, leadId) {
//      if (leadId) {
//          const leadData = {
//              id: leadId,
//              leadStatus: 'Status',
//              sold: true
//          };
//
//          $this.$http.post('/payments/update-saleforce-lead', leadData)
//          .then((response) => {
//              console.log('Lead is updated!');
//          })
//          .catch(function (response) {
//              console.log(response);
//          });
//      }
//  }

  goToListingPage() {
    var $this = this;
    console.log('#goToListingPage was clicked');

    $this.blockData.start();

    //clear the cookie:
    $this.$cookies.putObject('funnelPaymentData', {}, {domain: $this.cookieDomain, path: '/'});
    $this.$window.location.href = $this.businessSlug;
  }

}

export default PaymentCtrl;
