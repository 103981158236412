import _ from 'lodash';

/**
 * BusinessUpgradeFlowCtrl
 */
angular.module('checkout', ['blockUI', 'ngCookies'])
    .controller('BusinessUpgradeFlowCtrl', ['$scope', '$http', '$window', '$timeout', '$interval', '$location', '$cookieStore', '$rootScope', 'blockUI', '$uibModal', 'CheckoutService', 'CommonUtils', 'CheckoutConst', function ($scope, $http, $window, $timeout, $interval, $location, $cookieStore, $rootScope, blockUI, $uibModal, CheckoutService, CommonUtils, CheckoutConst) {
        /* HARDCODE FOR NEW CHECKOUT */
        $scope.mainScore = 33;
        $scope.firstName = '';
        $scope.lastName = '';
        $scope.card = {
          date: '',
          cvv: '',
        };

      $scope.range = function(min, max, step) {
        step = step || 1;
        const input = [];
        for (let i = min; i <= max; i += step) {
          input.push(i);
        }
        return input;
      };

      $scope.currentYear = new Date().getFullYear();

        //import mobile checker
        $scope.isMobile = CommonUtils.isMobile.bind(CommonUtils);
        $scope.isTablet = CommonUtils.isTablet.bind(CommonUtils);

        var loading = blockUI.instances.get('settings');
        $scope.billingFrequencyClass = CheckoutConst.billingFrequencyClass;
        $scope.months = CheckoutConst.months;
        $scope.years = CheckoutService.genYears();
        $scope.promoCodes = [];


        //whether user agreed terms of use
        $scope.termsOfUse = true;
        $scope.isYearly = 'false';
/*        $scope.starter = () => $scope.isYearly === 'true' ? 'The Director Yearly' : 'The Director';
        $scope.complete = () => $scope.isYearly === 'true' ? 'The Owner Yearly' : 'The Owner';
        $scope.advanced = () => $scope.isYearly === 'true' ? 'The Boss Yearly' : 'The Boss';*/
        $scope.entrepreneur = () => 'Entrepreneur';

        $scope.upfront = () => 'Upfront Payment';
        $scope.monthly = () => 'Monthly';
        $scope.yearly = () => 'Yearly';

        $scope.viewTab = $scope.monthly();

        //block UI on start
        loading.start();

        //initial data for the page
        $scope.business = {};
        $scope.user = {};
        $scope.customerInfo = {};
        $scope.states = CheckoutConst.states;

        //container for changes
        $scope.updatedData = {};
        $scope.errors = {};

        $scope.status = false;

        //billing plans
        $scope.plans = [];
        //selected billing plan
        $scope.selectedPlan = {};
        $scope.selectedPromoPlan = {};
        $scope.selectedFullPlan = {};

        $scope.useBusinessAddress = true;
        $scope.switchAddress = function () {
            if ($scope.useBusinessAddress) {
                $scope.billing.companyName = $scope.business.name;
                $scope.billing.phone = $scope.business.phone;
                $scope.billing.address = $scope.business.address;
                $scope.billing.city = $scope.business.city;
                $scope.billing.state = $scope.business.state;
                $scope.billing.zipcode = $scope.business.zipcode;
            } else {
                //$scope.billing.companyName = '';
                //$scope.billing.phone = '';
                //$scope.billing.address = '';
                //$scope.billing.city = '';
                //$scope.billing.state = '';
                //$scope.billing.zipcode = '';
            }
            $scope.updateSelectsDropDown();
        };
        $scope.useStoredCC = false;
        $scope.switchStoredCC = function () {
            if ($scope.useStoredCC) {
                $scope.billing.expirationMonth = $scope.months[($scope.customerInfo.expirationDate.split('/')[0] - 1)];
                $scope.billing.expirationYear = $scope.customerInfo.expirationDate.split('/')[1];
                $scope.billing.number = $scope.customerInfo.maskedNumber;
                $scope.billing.cardholderName = $scope.customerInfo.cardholderName;
            } else {
                $scope.billing.expirationMonth = '';
                $scope.billing.expirationYear = '';
                $scope.billing.number = '';
                $scope.billing.cardholderName = '';
            }
            $scope.updateSelectsDropDown();
            $scope.errors = {};
        };

        $scope.updateBillingInfo = function () {
            $scope.billing.number = '';
            $scope.billing.cvv = '';
            $scope.useStoredCC = !$scope.useStoredCC;
        };


        //do something specific on each stage
        $scope.$watch('stage', function () {
            //progress bar for each stage
            var container = $('.steps-container ul li');

            if (!isNaN($scope.stage - 1)) {
              for (var j = 0; j < container.length; j++) {
                var icon = container[j].querySelector('i');
                if ($scope.stage - 1 === j) {
                  icon.classList.remove('icon','ion-ios-checkmark-circle');
                  container[j].classList.remove('completed');
                  container[j].classList.add('active');
                } else if (j < $scope.stage - 1) {
                  container[j].classList.remove('active');
                  container[j].classList.add('completed');
                  icon.classList.add('icon','ion-ios-checkmark-circle');
                } else {
                  container[j].classList.remove('active');
                }
                /*var $bubbles = $(container[j]).find('.bubble');
                $bubbles.removeClass('active');
                $bubbles.removeClass('empty');
                $($bubbles[$scope.stage - 1]).addClass('active');
                for (var i = $scope.stage; i < $bubbles.length; i++) {
                  $($bubbles[i]).addClass('empty');
                }*/
              }
            }

            if ($scope.stage === 1) {
                $scope.loadBusinessData();
            }
            if ($scope.stage === 2) {
                //set user name from landing page (from step 0)
                if (sessionStorage.getItem('firstName') !== 'undefined') {
                    $scope.user.firstName = sessionStorage.getItem('firstName');
                }
                if (sessionStorage.getItem('lastName') !== 'undefined') {
                    $scope.user.lastName = sessionStorage.getItem('lastName');
                }
                if (sessionStorage.getItem('email') !== 'undefined') {
                    $scope.user.email = sessionStorage.getItem('email');
                }

                $scope.loadBusinessData();
            }
            if ($scope.stage === 3) {
                $timeout(() => {
                    $scope.loadAccountData();
                }, 0);

            }
            if ($scope.stage === 4) {
                var membershipName = $location.search().membershipName;
                if (membershipName) {
                    $scope.loadAccountData();
                }
                $scope.updateSelectsDropDown();
                $("html, body").stop().animate({scrollTop: 0}, '500', 'swing');
            }
        });

        //rotating customer's testimonials
        $scope.article = 0;
        $scope.itemquantity = function (stage) {
            return (stage === 3) ? 3 : 3;
        };
        $interval(function () {
            if ($scope.article < $scope.itemquantity($scope.stage)) {
                $scope.article++;
            } else {
                $scope.article = 0;
            }
        }, 10000);

        //switching stages based on location.hash
        $scope.$watch(function () {
            return $location.hash()
        }, function (hash, oldHash) {

            if (hash) {


                if (parseInt(hash) === 2 && parseInt(oldHash) === 3) {
                    //user press back on step 3 - redirect to BPP, because we are already claimed
                    $window.location.href = '/business/main';
                } else if (parseInt(hash) === 4 && _.isEmpty($scope.selectedPlan) && !$location.search().membershipName) {
                    //redirect from step 4 if there is no selected plan
                    $scope.goPrev();
                } else {
                    $scope.stage = parseInt(hash);
                }
            } else {
                $scope.stage = 1;//1st step of the upgrade flow
            }

            loading.stop();




        });
        $scope.learnMore = false;
        $scope.learnMorePlan = function (planName) {
            if ($scope.learnMore === planName) {
                $scope.learnMore = false;
            } else {
                $scope.learnMore = planName;
            }
        };

        /**
         * Get to next step of upgrade flow
         */
        $scope.goNext = function () {
            var hash = $location.hash(),
                stage = parseInt(hash) || 1;
            stage++;
            if (stage <= 4) {
                history.pushState(null, '', '#!#' + stage);
            }
        };

        /**
         * Get to previous step of upgrade flow
         */
        $scope.goPrev = function () {
            var hash = $location.hash(),
                stage = parseInt(hash);
            stage--;
            if (stage >= 1) {
                history.pushState(null, '', '#!#' + stage);
            } else {
                var backUrl = sessionStorage.getItem('back_url');
                if (!backUrl) {
                    backUrl = '/seo-promotion';
                }
                $window.location.href = backUrl;
            }
        };

        // *** #1 ***

        /**
         * Load business data
         */
        $scope.loadBusinessData = function () {
            if ($scope.businessId) {
                $http.get('/business/get/' + $scope.businessId)
                    .then(function (response) {
                        $scope.business = response.data;
                        $scope.updateSelectsDropDown();
                    })
                    .catch(function (errors) {
                        console.error(errors);
                        loading.stop();
                        $window.location.href = '/';
                    });
            } else {
                //ready to create new business
                $scope.business = JSON.parse(sessionStorage.getItem('business'));
                $scope.updateSelectsDropDown();
            }
        };

        // *** #2 ***
        /**
         * Claim business and go to next step, create and sign in user if needed
         *
         * @method claimBusiness
         * @response routing or error messages
         */
        $scope.claimBusiness = function () {
            loading.start();
            //$cookieStore.remove("accessToken");

            if ($scope.$root.authData) {
                angular.extend($scope.user, $scope.$root.authData);
            }

            var params = {
                user: $scope.user,
                business: $scope.business
            };

            $http.post('/business/claim', params)
                .then(function (response) {
                    $rootScope.authData = response.data.user;

                    $scope.goNext();
                    //remove data from session storage
                    sessionStorage.removeItem('firstName');
                    sessionStorage.removeItem('lastName');
                    sessionStorage.removeItem('email');
                    sessionStorage.removeItem('business');
                    sessionStorage.removeItem('back_url');

                    $window.location.reload();
                })
                .catch(function (response) {
                    if (response.data.details) {
                        $scope.errors = response.data.details.errors;
                    } else {
                        $scope.errors['companyName'] = 'Opps! Something went wrong.';
                    }
                    console.error(response);
                })
                .finally(function () {
                    loading.stop();
                })
        };

        // *** #2 ***
        /**
         * Claim business and go to next step, create and sign in user if needed
         *
         * @method webLeadClaimBusiness
         * @response routing or error messages
         */
        $scope.webLeadClaimBusiness = function () {
            loading.start();
            //$cookieStore.remove("accessToken");

            if ($scope.$root.authData) {
                angular.extend($scope.user, $scope.$root.authData);
            }

            var params = {
                user: $scope.user,
                business: $scope.business,
                isWebLead: 1
            };

            $http.post('/business/claim', params)
                .then(function (response) {
                    $rootScope.authData = response.data.user;

                    // ll('tagEvent', 'Claim a Business', {'Business ID': $scope.business.id, 'Location': $scope.business.zipcode, 'Claim Type': 'SEO'});
//                    var _hsq = window._hsq = window._hsq || [];
//                    _hsq.push(['trackEvent', {
//                        id: '000005941336',
//                        value: {'Business ID': $scope.business.id, 'Location': $scope.business.zipcode, 'Claim Type': 'SEO'}
//                    }]);

                    if (response.data.autoVerified) {
                        //user account successfully verifies
                        $mcGoal && $mcGoal.processEvent('prVerifiedSuccess');
                    }

                    //remove data from session storage
                    sessionStorage.removeItem('firstName');
                    sessionStorage.removeItem('lastName');
                    sessionStorage.removeItem('email');
                    sessionStorage.removeItem('business');
                    sessionStorage.removeItem('back_url');

                    $window.location.href = '/' + $scope.business.Slug.slug;
                    // loading.stop();
                })
                .catch(function (response) {
                    if (response.data.details) {
                        $scope.errors = response.data.details.errors;
                    } else {
                        $scope.errors['companyName'] = 'Opps! Something went wrong.';
                    }
                    console.error(response);
                })
                .finally(function () {
                    loading.stop();
                })
        };

        /**
         * Get name of state
         */
        $scope.getStateName = function () {
            var state = _.first($scope.states.filter(function (st) {
                return st.id === $scope.business.state;
            }));
            return state ? state.initials : '';
        };

        // *** #3 ***

        $scope.additionalPlanPopup = () => {

            $scope.modalInstance = $uibModal
                .open({
                    templateUrl: '/public/templates/additional_entrepreneur_plan_tpl.html',
                    size: 'md',
                    keyboard: true,
                    scope: $scope
                });

            $scope.modalInstance.result
                .then(
                    () => {
                        // close modal
                    },
                    () => {
                        $scope.goPrev()
                    });
        };


        /**
         *
         * @param {Object} membership
         * @param {String} membership.title
         * @param {Object} membership.BusinessFeatures
         * @param {Object} membership.details
         * @param {Object} membership.addOns
         * @param {Object} membership.discounts
         * @param {Object} membership.billingFrequency
         * @param {Boolean?} noSetupPrice
         * @returns {{Plan}}
         */
        function mapPlan(membership, noSetupPrice) {
            var plan = {};
            plan.id = membership.id;
            plan.description = membership.description;
            plan.title = membership.title;
            plan.features = _.map(membership.BusinessFeatures, function (feature) {
                return feature.name;
            });
            plan.billingFrequency = membership.details.billingFrequency || 0;//number of months
            plan.billingCycle = CheckoutConst.billingCycles[membership.details.billingFrequency];//name of period
            plan.originalPrice = +membership.details.price || 0;
            plan.discountAmount = CheckoutService.calculateAmount(membership.details.discounts || []);
            plan.addOnsAmount = CheckoutService.calculateAmount(membership.details.addOns);
            plan.price = plan.originalPrice || 0 + plan.addOnsAmount - plan.discountAmount;

            if (!!noSetupPrice) {
                plan.priceSetup = 0;
            } else {
                $scope.setupDiscounts = CheckoutService.calculateSetupAmount(membership.details.discounts || []);
                plan.originalPriceSetup = CheckoutService.calculateSetupAmount(membership.details.addOns);
                plan.priceSetup = plan.originalPriceSetup - $scope.setupDiscounts;
            }
            plan.details = membership.details;

            return plan;
        }

        $scope.loadAccountData = function () {
            loading.start();
            $http.get('/business/get/' + $scope.businessId)
                .then(function (response) {
                    if (response.data && !_.isEmpty(response.data)) {
                        $scope.business = response.data;
                        $scope.user = $scope.$root.authData;

                        $http.get('/billing/get/my')
                            .then(function (response) {
                                $scope.customerInfo = response.data.customerInfo;
                                $scope.isCustomerInfo = !_.isEmpty($scope.customerInfo);

                                if ($scope.isCustomerInfo) {
                                    $scope.customerInfo.expirationMonth = $scope.months[($scope.customerInfo.expirationDate.split('/')[0] - 1)];
                                    $scope.customerInfo.expirationYear = $scope.customerInfo.expirationDate.split('/')[1];
                                }

                                $scope.billing = response.data.billing;

                                //https://trello.com/c/ekQOM6vl/943-upgrade-from-dashboard
                                //skip setup price if the business has been already upgraded and has feature 'paid'
                                $scope.plans = response.data.memberships
                                    .filter(m => !!m.details)
                                    .map(m => mapPlan(m)); //CheckoutService.hasFeature($scope.business, 'paid')

                                var membershipName = $location.search().membershipName;
                                if (membershipName) {
                                    $scope.pickPlan(membershipName);
                                }

                                $scope.updateSelectsDropDown();
                            })
                            .catch(function (errors) {
                                $scope.errors = errors.error;
                                console.error(errors);
                            })
                            .finally(function () {
                                //unblock UI after fetching data
                                loading.stop();

                            });

                    } else {
                        //not logged id user
                        $window.location.href = '/';
                    }
                })
                .catch(function (errors) {
                    console.error(errors);
                    loading.stop();
                });
        };

        // *** #4 ***
        $scope.upgradeBusiness = () => {
            if (_.isEmpty($scope.customerInfo) || $scope.useStoredCC) {
                return $scope.upgradeBusinessSend();
            }
            $scope.modalInstance = $uibModal.open({
                templateUrl: '/public/templates/modules/billingPopup.tpl.html',
                size: 'sm',
                backdrop: 'static',
                keyboard: true,
                scope: $scope
            });

            $scope.modalInstance.result
                .then(() => {
                    $scope.upgradeBusinessSend();
                });

        };

        /**
         * Picking plan and go to next step
         * @param planName
         */
        $scope.pickPlan = function (planName) {

            $scope.selectedFullPlan = $scope.getPlanByName(planName);
            $scope.selectedPlan = $scope.selectedFullPlan;
            $scope.promoCodes = [];
            $scope.goNext();

            !_.isEmpty($scope.customerInfo) ? $scope.useStoredCC = true : $scope.useStoredCC = false;


            $scope.switchAddress();
            $scope.switchStoredCC();

            $scope.checkAdditionalPlan(planName);
        };


        $scope.checkAdditionalPlan = function (planName) {

            if (planName === $scope.entrepreneur()) {
                $scope.additionalPlanPopup()
            }
        };


        $scope.upgradeBusinessSend = function () {

            $scope.errors = {};

            loading.start();

            var billing = _.clone($scope.billing),
                brainTree = CheckoutService.getBraintreeObject($window.location.host);

            if (billing && billing.number) {
                billing.number = brainTree.encrypt(billing.number)
            }

            if (billing && billing.cvv) {
                billing.cvv = brainTree.encrypt(billing.cvv)
            }

            if (!$scope.useStoredCC) {
                //change format date
                var dateFrom = $scope.months.indexOf($scope.billing.expirationMonth) + 1;
                billing.expirationMonth = ("0" + dateFrom).slice(-2);
            }
            billing.expirationYear = parseInt($scope.billing.expirationYear);

            billing.useStored = $scope.useStoredCC;
            billing.isNewBusinessAddress = !$scope.useBusinessAddress;


            var params = {
                billing: billing,
                customerInfo: $scope.customerInfo,
                businessId: $scope.businessId,
                promoCodes: JSON.stringify($scope.promoCodes),
                plan: $scope.selectedPlan,
                termsOfUse: $scope.termsOfUse
            };

            $http.post('/business/upgrade', params)
                .then(function (response) {
                    $window.location.href = '/business/upgrade/success/' + (response.data.transactionId || response.data.subscriptionId);
                })
                .catch(function (response) {
                    if (response.data.details) {
                        if ($scope.useBusinessAddress) {
                            $scope.useBusinessAddress = !$scope.useBusinessAddress;
                            $scope.switchAddress();
                        }

                        $scope.billing.number = '';
                        $scope.billing.cvv = '';

                        $scope.errors = response.data.details.errors;
                    } else {
                        $scope.errors['companyName'] = 'Opps! Something went wrong.';
                    }
                    console.error(response);
                })
                .finally(function () {
                    loading.stop();
                });
        };

        /**
         * Check given promo code entered and apply if valid
         * @param promoCode
         */
        var promoCodeTimeout = null;
        $scope.onPromoCodeChange = function (promoCode) {
            $timeout.cancel(promoCodeTimeout);
            promoCodeTimeout = $timeout(function () {

                $scope.errors.promoCode = [];
                if (promoCode && promoCode.length > 4) {
                    loading.start();

                    let allCodes = $scope.promoCodes.concat(promoCode);
                    $http.get(`/membership/${$scope.selectedPlan.id}/${$scope.business.id}`, {params: {promoCodes: JSON.stringify(allCodes)}})
                        .then(function (response) {

                            var plan = {},
                                membership = response.data;
                            //whether promo code for selected tariff plan or free promo code
                            if (membership && (membership.description.indexOf($scope.selectedFullPlan.description) > -1 || membership.price === 0)) {
                                plan = mapPlan(membership, CheckoutService.hasFeature($scope.business, 'paid'));

                                $scope.selectedPromoPlan = plan;
                                $scope.selectedPlan = $scope.selectedPromoPlan;
                                $scope.promoCodes.push(promoCode);

                                $scope.promoCode = "";
                            } else {
                                $scope.selectedPromoPlan = {};
                                $scope.selectedPlan = $scope.selectedFullPlan;
                                $scope.errors.promoCode = ['Can not apply this promo code.'];
                            }
                        })
                        .catch(function (errors) {
                            console.log(errors);
                            $scope.errors.promoCode = ['Can not apply this promo code.'];
                        })
                        .finally(() => {
                            allCodes = [];
                            loading.stop();
                        })
                } else {
                    $scope.selectedPlan = $scope.selectedFullPlan;
                }

            }, 1000);
        };

        // *** common part ***

        /**
         * Get plan by name from fetched list of plans
         * @param planDescription
         * @returns {Plan}
         */
        $scope.getPlanByName = planDescription => _.first($scope.plans.filter(plan => plan.description === planDescription));

        $scope.showTab = tabName => {
            $scope.viewTab = tabName;
        };

        $scope.calculateMonthPrice = plan => plan ? Math.ceil(plan.originalPrice / plan.details.billingFrequency) : "";


        $scope.pickClassColor = function (selectedPlanName) {
            let className = '',
                selectedPlan = selectedPlanName ? selectedPlanName.replace("Yearly", "").toLowerCase() : '';

            switch (selectedPlan) {
                case 'the director':
                    className = 'starter';
                    break;
                case 'the owner':
                    className = 'complete';
                    break;
                case 'the boss':
                    className = 'advanced';
                    break;
                default :
                    className = 'starter';
            }

            return className
        };

        /**
         * Run animated scroll to HTML element with given id
         * @param id
         */
        $scope.scrollToElement = function (id) {
            var target = $('#' + id);
            if (target.length > 0) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1000);
            }
        };

        /**
         * "Essential PROMOCODE:TODAY10" -> "Essential"
         * @param planName
         * @returns {*}
         */
        $scope.normalizePlanName = function (planName) {
            var index = planName ? planName.indexOf('PROMOCODE') : -1,
                normalizedPlanName = planName;

            if (index > 0) {
                normalizedPlanName = planName.substring(0, index - 1);
            }

            return normalizedPlanName;
        };

        var geoCodeTimeout = null;
        $scope.onFindLocation = function () {
            $timeout.cancel(geoCodeTimeout);
            geoCodeTimeout = $timeout(function () {
                $scope.findLocation();
            }, 1000);
        };

        /**
         * Look for location by entered address
         */
        $scope.findLocation = function () {
            $http.get('/location/find' +
                '?address=' + $scope.business.address +
                '&city=' + $scope.business.city +
                '&state=' + _.first($scope.states.filter(function (st) {
                    return st.initials === $scope.business.state
                })).name +
                '&zipcode=' + $scope.business.zipcode)
                .then(function (response) {
                    var location = response.data.location;
                    $scope.business.latitude = location.latitude;
                    $scope.business.longitude = location.longitude;
                })
                .catch(function (error) {
                    console.error(error);
                })
        };

        /**
         * show tooltips
         */
        $(document).ready(function () {
            var $tooltip = $('[data-toggle="tooltip"]');
            $tooltip.tooltip();
            $tooltip.click(function (e) {
                    e.preventDefault();
                }
            );
        });

        /**
         * Workaround for dynamic selects: manually apply 'bootstrap-select' plugin
         */
        $scope.updateSelectsDropDown = function () {
            $timeout(function () {

                var divs = $(".pr-selectpicker");
                if (!_.isEmpty(divs)) {
                    divs.hide();
                    divs.selectpicker('refresh');
                }

            }, 1000);
        };


    }])
    .controller('BusinessSubscriptionUpgradeFlowCtrl', ['$scope', '$http', '$window', '$timeout', '$interval', '$location', '$cookieStore', '$rootScope', 'blockUI', '$uibModal', 'CheckoutService', 'CommonUtils', 'CheckoutConst', function ($scope, $http, $window, $timeout, $interval, $location, $cookieStore, $rootScope, blockUI, $uibModal, CheckoutService, CommonUtils, CheckoutConst) {

        let loading = blockUI.instances.get('settings'),
            that = this;
        that.useNewBilling = false;
        that.plans = [];

        that.selectedPlan = {};
        that.selectedFullPlan = {};
        that.promoCodes = [];
        that.addons = [];
        that.billing = {
            cvv: '',
            number: '',
            cardholderName: '',
            expirationMonth: '',
            expirationYear: '',
            companyName: '',
            address: '',
            city: '',
            state: '',
            zipcode: ''
        };

        // that.startSubscriptionDate = 0;
        that.startSubscriptionDays = CheckoutService.genDays();



        that.states = CheckoutConst.states;
        that.months = CheckoutConst.months;
        that.errors = {};
        that.years = CheckoutService.genYears();


        that.init = (businessId, txKey) => {
            that.businessId = businessId;
            that.transactionKey = txKey;
            that.loadCheckoutData();
        };

        that.changePlan = () => {
            that.selectedFullPlan = that.selectedPlan;
            that.promoCodes = [];
        };


        let mapPlan = membership => {
            let plan = {};
            plan.id = membership.id;
            plan.description = membership.description;
            plan.title = membership.title;
            plan.features = _.map(membership.BusinessFeatures, feature => feature.name);
            plan.billingFrequency = membership.details.billingFrequency;
            plan.originalPrice = +membership.details.price;
            plan.billingCycle = CheckoutConst.billingCycles[membership.details.billingFrequency];//name of period
            plan.discountAmount = CheckoutService.calculateAmount(membership.details.discounts);
            plan.addonPrice = CheckoutService.calculateAddon(membership.details);
            plan.price = plan.originalPrice - plan.discountAmount;
            plan.details = membership.details;
            return plan;
        };

        that.loadCheckoutData = () => {

            loading.start();


            let getMyBilling = () => {

                let onSuccess = response => {

                    that.plans = response.data.memberships
                        .filter(m => !!m.braintreeReference && !!m.details && !m.title.includes('Upfront-PowerListing'))
                        .map(m => mapPlan(m)); //TODO add && !single  hasFeature($scope.business, 'paid')
                    that.selectedPlan = that.plans.find(item => item.title === 'Monthly plan');
                    that.selectedFullPlan = that.selectedPlan;
                    that.addons = response.data.addons || [];
                };

                let onError = errors => {
                    that.errors = errors.error;
                    console.error(errors);
                };

                let onFinally = () => {
                    loading.stop();
                };


                return $http.get('/billing/get/my')
                    .then(onSuccess)
                    .catch(onError)
                    .finally(onFinally)


            };

            getMyBilling();

        };


        that.submitOrder = () => {

            that.errors = {};
            let billing = {};

            loading.start();


            if (that.useNewBilling) {

                let brainTree = CheckoutService.getBraintreeObject($window.location.host);
                billing = _.clone(that.billing);

                if (billing && billing.number) {
                    billing.number = brainTree.encrypt(billing.number)
                }

                if (billing && billing.cvv) {
                    billing.cvv = brainTree.encrypt(billing.cvv)
                }

                let dateFrom = that.months.indexOf(that.billing.expirationMonth) + 1;
                billing.expirationMonth = ("0" + dateFrom).slice(-2);
                billing.expirationYear = parseInt(that.billing.expirationYear);
            }

            let params = {
                email: that.email,
                transactionKey: that.transactionKey,
                startSubscriptionOver: that.startSubscriptionOver,
                businessId: that.businessId,
                billing: billing,
                additionalAddons: JSON.stringify(that.addons.filter(item => item.isChecked).map(item => item.code) || []),
                promoCodes: JSON.stringify(that.promoCodes),
                plan: that.selectedFullPlan,
                useNewBilling: that.useNewBilling
            };


            let onError = response => {
                if (response.data.details) {

                    that.errors = response.data.details.errors;
                } else {
                    that.errors['companyName'] = 'Opps! Something went wrong.';
                }
                console.error(response);
            };


            $http.post(`/business/subscription-activation`, params)
                .then(response => {
                    console.log(response);
                    $window.location.href = `/business/subscription-activation/success/${(response.data.subscriptionId)}/${response.data.businessName}`;
                })
                .catch(onError)
                .finally(() => {
                    loading.stop()
                });


        };


        let promoCodeTimeout = null;


        that.calcViewPrice = () =>{
            return that.selectedFullPlan.price + that.addons
                    .filter(item => item.isChecked)
                    .reduce((previous, current)=>(previous + current.discount), 0);
        };

        that.onPromoCodeChange = promoCode => {

            $timeout.cancel(promoCodeTimeout);
            promoCodeTimeout = $timeout(() => {

                that.errors.promoCode = [];
                if (promoCode && promoCode.length > 4) {
                    loading.start();

                    let allCodes = that.promoCodes.concat(promoCode);


                    let onSuccess = response => {

                        let plan = {},
                            membership = response.data;
                        //whether promo code for selected tariff plan or free promo code
                        if (membership && (membership.id.indexOf(that.selectedPlan.id) > -1)) {
                            plan = mapPlan(membership); //hasFeature(that.business, 'paid')

                            that.selectedFullPlan = plan;
                            that.promoCodes.push(promoCode);

                            that.promoCode = "";
                        } else {
                            that.errors.promoCode = ['Can not apply this promo code.'];
                        }
                    };

                    let oError = errors => {
                        console.log(errors);
                        that.errors.promoCode = ['Can not apply this promo code.'];
                    };

                    let onFinally = () => {
                        allCodes = [];
                        loading.stop();
                    };

                    $http.get(`/membership/${that.selectedPlan.id}/${that.businessId}`, {params: {promoCodes: JSON.stringify(allCodes)}})
                        .then(onSuccess)
                        .catch(oError)
                        .finally(onFinally)
                }

            }, 1000);
        };


        /*app.service('hexafy', function() {
         this.myFunc = function (x) {
         return x.toString(16);
         }
         });*/


    }])
    .service('CheckoutService', function () {

        return {
            getBraintreeObject: getBraintreeObject,
            hasFeature: hasFeature,
            calculateAmount: calculateAmount,
            calculateAddon: calculateAddon,
            calculateSetupAmount: calculateSetupAmount,
            genYears: genYears,
            genDays: genDays,
            calculatedDate: calculatedDate
        };


        /**
         * Calculate amount of addOns or discounts for 1 billing cycle (aka 'setup price')
         * @param {Object} modifications - addOns or discounts
         * @returns {Number} total amount
         */
        function calculateSetupAmount(modifications) {
            return modifications
                .filter(function (d) {
                    return d.numberOfBillingCycles === 1;
                })
                .map(function (d) {
                    return parseFloat(d.amount)
                })
                .reduce(function (pv, cv) {
                    return pv + cv;
                }, 0);
        }

        function calculateAddon(modifications) {
            const addons = modifications.addOns;
            let price = 0;
            if(!addons || addons.length === 0){
                return price;
            }

            return addons.reduce((prev, current)=> (prev + (~~current.amount))
            , price)
        }


        /**
         * Calculate amount of addOns or discounts
         * @param {Object} modifications - addOns or discounts
         * @returns {Number} total amount
         */
        function calculateAmount(modifications) {
            return modifications
                .filter(d => !!d.neverExpires)
                .map(d => parseFloat(d.amount))
                .reduce((pv, cv) => pv + cv, 0);
        }

        /**
         * Whether business has %featureName% or not
         * @param {Object} business
         * @param {Object} business.Membership
         * @param {String} featureName
         * @returns {boolean|Boolean}
         */
        function hasFeature(business, featureName) {
            let features = _.get(business, 'Membership.BusinessFeatures', []),
                featureNames = _.map(features, 'name');

            return _.includes(featureNames, featureName);
        }

        function genYears() {
            let startYear = new Date().getYear() + 1900,
                years = [];
            for (let year = startYear; year < startYear + 10; year++) {
                years.push(year);
            }

            return years;
        }

        function genDays() {
            let startDay = 1,
                days = [];
            for (let day = startDay; day < startDay + 30; day++){
                days.push(day);
            }

            return days;
        }

        function calculatedDate(maxDayDate) {
            let now = new Date();
            return now.setDate(now.getDate() + maxDayDate);
        }



        function getBraintreeObject(location) {
            let sandboxKey = "MIIBCgKCAQEAvWLzLyMnluK+RnzPUlTVya6Q24ozX3TIPLLuCWBz1Re/Skc3sX8zlbXh3DkPRDpv6YDz7HrPkdpVDKnWZ7hJYzuBTg3OF1Vt/5Xob3BgB043s2bSGcUco0bAp4MdhSPPUNfxiE71rdpocak6lmUX4b17n3rnXX9ifRqs2bBkhiH92Xu4XFYe+5U47CFr0wGIARiqzxud07k/1/0D5rA5A/0CSbHULmLOEl1u23W4RyISFVTNZa8YAujl4ZcZVitxWfK4CTJSXvLXHHd9zO/p4p6Rwdis6uNS/3SmqkV3N2Qmab76xjOskHYGCXXMxsIXdmLxndR/ty6ZTEsc5pBoGQIDAQAB",
                productionKey = "MIIBCgKCAQEAqmGclAGXt2GchzMvBtIwPZFbox1h+RPvEcWsWYGNRyx8xBScw70U5xmad81ZRaN3GQ4qnLPyGM+SJBZ4wn7xi2uqrsdmhOwfUZcpbGsYuzaIZjVA75+KFAhGvsa0e+yYGY79BwB543NWV8reNI/JHD6Q7LHBcewOGZtaxaMDu0PGM9DbC6EAPAc3oXeAtQ4c1KXOPxmp2umPG9vXH3/c8ZQD79CkNsYYT59nn3DEBgJ5AjcvR3o72KB8hdsfVx+2nZtHnpruspj0brQyrwtY9GwcIDJhrhlBlP4a0x4sx4NTuSsAm0D2oqfXx14Cf78KZ6Ek2xE1FrUzE/hWXBDeQQIDAQAB",
                braintreeKey = (location == "pr.business" || location == "pr.business") ? productionKey : sandboxKey;

            return Braintree.create(braintreeKey);
        }

    })
    .constant("CheckoutConst", {
        billingCycles: {'0': 'One-time Update', '1': 'Monthly', '3': 'Quarterly', '12': 'Yearly'},
        billingFrequencyClass: {1: 'month', 3: 'quarter', 12: 'year'},
        states: [
            {countryCode: 'US', initials: 'AL', name: 'Alabama'},
            {countryCode: 'US', initials: 'AK', name: 'Alaska'},
            {countryCode: 'US', initials: 'AZ', name: 'Arizona'},
            {countryCode: 'US', initials: 'AR', name: 'Arkansas'},
            {countryCode: 'US', initials: 'CA', name: 'California'},
            {countryCode: 'US', initials: 'CO', name: 'Colorado'},
            {countryCode: 'US', initials: 'CT', name: 'Connecticut'},
            {countryCode: 'US', initials: 'DE', name: 'Delaware'},
            {countryCode: 'US', initials: 'DC', name: 'District of Columbia'},
            {countryCode: 'US', initials: 'FL', name: 'Florida'},
            {countryCode: 'US', initials: 'GA', name: 'Georgia'},
            {countryCode: 'US', initials: 'HI', name: 'Hawaii'},
            {countryCode: 'US', initials: 'ID', name: 'Idaho'},
            {countryCode: 'US', initials: 'IL', name: 'Illinois'},
            {countryCode: 'US', initials: 'IN', name: 'Indiana'},
            {countryCode: 'US', initials: 'IA', name: 'Iowa'},
            {countryCode: 'US', initials: 'KS', name: 'Kansas'},
            {countryCode: 'US', initials: 'KY', name: 'Kentucky'},
            {countryCode: 'US', initials: 'LA', name: 'Louisiana'},
            {countryCode: 'US', initials: 'ME', name: 'Maine'},
            {countryCode: 'US', initials: 'MD', name: 'Maryland'},
            {countryCode: 'US', initials: 'MA', name: 'Massachusetts'},
            {countryCode: 'US', initials: 'MI', name: 'Michigan'},
            {countryCode: 'US', initials: 'MN', name: 'Minnesota'},
            {countryCode: 'US', initials: 'MS', name: 'Mississippi'},
            {countryCode: 'US', initials: 'MO', name: 'Missouri'},
            {countryCode: 'US', initials: 'MT', name: 'Montana'},
            {countryCode: 'US', initials: 'NE', name: 'Nebraska'},
            {countryCode: 'US', initials: 'NV', name: 'Nevada'},
            {countryCode: 'US', initials: 'NH', name: 'New Hampshire'},
            {countryCode: 'US', initials: 'NJ', name: 'New Jersey'},
            {countryCode: 'US', initials: 'NM', name: 'New Mexico'},
            {countryCode: 'US', initials: 'NY', name: 'New York'},
            {countryCode: 'US', initials: 'NC', name: 'North Carolina'},
            {countryCode: 'US', initials: 'ND', name: 'North Dakota'},
            {countryCode: 'US', initials: 'OH', name: 'Ohio'},
            {countryCode: 'US', initials: 'OK', name: 'Oklahoma'},
            {countryCode: 'US', initials: 'OR', name: 'Oregon'},
            {countryCode: 'US', initials: 'PA', name: 'Pennsylvania'},
            {countryCode: 'US', initials: 'RI', name: 'Rhode Island'},
            {countryCode: 'US', initials: 'SC', name: 'South Carolina'},
            {countryCode: 'US', initials: 'SD', name: 'South Dakota'},
            {countryCode: 'US', initials: 'TN', name: 'Tennessee'},
            {countryCode: 'US', initials: 'TX', name: 'Texas'},
            {countryCode: 'US', initials: 'UT', name: 'Utah'},
            {countryCode: 'US', initials: 'VT', name: 'Vermont'},
            {countryCode: 'US', initials: 'VA', name: 'Virginia'},
            {countryCode: 'US', initials: 'WA', name: 'Washington'},
            {countryCode: 'US', initials: 'WV', name: 'West Virginia'},
            {countryCode: 'US', initials: 'WI', name: 'Wisconsin'},
            {countryCode: 'US', initials: 'WY', name: 'Wyoming'},

            {countryCode: 'CA', initials: 'AB', name: 'Alberta'},
            {countryCode: 'CA', initials: 'BC', name: 'British Columbia'},
            {countryCode: 'CA', initials: 'MB', name: 'Manitoba'},
            {countryCode: 'CA', initials: 'NB', name: 'New Brunswick'},
            {countryCode: 'CA', initials: 'NL', name: 'Newfoundland and Labrador'},
            {countryCode: 'CA', initials: 'NT', name: 'Northwest Territories'},
            {countryCode: 'CA', initials: 'NS', name: 'Nova Scotia'},
            {countryCode: 'CA', initials: 'NU', name: 'Nunavut'},
            {countryCode: 'CA', initials: 'ON', name: 'Ontario'},
            {countryCode: 'CA', initials: 'PE', name: 'Prince Edward Island'},
            {countryCode: 'CA', initials: 'QC', name: 'Quebec'},
            {countryCode: 'CA', initials: 'SK', name: 'Saskatchewan'},
            {countryCode: 'CA', initials: 'YT', name: 'Yukon'},

            {countryCode: 'AU', initials: 'NSW', name: 'New South Wales'},
            {countryCode: 'AU', initials: 'QLD', name: 'Queensland'},
            {countryCode: 'AU', initials: 'SA', name: 'South Australia'},
            {countryCode: 'AU', initials: 'TAS', name: 'Tasmania'},
            {countryCode: 'AU', initials: 'VIC', name: 'Victoria'},
            {countryCode: 'AU', initials: 'WA', name: 'Western Australia'},

            {countryCode: 'UK', initials: 'ABE', name: 'Aberdeen City'},
            {countryCode: 'UK', initials: 'ABD', name: 'Aberdeenshire'},
            {countryCode: 'UK', initials: 'ANS', name: 'Angus'},
            {countryCode: 'UK', initials: 'ANT', name: 'Antrim'},
            {countryCode: 'UK', initials: 'ARD', name: 'Ards'},
            {countryCode: 'UK', initials: 'AGB', name: 'Argyll and Bute'},
            {countryCode: 'UK', initials: 'ARM', name: 'Armagh'},
            {countryCode: 'UK', initials: 'BLA', name: 'Ballymena'},
            {countryCode: 'UK', initials: 'BLY', name: 'Ballymoney'},
            {countryCode: 'UK', initials: 'BNB', name: 'Banbridge'},
            {countryCode: 'UK', initials: 'BDG', name: 'Barking and Dagenham'},
            {countryCode: 'UK', initials: 'BNE', name: 'Barnet'},
            {countryCode: 'UK', initials: 'BNS', name: 'Barnsley'},
            {countryCode: 'UK', initials: 'BAS', name: 'Bath and North East Somerset'},
            {countryCode: 'UK', initials: 'BDF', name: 'Bedfordshire'},
            {countryCode: 'UK', initials: 'BFS', name: 'Belfast'},
            {countryCode: 'UK', initials: 'BEX', name: 'Bexley'},
            {countryCode: 'UK', initials: 'BIR', name: 'Birmingham'},
            {countryCode: 'UK', initials: 'BBD', name: 'Blackburn with Darwen'},
            {countryCode: 'UK', initials: 'BPL', name: 'Blackpool'},
            {countryCode: 'UK', initials: 'BGW', name: 'Blaenau Gwent'},
            {countryCode: 'UK', initials: 'BOL', name: 'Bolton'},
            {countryCode: 'UK', initials: 'BMH', name: 'Bournemouth'},
            {countryCode: 'UK', initials: 'BRC', name: 'Bracknell Forest'},
            {countryCode: 'UK', initials: 'BRD', name: 'Bradford'},
            {countryCode: 'UK', initials: 'BEN', name: 'Brent'},
            {countryCode: 'UK', initials: 'BGE', name: 'Bridgend (Pen-y-bont ar Ogwr GB-POG)'},
            {countryCode: 'UK', initials: 'BNH', name: 'Brighton and Hove'},
            {countryCode: 'UK', initials: 'BST', name: 'Bristol, City of'},
            {countryCode: 'UK', initials: 'BRY', name: 'Bromley'},
            {countryCode: 'UK', initials: 'BKM', name: 'Buckinghamshire'},
            {countryCode: 'UK', initials: 'BUR', name: 'Bury'},
            {countryCode: 'UK', initials: 'CAY', name: 'Caerphilly (Caerffili GB-CAF)'},
            {countryCode: 'UK', initials: 'CLD', name: 'Calderdale'},
            {countryCode: 'UK', initials: 'CAM', name: 'Cambridgeshire'},
            {countryCode: 'UK', initials: 'CMD', name: 'Camden'},
            {countryCode: 'UK', initials: 'CRF', name: 'Cardiff (Caerdydd GB-CRD)'},
            {countryCode: 'UK', initials: 'CMN', name: 'Carmarthenshire (Sir Gaerfyrddin GB-GFY)'},
            {countryCode: 'UK', initials: 'CKF', name: 'Carrickfergus'},
            {countryCode: 'UK', initials: 'CSR', name: 'Castlereagh'},
            {countryCode: 'UK', initials: 'CGN', name: 'Ceredigion (Sir Ceredigion)'},
            {countryCode: 'UK', initials: 'CHS', name: 'Cheshire'},
            {countryCode: 'UK', initials: 'CLK', name: 'Clackmannanshire'},
            {countryCode: 'UK', initials: 'CLR', name: 'Coleraine'},
            {countryCode: 'UK', initials: 'CWY', name: 'Conwy'},
            {countryCode: 'UK', initials: 'CKT', name: 'Cookstown'},
            {countryCode: 'UK', initials: 'CON', name: 'Cornwall'},
            {countryCode: 'UK', initials: 'COV', name: 'Coventry'},
            {countryCode: 'UK', initials: 'CGV', name: 'Craigavon'},
            {countryCode: 'UK', initials: 'CRY', name: 'Croydon'},
            {countryCode: 'UK', initials: 'CMA', name: 'Cumbria'},
            {countryCode: 'UK', initials: 'DAL', name: 'Darlington'},
            {countryCode: 'UK', initials: 'DEN', name: 'Denbighshire (Sir Ddinbych GB-DDB)'},
            {countryCode: 'UK', initials: 'DER', name: 'Derby'},
            {countryCode: 'UK', initials: 'DBY', name: 'Derbyshire'},
            {countryCode: 'UK', initials: 'DRY', name: 'Derry'},
            {countryCode: 'UK', initials: 'DEV', name: 'Devon'},
            {countryCode: 'UK', initials: 'DNC', name: 'Doncaster'},
            {countryCode: 'UK', initials: 'DOR', name: 'Dorset'},
            {countryCode: 'UK', initials: 'DOW', name: 'Down'},
            {countryCode: 'UK', initials: 'DUD', name: 'Dudley'},
            {countryCode: 'UK', initials: 'DGY', name: 'Dumfries and Galloway'},
            {countryCode: 'UK', initials: 'DND', name: 'Dundee City'},
            {countryCode: 'UK', initials: 'DGN', name: 'Dungannon'},
            {countryCode: 'UK', initials: 'DUR', name: 'Durham'},
            {countryCode: 'UK', initials: 'EAL', name: 'Ealing'},
            {countryCode: 'UK', initials: 'EAY', name: 'East Ayrshire'},
            {countryCode: 'UK', initials: 'EDU', name: 'East Dunbartonshire'},
            {countryCode: 'UK', initials: 'ELN', name: 'East Lothian'},
            {countryCode: 'UK', initials: 'ERW', name: 'East Renfrewshire'},
            {countryCode: 'UK', initials: 'ERY', name: 'East Riding of Yorkshire'},
            {countryCode: 'UK', initials: 'ESX', name: 'East Sussex'},
            {countryCode: 'UK', initials: 'EDH', name: 'Edinburgh, City of'},
            {countryCode: 'UK', initials: 'ELS', name: 'Eilean Siar'},
            {countryCode: 'UK', initials: 'ENF', name: 'Enfield'},
            {countryCode: 'UK', initials: 'ESS', name: 'Essex'},
            {countryCode: 'UK', initials: 'FAL', name: 'Falkirk'},
            {countryCode: 'UK', initials: 'FER', name: 'Fermanagh'},
            {countryCode: 'UK', initials: 'FIF', name: 'Fife'},
            {countryCode: 'UK', initials: 'FLN', name: 'Flintshire (Sir y Fflint GB-FFL)'},
            {countryCode: 'UK', initials: 'GAT', name: 'Gateshead'},
            {countryCode: 'UK', initials: 'GLG', name: 'Glasgow City'},
            {countryCode: 'UK', initials: 'GLS', name: 'Gloucestershire'},
            {countryCode: 'UK', initials: 'GRE', name: 'Greenwich'},
            {countryCode: 'UK', initials: 'GWN', name: 'Gwynedd'},
            {countryCode: 'UK', initials: 'HCK', name: 'Hackney'},
            {countryCode: 'UK', initials: 'HAL', name: 'Halton'},
            {countryCode: 'UK', initials: 'HMF', name: 'Hammersmith and Fulham'},
            {countryCode: 'UK', initials: 'HAM', name: 'Hampshire'},
            {countryCode: 'UK', initials: 'HRY', name: 'Haringey'},
            {countryCode: 'UK', initials: 'HRW', name: 'Harrow'},
            {countryCode: 'UK', initials: 'HPL', name: 'Hartlepool'},
            {countryCode: 'UK', initials: 'HAV', name: 'Havering'},
            {countryCode: 'UK', initials: 'HEF', name: 'Herefordshire, County of'},
            {countryCode: 'UK', initials: 'HRT', name: 'Hertfordshire'},
            {countryCode: 'UK', initials: 'HLD', name: 'Highland'},
            {countryCode: 'UK', initials: 'HIL', name: 'Hillingdon'},
            {countryCode: 'UK', initials: 'HNS', name: 'Hounslow'},
            {countryCode: 'UK', initials: 'IVC', name: 'Inverclyde'},
            {countryCode: 'UK', initials: 'AGY', name: 'Isle of Anglesey (Sir Ynys Mnnnn GB-YNM)'},
            {countryCode: 'UK', initials: 'IOW', name: 'Isle of Wight'},
            {countryCode: 'UK', initials: 'IOS', name: 'Isles of Scilly'},
            {countryCode: 'UK', initials: 'ISL', name: 'Islington'},
            {countryCode: 'UK', initials: 'KEC', name: 'Kensington and Chelsea'},
            {countryCode: 'UK', initials: 'KEN', name: 'Kent'},
            {countryCode: 'UK', initials: 'KHL', name: 'Kingston upon Hull, City of'},
            {countryCode: 'UK', initials: 'KTT', name: 'Kingston upon Thames'},
            {countryCode: 'UK', initials: 'KIR', name: 'Kirklees'},
            {countryCode: 'UK', initials: 'KWL', name: 'Knowsley'},
            {countryCode: 'UK', initials: 'LBH', name: 'Lambeth'},
            {countryCode: 'UK', initials: 'LAN', name: 'Lancashire'},
            {countryCode: 'UK', initials: 'LRN', name: 'Larne'},
            {countryCode: 'UK', initials: 'LDS', name: 'Leeds'},
            {countryCode: 'UK', initials: 'LCE', name: 'Leicester'},
            {countryCode: 'UK', initials: 'LEC', name: 'Leicestershire'},
            {countryCode: 'UK', initials: 'LEW', name: 'Lewisham'},
            {countryCode: 'UK', initials: 'LMV', name: 'Limavady'},
            {countryCode: 'UK', initials: 'LIN', name: 'Lincolnshire'},
            {countryCode: 'UK', initials: 'LSB', name: 'Lisburn'},
            {countryCode: 'UK', initials: 'LIV', name: 'Liverpool'},
            {countryCode: 'UK', initials: 'LND', name: 'London'},
            {countryCode: 'UK', initials: 'LUT', name: 'Luton'},
            {countryCode: 'UK', initials: 'MFT', name: 'Magherafelt'},
            {countryCode: 'UK', initials: 'MAN', name: 'Manchester'},
            {countryCode: 'UK', initials: 'MDW', name: 'Medway'},
            {countryCode: 'UK', initials: 'MTY', name: 'Merthyr Tydfil (Merthyr Tudful GB-MTU)'},
            {countryCode: 'UK', initials: 'MRT', name: 'Merton'},
            {countryCode: 'UK', initials: 'MDB', name: 'Middlesbrough'},
            {countryCode: 'UK', initials: 'MLN', name: 'Midlothian'},
            {countryCode: 'UK', initials: 'MIK', name: 'Milton Keynes'},
            {countryCode: 'UK', initials: 'MON', name: 'Monmouthshire (Sir Fynwy GB-FYN)'},
            {countryCode: 'UK', initials: 'MRY', name: 'Moray'},
            {countryCode: 'UK', initials: 'MYL', name: 'Moyle'},
            {countryCode: 'UK', initials: 'NTL', name: 'Neath Port Talbot (Castell-nedd Port Talbot GB-CTL)'},
            {countryCode: 'UK', initials: 'NET', name: 'Newcastle upon Tyne'},
            {countryCode: 'UK', initials: 'NWM', name: 'Newham'},
            {countryCode: 'UK', initials: 'NWP', name: 'Newport (Casnewydd GB-CNW)'},
            {countryCode: 'UK', initials: 'NYM', name: 'Newry and Mourne'},
            {countryCode: 'UK', initials: 'NTA', name: 'Newtownabbey'},
            {countryCode: 'UK', initials: 'NFK', name: 'Norfolk'},
            {countryCode: 'UK', initials: 'NAY', name: 'North Ayrshire'},
            {countryCode: 'UK', initials: 'NDN', name: 'North Down'},
            {countryCode: 'UK', initials: 'NEL', name: 'North East Lincolnshire'},
            {countryCode: 'UK', initials: 'NLK', name: 'North Lanarkshire'},
            {countryCode: 'UK', initials: 'NLN', name: 'North Lincolnshire'},
            {countryCode: 'UK', initials: 'NSM', name: 'North Somerset'},
            {countryCode: 'UK', initials: 'NTY', name: 'North Tyneside'},
            {countryCode: 'UK', initials: 'NYK', name: 'North Yorkshire'},
            {countryCode: 'UK', initials: 'NTH', name: 'Northamptonshire'},
            {countryCode: 'UK', initials: 'NBL', name: 'Northumberland'},
            {countryCode: 'UK', initials: 'NGM', name: 'Nottingham'},
            {countryCode: 'UK', initials: 'NTT', name: 'Nottinghamshire'},
            {countryCode: 'UK', initials: 'OLD', name: 'Oldham'},
            {countryCode: 'UK', initials: 'OMH', name: 'Omagh'},
            {countryCode: 'UK', initials: 'ORK', name: 'Orkney Islands'},
            {countryCode: 'UK', initials: 'OXF', name: 'Oxfordshire'},
            {countryCode: 'UK', initials: 'PEM', name: 'Pembrokeshire (Sir Benfro GB-BNF)'},
            {countryCode: 'UK', initials: 'PKN', name: 'Perth and Kinross'},
            {countryCode: 'UK', initials: 'PTE', name: 'Peterborough'},
            {countryCode: 'UK', initials: 'PLY', name: 'Plymouth'},
            {countryCode: 'UK', initials: 'POL', name: 'Poole'},
            {countryCode: 'UK', initials: 'POR', name: 'Portsmouth'},
            {countryCode: 'UK', initials: 'POW', name: 'Powys'},
            {countryCode: 'UK', initials: 'RDG', name: 'Reading'},
            {countryCode: 'UK', initials: 'RDB', name: 'Redbridge'},
            {countryCode: 'UK', initials: 'RCC', name: 'Redcar and Cleveland'},
            {countryCode: 'UK', initials: 'RFW', name: 'Renfrewshire'},
            {countryCode: 'UK', initials: 'RCT', name: 'Rhondda, Cynon, Taff (Rhondda, Cynon,Taf)'},
            {countryCode: 'UK', initials: 'RIC', name: 'Richmond upon Thames'},
            {countryCode: 'UK', initials: 'RCH', name: 'Rochdale'},
            {countryCode: 'UK', initials: 'ROT', name: 'Rotherham'},
            {countryCode: 'UK', initials: 'RUT', name: 'Rutland'},
            {countryCode: 'UK', initials: 'SLF', name: 'Salford'},
            {countryCode: 'UK', initials: 'SAW', name: 'Sandwell'},
            {countryCode: 'UK', initials: 'SCB', name: 'Scottish Borders, The'},
            {countryCode: 'UK', initials: 'SFT', name: 'Sefton'},
            {countryCode: 'UK', initials: 'SHF', name: 'Sheffield'},
            {countryCode: 'UK', initials: 'ZET', name: 'Shetland Islands'},
            {countryCode: 'UK', initials: 'SHR', name: 'Shropshire'},
            {countryCode: 'UK', initials: 'SLG', name: 'Slough'},
            {countryCode: 'UK', initials: 'SOL', name: 'Solihull'},
            {countryCode: 'UK', initials: 'SOM', name: 'Somerset'},
            {countryCode: 'UK', initials: 'SAY', name: 'South Ayrshire'},
            {countryCode: 'UK', initials: 'SGC', name: 'South Gloucestershire'},
            {countryCode: 'UK', initials: 'SLK', name: 'South Lanarkshire'},
            {countryCode: 'UK', initials: 'STY', name: 'South Tyneside'},
            {countryCode: 'UK', initials: 'STH', name: 'Southampton'},
            {countryCode: 'UK', initials: 'SOS', name: 'Southend-on-Sea'},
            {countryCode: 'UK', initials: 'SWK', name: 'Southwark'},
            {countryCode: 'UK', initials: 'SHN', name: 'St. Helens'},
            {countryCode: 'UK', initials: 'STS', name: 'Staffordshire'},
            {countryCode: 'UK', initials: 'STG', name: 'Stirling'},
            {countryCode: 'UK', initials: 'SKP', name: 'Stockport'},
            {countryCode: 'UK', initials: 'STT', name: 'Stockton-on-Tees'},
            {countryCode: 'UK', initials: 'STE', name: 'Stoke-on-Trent'},
            {countryCode: 'UK', initials: 'STB', name: 'Strabane'},
            {countryCode: 'UK', initials: 'SFK', name: 'Suffolk'},
            {countryCode: 'UK', initials: 'SND', name: 'Sunderland'},
            {countryCode: 'UK', initials: 'SRY', name: 'Surrey'},
            {countryCode: 'UK', initials: 'STN', name: 'Sutton'},
            {countryCode: 'UK', initials: 'SWA', name: 'Swansea (Abertawe GB-ATA)'},
            {countryCode: 'UK', initials: 'SWD', name: 'Swindon'},
            {countryCode: 'UK', initials: 'TAM', name: 'Tameside'},
            {countryCode: 'UK', initials: 'TFW', name: 'Telford and Wrekin'},
            {countryCode: 'UK', initials: 'THR', name: 'Thurrock'},
            {countryCode: 'UK', initials: 'TOB', name: 'Torbay'},
            {countryCode: 'UK', initials: 'TOF', name: 'Torfaen (Tor-faen)'},
            {countryCode: 'UK', initials: 'TWH', name: 'Tower Hamlets'},
            {countryCode: 'UK', initials: 'TRF', name: 'Trafford'},
            {countryCode: 'UK', initials: 'VGL', name: 'Vale of Glamorgan, The (Bro Morgannwg GB-BMG)'},
            {countryCode: 'UK', initials: 'WKF', name: 'Wakefield'},
            {countryCode: 'UK', initials: 'WLL', name: 'Walsall'},
            {countryCode: 'UK', initials: 'WFT', name: 'Waltham Forest'},
            {countryCode: 'UK', initials: 'WND', name: 'Wandsworth'},
            {countryCode: 'UK', initials: 'WRT', name: 'Warrington'},
            {countryCode: 'UK', initials: 'WAR', name: 'Warwickshire'},
            {countryCode: 'UK', initials: 'WBK', name: 'West Berkshire'},
            {countryCode: 'UK', initials: 'WDU', name: 'West Dunbartonshire'},
            {countryCode: 'UK', initials: 'WLN', name: 'West Lothian'},
            {countryCode: 'UK', initials: 'WSX', name: 'West Sussex'},
            {countryCode: 'UK', initials: 'WSM', name: 'Westminster'},
            {countryCode: 'UK', initials: 'WGN', name: 'Wigan'},
            {countryCode: 'UK', initials: 'WIL', name: 'Wiltshire'},
            {countryCode: 'UK', initials: 'WNM', name: 'Windsor and Maidenhead'},
            {countryCode: 'UK', initials: 'WRL', name: 'Wirral'},
            {countryCode: 'UK', initials: 'WOK', name: 'Wokingham'},
            {countryCode: 'UK', initials: 'WLV', name: 'Wolverhampton'},
            {countryCode: 'UK', initials: 'WOR', name: 'Worcestershire'},
            {countryCode: 'UK', initials: 'WRX', name: 'Wrexham (Wrecsam GB-WRC)'},
            {countryCode: 'UK', initials: 'YOR', name: 'York'},
        ],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        monthsValues: [
            {name: 'Jan', value: '01'},
            {name: 'Feb', value: '02'},
            {name: 'Mar', value: '03'},
            {name: 'Apr', value: '04'},
            {name: 'May', value: '05'},
            {name: 'Jun', value: '06'},
            {name: 'Jul', value: '07'},
            {name: 'Aug', value: '08'},
            {name: 'Sep', value: '09'},
            {name: 'Oct', value: '10'},
            {name: 'Nov', value: '11'},
            {name: 'Dec', value: '12'}
        ]
    });

