'use strict';


const _$http = new WeakMap(),
    _$sce = new WeakMap();


class SearchService {

    constructor($http, $sce) {
        _$http.set(this, $http);
        _$sce.set(this, $sce);
    }

    clickTracking(businessId) {
        return _$http.get(this).get(`/search/click_tracking?id=${businessId}`);
    }

}

export default SearchService;