'use strict';

class InlineErrorDrct {
    constructor() {
        this.templateUrl = '/public/templates/inline-error.tpl.html';
        this.restrict = 'E';
        this.scope = {
            errors: '='
        };
    }
}

export default InlineErrorDrct;