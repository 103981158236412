import _ from 'lodash';

class PreviewGallery {
    constructor($scope, $element, $interval) {
        this.template = `
            <img data-ng-if="media.galleryFilterType === 'photo'" class="photo-img" data-ng-src="{{media.imageUrl}}" alt="{{media.imageAlt}}"/>
            
            <div data-ng-if="media.galleryFilterType === 'video'" class="video-img">
                <img data-ng-src="{{media.previewYoutubeImage}}" alt="{{media.imageAlt}}" />
            </div>
        `;
        this.restrict = 'E';
        this.scope = {
            media: '='
        };
    }

    link(scope, element, attr) {

        scope.getPreviewYoutubeImage = (url)=>{
            let videoId = _.last(url.split('v=')),
                ampersandPosition = videoId.indexOf('&');

            if(ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
            return videoId ? `https://img.youtube.com/vi/${videoId}/mqdefault.jpg` : url;
        };



    }


}

export default PreviewGallery;


