import _ from 'lodash';

angular.module('renderGroupedReviewTableTextMessage', [])
    .directive('renderGroupedReviewTableTextMessage', ['$window', '$http', '$timeout', 'CommonUtils', function ($window, $http, $timeout, CommonUtils) {
        return {
            restrict: 'E',
            scope: {
                business: '=',
                reviewGroupData: '=',
                groupName: '@',
                isMobile: '=?',
                isReportLoaded: '=?',
                isFoodMenus: '=?',
            },
            template: `
                        <table class="yext-table yext-table-mobile table table-curved new-report-table" data-ng-if="isMobile">
                        <thead>
                                    <tr>
                                      <th>{{groupName}}</th>
                                      <th>Status</th>
                                      <th>Texting</th>
                                    </tr>
                        </thead>
                        <tbody>
                        
                        <tr data-ng-repeat-start="scanResult in reviewGroupData" data-ng-click="scanResult.report.show = !scanResult.report.show" class="dropdown-field">
                            <td>
                                <div class="left-row-part">
                                    <span>
                                        <img data-ng-src="/public/images/business_profile_page/yext/directories/{{siteIcon(scanResult)}}" alt="{{scanResult.siteId}}">
                                    </span>
                                    <span>{{scanResult.siteId}}</span>
                                </div>
                                <div>
                                    <a class="viewlink mobile-view-link" data-ng-if="scanResult.report && scanResult.report.url" target="_blank" data-ng-href="{{scanResult.report.url}}"><i class="icon ion-ios-eye"></i>view listing</a>
                                </div>
                            </td>
                            <td>
                                <span class="mark-mobile">
                                    <img data-ng-src="/public/images/business_profile_page/yext/status/{{statusImage(scanResult)}}.svg" alt="{{statusImage(scanResult)}}" data-ng-class="{'alert-type': statusImage(scanResult) === 'alert'}"/>
                                </span>
                            </td>
                            <td>
                                <span class="mark-mobile">
                                    <img data-ng-src="/public/images/business_profile_page/yext/status/{{textingStatusImage(scanResult)}}.svg" alt="{{textingStatusImage(scanResult)}}"/>
                                </span>
                            </td>
                        </tr>
                        
                        <tr class="blue-dropdown" data-ng-show="scanResult.report.show" data-ng-repeat-end>
                            <td colspan="3">
                                <ul data-ng-if="!isReportLoaded" class="not-submitted-class">
                                    <li class="one-line">
                                        <img src="/public/images/business_profile_page/yext/hourglass.png" alt="hourglass.svg">
                                        <span class="text grey-text">Listing is loading...</span>
                                    </li>
                                </ul>

                                <ul data-ng-if="isReportLoaded && isShowNAP(scanResult) && !isSubmittedType(scanResult)" class="left">
                                        <li>
                                            <span class="blue-circle">N</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.name}}
                                        </span>
                                        </li>
                                        <li>
                                            <span class="blue-circle">A</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.address}},
                                            {{scanResult.report.city}}, {{scanResult.report.state}} {{scanResult.report.zip}}
                                        </span>
                                        </li>
                                        <li>
                                            <span class="blue-circle">P</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.phone}}
                                        </span>
                                        </li>
                                </ul>
            
                                <ul data-ng-if="isReportLoaded && !isShowNAP(scanResult) && !isSubmittedType(scanResult)" class="not-submitted-class">
                                      <li class="one-line">
                                          <img src="/public/images/business_profile_page/yext/status/x.svg" alt="x.svg">
                                          <span class="text red-text">No Listing Found!</span>
                                      </li>
                                </ul>
                                    
                                <ul data-ng-if="isReportLoaded && isSubmittedType(scanResult) && !isNoErrorsField(scanResult)" class="not-submitted-class">
                                      <li class="one-line">
                                         <img src="/public/images/business_profile_page/yext/status/x.svg" alt="x.svg">
                                         <span class="text red-text">Not Submitted!</span>
                                      </li>
                                </ul>
            
                                <ul data-ng-if="isReportLoaded && isSubmittedType(scanResult) && isNoErrorsField(scanResult)" class="submitted-class">
                                      <li class="one-line">
                                      <img src="/public/images/business_profile_page/yext/check.svg" alt="check.svg">
                                         <span class="text green-submitted-text">Submitted</span>
                                      </li>
                                </ul>


                                <ul data-ng-if="isTextingEnabled(scanResult)" class="submitted-class">
                                      <li class="one-line">
                                        <img src="/public/images/business_profile_page/yext/check.svg" alt="check.svg">
                                         <span class="text green-submitted-text">Texting Enabled</span>
                                      </li>
                                </ul>
            
                                <ul data-ng-if="!isTextingEnabled(scanResult)" class="not-submitted-class">
                                      <li class="one-line">
                                         <img src="/public/images/business_profile_page/yext/status/x.svg" alt="x.svg">
                                         <span class="text red-text">Texting Not Enabled</span>
                                      </li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                        </table>
            
            
                        <table class="yext-table table table-curved new-report-table" data-ng-if="!isMobile">
                        <thead>
                                    <tr>
                                      <th>{{groupName}}</th>
                                      <th>Result</th>
                                      <th>Status</th>
                                      <th>Texting</th>
                                    </tr>
                        </thead>
                        <tbody>
                        <tr data-ng-repeat="scanResult in reviewGroupData">
                            
                            <td>
                                <div class="first-column">
                                    <img data-ng-src="/public/images/business_profile_page/yext/directories/{{siteIcon(scanResult)}}" alt="{{scanResult.siteId}}">
                                    <span>
                                        <div>{{scanResult.siteId}}</div>
                                        <a data-ng-if="scanResult.report && scanResult.report.url" data-ng-href="{{scanResult.report.url}}"
                                           target="_blank"><i class="icon ion-ios-eye"></i>View Listing</a>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div class="middle-column">
                                    <ul data-ng-if="!isReportLoaded" class="not-submitted-class">
                                        <li class="one-line">
                                            <img src="/public/images/business_profile_page/yext/hourglass.png" alt="hourglass.svg">
                                            <span class="text grey-text">Listing is loading...</span>
                                        </li>
                                    </ul>

                                    <ul data-ng-if="isReportLoaded && isShowNAP(scanResult) && !isSubmittedType(scanResult)" class="left">
                                        <li>
                                            <span class="blue-circle">N</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.name}}
                                        </span>
                                        </li>
                                        <li>
                                            <span class="blue-circle">A</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.address}},
                                            {{scanResult.report.city}}, {{scanResult.report.state}} {{scanResult.report.zip}}
                                        </span>
                                        </li>
                                        <li>
                                            <span class="blue-circle">P</span>
                                            <span class="text" data-ng-style="{color:'{{styleColor(scanResult)}}' }">{{scanResult.report.phone}}
                                        </span>
                                        </li>
                                    </ul>
            
                                    <ul data-ng-if="isReportLoaded && !isShowNAP(scanResult) && !isSubmittedType(scanResult)" class="not-submitted-class">
                                        <li class="one-line">
                                            <img src="/public/images/business_profile_page/yext/status/x.svg" alt="x.svg">
                                            <span class="text red-text">No Listing Found!</span>
                                        </li>
                                    </ul>
                                    
                                    <ul data-ng-if="isReportLoaded && isSubmittedType(scanResult) && !isNoErrorsField(scanResult)" class="not-submitted-class">
                                        <li class="one-line">
                                            <img src="/public/images/business_profile_page/yext/status/x.svg" alt="x.svg">
                                            <span class="text red-text">Not Submitted!</span>
                                        </li>
                                    </ul>
            
                                    <ul data-ng-if="isReportLoaded && isSubmittedType(scanResult) && isNoErrorsField(scanResult)" class="submitted-class">
                                        <li class="one-line">
                                            <img src="/public/images/business_profile_page/yext/check.svg" alt="check.svg">
                                            <span class="text green-submitted-text">Submitted</span>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            
                            <td class="image">
                                <img data-ng-src="/public/images/business_profile_page/yext/status/{{statusImage(scanResult)}}.svg" alt="{{statusImage(scanResult)}}" data-ng-class="{'alert-type': statusImage(scanResult) === 'alert'}"/>
                                <div class='black status-legend'>{{statusMessage(scanResult)}}</div>
                            </td>
                            <td class="image">
                                <img data-ng-src="/public/images/business_profile_page/yext/status/{{textingStatusImage(scanResult)}}.svg" alt="{{textingStatusImage(scanResult)}}"/>
                                <div class='black status-legend'>{{textingStatusMessage(scanResult)}}</div>
                            </td>
                            
                        </tr>            
                        </tbody>
                    </table>`,
            controller: function ($scope) {
                let membership = $scope.business.Membership || {},
                    features = _.map(membership.BusinessFeatures, 'name');

                const isUpgraded = _.includes(features, 'upgraded');
                const business = $scope.business || {};

                const isShowAlexa = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowAlexa', false)/* || _.includes(features, 'alexa') || _.includes(features, 'yext')*/;
                const isShowSiri = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowSiri', false);
                const isShowCortana = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowCortana', false);
                const isShowGoogleAssistant = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowGoogleAssistant', false);
                const isShowBixby = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowBixby', false);
                const isShowCarPlay = _.get(business, 'BusinessSettings.standardTemplateSettings.isShowCarPlay', false);
                const isShowAppleMaps = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowAppleMaps', false);
                const isShowGoogleMaps = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowGoogleMaps', false);
                const isShowBingMaps = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowBingMaps', false);
                const isShowWaze = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowWaze', false);
                const isShowHERE = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowHERE', false);
                const isShowWhereTo = _.get(business, 'BusinessSettings.iMappingTemplateSettings.isShowWhereTo', false);

                let listingsAsSubmitted = [
                    'Amazon Alexa',
                    'Apple Siri',
                    'Microsoft Cortana',
                    'Google Assistant',
                    'Samsung Bixby',
                    'Postmales',
                    'Yandex',
                    'Zomato',
                    'Apple Maps',
                    'Bing Maps',
                    'HERE',
                    'Telenav',
                    'TomTom',
                    'CarPlay',
                    'D & B Database',
                    'Factual',
                    'Pitney Bowes',
                    'Soleo',
                ];

                const hardcodedSites = _.filter($scope.reviewGroupData, { report: { isFullDirectoryData: true } });
                const hardcodedSiteNames = _.map(hardcodedSites, 'siteId');

                listingsAsSubmitted = listingsAsSubmitted.concat(hardcodedSiteNames);

                !isShowGoogleMaps && listingsAsSubmitted.push('Google Maps');
                !isShowWaze && listingsAsSubmitted.push('Waze');
                !isShowWhereTo && listingsAsSubmitted.push('WhereTo');


                $scope.isShowNAP = function(scanResult) {
                    let report = scanResult.report,

                        address = report.match_address,
                        name = report.match_name,
                        phone = report.match_phone,
                        isName = _.isEqual((_.get(report, 'name') || '').toLowerCase(), (_.get(business, 'name') || '').toLowerCase()),
                        isCity = _.isEqual((_.get(report, 'city') || '').toLowerCase(), (_.get(business, 'city') || '').toLowerCase()),
                        isState = _.isEqual((_.get(report, 'state') || '').toLowerCase(), (_.get(business, 'state') || '').toLowerCase()),
                        isZip = _.isEqual(_.get(report, 'zip'), _.get(business, 'zipcode')),
                        ifAllDataExist = address && phone && name || isName && isState && isCity && isZip,

                        isPublicReputation = _.isEqual(scanResult.siteId, 'pr.business'),
                        isGoogle = _.isEqual(scanResult.siteId, 'GoogleMyBusiness');

                    // return isPublicReputation || isGoogle || report.status === 'complete' || ifAllDataExist;
                    return isPublicReputation || report.status === 'complete' || ifAllDataExist;
                }

                $scope.isSubmittedType = function(scanResult) {
                    return _.includes(listingsAsSubmitted, scanResult.siteId);
                }

                $scope.isNoErrorsField = function(scanResult) {
                    //with toggling between “Submitted” with a green checkmark and “Not Submitted” with a red X:

                    if (_.isEqual(scanResult.siteId, 'Amazon Alexa')) {
                        return isShowAlexa;
                    }
                    if (_.isEqual(scanResult.siteId, 'Apple Siri')) {
                        return isShowSiri;
                    }
                    if (_.isEqual(scanResult.siteId, 'Microsoft Cortana')) {
                        return isShowCortana;
                    }
                    if (_.isEqual(scanResult.siteId, 'Google Assistant')) {
                        return isShowGoogleAssistant;
                    }
                    if (_.isEqual(scanResult.siteId, 'Samsung Bixby')) {
                        return isShowBixby;
                    }
                    if (_.isEqual(scanResult.siteId, 'CarPlay')) {
                        return isShowCarPlay;
                    }
                    if (_.isEqual(scanResult.siteId, 'Apple Maps')) {
                        return isShowAppleMaps;
                    }
                    if (_.isEqual(scanResult.siteId, 'Google Maps')) {
                        return isShowGoogleMaps;
                    }
                    if (_.isEqual(scanResult.siteId, 'Bing Maps')) {
                        return isShowBingMaps;
                    }
                    if (_.isEqual(scanResult.siteId, 'Waze')) {
                        return isShowWaze;
                    }
                    if (_.isEqual(scanResult.siteId, 'HERE')) {
                        return isShowHERE;
                    }
                    if (_.isEqual(scanResult.siteId, 'WhereTo')) {
                        return isShowWhereTo;
                    }

                    return scanResult.report.status === 'complete';

                    //old type:
//                    return _.isEmpty(scanResult.report.errors);
                }

                $scope.siteIcon = function(scanResult) {
                    const extension = scanResult.siteId === 'Apple Siri' ? '.png' : '.svg';
                    return `${scanResult.siteId.replace(/\s/g, '')}${extension}`;
                }

                $scope.styleColor = function(scanResult) {
                    let color = 'red',
                        isSubmittedType = _.includes(listingsAsSubmitted, scanResult.siteId),

                        report = scanResult.report,
                        address = report.match_address,
                        name = report.match_name,
                        phone = report.match_phone,
                        isName = _.isEqual((_.get(report, 'name') || '').toLowerCase(), (_.get(business, 'name') || '').toLowerCase()),
                        isCity = _.isEqual((_.get(report, 'city') || '').toLowerCase(), (_.get(business, 'city') || '').toLowerCase()),
                        isState = _.isEqual((_.get(report, 'state') || '').toLowerCase(), (_.get(business, 'state') || '').toLowerCase()),
                        isZip = _.isEqual(_.get(report, 'zip'), _.get(business, 'zipcode')),

                        ifAllDataExist = address && phone && name || isName && isState && isCity && isZip,
                        isNoErrorsField = report.status === 'complete'; // _.isEmpty(report.errors)


                    if (!address && !phone && !name || !isName && !isState && !isCity && !isZip) {} else if (ifAllDataExist && report.status !== 'in-progress') {
                        color = 'black';
                    }

                    //for submitted type:
                    if(isSubmittedType) {
                        if (isNoErrorsField) {} else {color = 'black'}
                    }

                    return color;
                }

                $scope.statusImage = function(scanResult) {
                    if (!$scope.isReportLoaded) {
                        return 'loading';
                    }

                    let statusImage = 'alert',
                        isSubmittedType = _.includes(listingsAsSubmitted, scanResult.siteId),

                        report = scanResult.report,
                        address = report.match_address,
                        name = report.match_name,
                        phone = report.match_phone,
                        isName = _.isEqual((_.get(report, 'name') || '').toLowerCase(), (_.get(business, 'name') || '').toLowerCase()),
                        isCity = _.isEqual((_.get(report, 'city') || '').toLowerCase(), (_.get(business, 'city') || '').toLowerCase()),
                        isState = _.isEqual((_.get(report, 'state') || '').toLowerCase(), (_.get(business, 'state') || '').toLowerCase()),
                        isZip = _.isEqual(_.get(report, 'zip'), _.get(business, 'zipcode')),
                        isPublicReputation = _.isEqual(scanResult.siteId, "pr.business"),
                        isGoogle = _.isEqual(scanResult.siteId, 'GoogleMyBusiness'),
//                      isSynced = isPublicReputation || isGoogle && report.has_powerlistings || !isGoogle && (isUpgraded) && name && address && phone || !isGoogle && (isName && isState && isCity && isZip),
//                      inProgress = !isPublicReputation && (!report.has_powerlistings || !isGoogle && report.has_powerlistings) && isUpgraded && !(name && address && phone) && !isSynced,
                        isSynced = isPublicReputation,
                        inProgress = !isPublicReputation && report.status === 'in-progress',

                        ifAllDataExist = address && phone && name || isName && isState && isCity && isZip,
                        isNoErrorsField = report.status === 'complete'; // _.isEmpty(report.errors)


                    if (!address && !phone && !name || !isName && !isState && !isCity && !isZip) {
                        //set status fields:
                        statusImage = 'error';

                    } else if (isSynced || ifAllDataExist) {
                        statusImage = 'check';
                    }

                    if (inProgress && ifAllDataExist) {
                        statusImage = 'proc';
                    }


                    //for submitted type:
                    if(isSubmittedType) {
                        //with toggling between “Submitted” with a green checkmark and “Not Submitted” with a red X:
                        if (_.isEqual(scanResult.siteId, 'Amazon Alexa')) {
                            statusImage = isShowAlexa ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Apple Siri')) {
                            statusImage = isShowSiri ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Microsoft Cortana')) {
                            statusImage = isShowCortana ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Google Assistant')) {
                            statusImage = isShowGoogleAssistant ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Samsung Bixby')) {
                            statusImage = isShowBixby ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'CarPlay')) {
                            statusImage = isShowCarPlay ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Apple Maps')) {
                            statusImage = isShowAppleMaps ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Google Maps')) {
                            statusImage = isShowGoogleMaps ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Bing Maps')) {
                            statusImage = isShowBingMaps ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'Waze')) {
                            statusImage = isShowWaze ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'HERE')) {
                            statusImage = isShowHERE ? 'check' : 'error';
                        } else if (_.isEqual(scanResult.siteId, 'WhereTo')) {
                            statusImage = isShowWhereTo ? 'check' : 'error';
                        } else {
                            statusImage = isNoErrorsField ? 'check' : 'error';
                        }

//                        //old type:
//                        if (isNoErrorsField) {
//                            statusImage = 'check';
//                        } else {
//                            statusImage = 'error';
//                        }
                    }

                    if (isPublicReputation && !_.isEmpty(business)) {
                        statusImage = 'check';
                    }

                    return statusImage;
                }

                $scope.statusMessage = function(scanResult) {
                    if (!$scope.isReportLoaded) {
                        return 'Scanning';
                    }

                    let statusMessage = 'Action Required',
                        isSubmittedType = _.includes(listingsAsSubmitted, scanResult.siteId),

                        report = scanResult.report,
                        address = report.match_address,
                        name = report.match_name,
                        phone = report.match_phone,
                        isName = _.isEqual((_.get(report, 'name') || '').toLowerCase(), (_.get(business, 'name') || '').toLowerCase()),
                        isCity = _.isEqual((_.get(report, 'city') || '').toLowerCase(), (_.get(business, 'city') || '').toLowerCase()),
                        isState = _.isEqual((_.get(report, 'state') || '').toLowerCase(), (_.get(business, 'state') || '').toLowerCase()),
                        isZip = _.isEqual(_.get(report, 'zip'), _.get(business, 'zipcode')),
                        isPublicReputation = _.isEqual(scanResult.siteId, "pr.business"),
                        isGoogle = _.isEqual(scanResult.siteId, 'GoogleMyBusiness'),
//                      isSynced = isPublicReputation || isGoogle && report.has_powerlistings || !isGoogle && (isUpgraded) && name && address && phone || !isGoogle && (isName && isState && isCity && isZip),
//                      inProgress = !isPublicReputation && (!report.has_powerlistings || !isGoogle && report.has_powerlistings) && isUpgraded && !(name && address && phone) && !isSynced,
                        isSynced = isPublicReputation,
                        inProgress = !isPublicReputation && report.status === 'in-progress',

                        ifAllDataExist = address && phone && name || isName && isState && isCity && isZip,
                        isNoErrorsField = report.status === 'complete'; // _.isEmpty(report.errors)


                    if (!address && !phone && !name || !isName && !isState && !isCity && !isZip) {
                        statusMessage = 'Unsynced';

                    } else if (isSynced || ifAllDataExist) {
                        statusMessage = 'Synced';
                    }

                    if (inProgress && ifAllDataExist) {
                        statusMessage = 'Processing';
                    }


                    //for submitted type:
                    if(isSubmittedType) {
                        //with toggling between “Submitted” with a green checkmark and “Not Submitted” with a red X:
                        if (_.isEqual(scanResult.siteId, 'Amazon Alexa')) {
                            statusMessage = isShowAlexa ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Apple Siri')) {
                            statusMessage = isShowSiri ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Microsoft Cortana')) {
                            statusMessage = isShowCortana ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Google Assistant')) {
                            statusMessage = isShowGoogleAssistant ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Samsung Bixby')) {
                            statusMessage = isShowBixby ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'CarPlay')) {
                            statusMessage = isShowCarPlay ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Apple Maps')) {
                            statusMessage = isShowAppleMaps ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Google Maps')) {
                            statusMessage = isShowGoogleMaps ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Bing Maps')) {
                            statusMessage = isShowBingMaps ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'Waze')) {
                            statusMessage = isShowWaze ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'HERE')) {
                            statusMessage = isShowHERE ? 'Submitted' : 'Not Submitted';
                        } else if (_.isEqual(scanResult.siteId, 'WhereTo')) {
                            statusMessage = isShowWhereTo ? 'Submitted' : 'Not Submitted';
                        } else {
                            statusMessage = isNoErrorsField ? 'Submitted' : 'Not Submitted';
                        }

                        //old type:
//                        if (isNoErrorsField) {
//                            statusMessage = 'Submitted';
//                        } else {
//                            statusMessage = 'Not Submitted';
//                        }
                    }

                    if (isPublicReputation && !_.isEmpty(business)) {
                        statusMessage = 'Synced';
                    }

                    return statusMessage;
                }

                $scope.isTextingEnabled = function(scanResult) {
                    return _.get(scanResult, 'textMessageEnabled', false);
                }

                $scope.textingStatusImage = function(scanResult) {
                    return _.get(scanResult, 'textMessageEnabled', false) ? 'check' : 'error';
                }

                $scope.textingStatusMessage = function(scanResult) {
                    return _.get(scanResult, 'textMessageEnabled', false) ? 'Enabled' : 'Not Enabled';
                }
            }
        };
    }]);
