'use strict';

import {ClusterIcon, Cluster, MarkerClusterer} from '../utils/markerclusterer';

class BusinessMap {
    constructor() {
        this.templateUrl = '/public/templates/businessMap.tpl.html';
        this.restrict = 'E';
        this.scope = {
            locations: '=',
            lat: '=',
            lon: '=',
            score: '=',
            name: '='
        };
    }

    link(scope, elem, attrs) {
        var $this = BusinessMap.instance;
        BusinessMap.instance.initialize(scope);

        //watch location
        scope.$watch(() => {
            return (scope.lat + scope.lon) || scope.name;
        }, () => {
            $this.initialize(scope);
        });
    }

    initialize(scope) {
        var $this = BusinessMap.instance;
        $this.domMapElement = document.getElementById('map');
        if (!$this.domMapElement) {
            return;
        }
        $this.infoWindows = [];//popups
        $this.initGoogleMap();

        let coloredMarkers = false,
            locations = [].concat(scope.locations);
        if (scope.lat && scope.lon) {
            coloredMarkers = true;
            var mainMarker = {
                latitude: scope.lat,
                longitude: scope.lon,
                prScore: scope.score,
                name: scope.name,
                big: true
            };
            locations.push(mainMarker);
        }

        $this.domMapElement.style.height = coloredMarkers ? '155px' : '100%';
        $this.initMarkers(locations, coloredMarkers);

    }

    initMarkers(locations, coloredMarkers) {
        let latLngBounds = new google.maps.LatLngBounds(),
            markers = [],
            markersClustered = [],
            count = 0;

        for (let item of locations) {
            if (item.latitude && item.longitude) {
                let latLng = new google.maps.LatLng(item.latitude, item.longitude);
                count++;
                let pinIcon = new google.maps.MarkerImage(BusinessMap.resolveImageName(item.prScore, item.big, coloredMarkers));

                let title = `${item.name}`;
                let href  = item.Slug ? `/${item.Slug.slug}`: null;

                let marker = new google.maps.Marker({
                    position: latLng,
                    icon: pinIcon,
                    map: BusinessMap.instance.map,
                    title: `${item.name}`,
                    label: !coloredMarkers ? count.toString() : ''
                });

                let infoWindow = BusinessMap.instance.infoMarker(marker, title, href);
                markers.push(latLng);
                markersClustered.push(marker);
                BusinessMap.instance.infoWindows.push(infoWindow);
            }
        }

        markers.forEach(n => {
            latLngBounds.extend(n);
        });
        BusinessMap.instance.map.setCenter(latLngBounds.getCenter());
        BusinessMap.instance.map.fitBounds(latLngBounds);

        BusinessMap.instance.markerClusterer.addMarkers(markersClustered, true);
    }

    initGoogleMap() {
        let mapOptionsDefaults = {
            zoom: 10,
            panControl: false,
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.RIGHT_BOT
            },
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOT
            }
        };
        BusinessMap.instance.map = new google.maps.Map(BusinessMap.instance.domMapElement, mapOptionsDefaults);

        BusinessMap.instance.map.addListener('click', ()=> {
            if (!!BusinessMap.instance.infoWindows && BusinessMap.instance.infoWindows.length > 0) {
                BusinessMap.instance.infoWindows.forEach(popup => popup.close());
            }
        });


        let mcOptions = {gridSize: 50, maxZoom: 15};
        BusinessMap.instance.markerClusterer = new MarkerClusterer(BusinessMap.instance.map, [], mcOptions);

    }

    static resolveSuffix(prScore) {
        let index = 0;
        const imagesSuffixes = ['60', '61-65', '66-71', '72-77', '78-83', '84-89', '90-100'];

        if (prScore <= 60) index = 0;
        if (prScore >= 61 && prScore <= 65) index = 1;
        if (prScore >= 66 && prScore <= 71) index = 2;
        if (prScore >= 72 && prScore <= 77) index = 3;
        if (prScore >= 78 && prScore <= 83) index = 4;
        if (prScore >= 84 && prScore <= 89) index = 5;
        if (prScore >= 90 && prScore <= 100) index = 6;

        return imagesSuffixes[index];
    }

    static resolveImageName(prScore, bigImage, colored) {
        var prefix = bigImage === true ? 'big' : 'small';
        if (colored) {
            return '/public/images/pins/' + prefix + '_pin_' + BusinessMap.resolveSuffix(prScore) + '.png';
        } else {
            return '/public/images/pins/default.png';
        }
    }

    infoMarker(marker, title, href) {
        let contentString = '<a href="' + (href || '#') + '" class="map-business_title">'+ title +'</a>';

        let infoWindow = new google.maps.InfoWindow({
            content: contentString});

        marker.addListener('click', () => {
            if (!!BusinessMap.instance.infoWindows && BusinessMap.instance.infoWindows.length > 0) {
                BusinessMap.instance.infoWindows.forEach(popup => popup.close());
            }
            infoWindow.open(BusinessMap.instance.map, marker);
        });
        return infoWindow;
    }

    static directiveFactoryBusinessMap(){
        BusinessMap.instance = new BusinessMap();
        return BusinessMap.instance;
    }
}

export default BusinessMap;

