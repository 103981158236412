'use strict';
import _ from 'lodash'

/**
 * Send email from SEO landing form and do redirect to verify/upgrade flow
 */
function redirectListingHandler($http, business, params) {
    var doRedirect = function (business) {
        if(window.location.pathname.toLowerCase().indexOf("/reviews-and-reputation")>= 0){
            window.location="/"+business.Slug.slug;
        }
        else  if(window.location.pathname.toLowerCase().indexOf("/advertise-my-business")>= 0){

            window.location="/landing/thank-you?slug="+business.Slug.slug;
        }
        else {
          window.location.href = '/business/upgrade_weblead/' + business.id + '#!#1';
        }


    },
    formSubmitHandler = doRedirect.bind(this, business);
    $http.post('/home/seo_promotion_form_submit', params)
        .success(formSubmitHandler)
        .error(formSubmitHandler);
}




var _self, loading, _q, _http, uibModal, timeout;

class BusinessSearchLandingCtrl {
    constructor(CommonUtils, blockUI, $interval, $timeout, $window, $scope, $http, $q, $uibModal) {
        loading = blockUI.instances.get('searchlanding');
        _q = $q;
        uibModal = $uibModal;
        _self = $scope;
        _self.errors = {};
        _http = $http;
        this.$http = $http;
        this.$window = $window;
        timeout = $timeout;

        this.isMobile = CommonUtils.isMobile.bind(CommonUtils);
        this.isTablet = CommonUtils.isTablet.bind(CommonUtils);

        this.article = 0;
        this.itemquantity = 4;
        this.businessName = '';
        this.businessAddress = '';
        this.city = '';
        this.phone = '';
        this.zipcode = '';
        this.userData = {
            firstName: '',
            lastName: '',
            email: ''
        };
        _self.userData = this.userData;
        this.checkingBusiness = 0;

        $interval(() => {this.article < this.itemquantity ? this.article++ : this.article = 0;}, 10000);
    }

    searchListings(force) {
        loading.start();
        timeout(() => {loading.message('Scanning...')}, 1000);
        timeout(() => {loading.message('Still scanning...')}, 10000);
        timeout(() => {loading.message('Almost there...')}, 25000);
        let params = {};

        if (!_.isUndefined(this.businessName)) {params.name = this.businessName;}
        if (!_.isUndefined(this.businessAddress)) {params.address = this.businessAddress;}
        if (!_.isUndefined(this.city)) {params.city = this.city;}
        if (!_.isUndefined(this.phone)) {params.telephone = this.phone;}
        if (!_.isUndefined(this.zipcode)) {params.zipcode = this.zipcode;}
        if (!_.isUndefined(this.userData.firstName)) {params.firstName = this.userData.firstName;}
        if (!_.isUndefined(this.userData.lastName)) {params.lastName = this.userData.lastName;}
        if (!_.isUndefined(this.userData.email)) {params.email = this.userData.email;}
        if (!_.isUndefined(this.keyword)) {params.keyword = this.keyword;}
        if (force) {params.force = true;}

        params.leadSource = this.$window.location.pathname;
        params.GCLID = $('#zc_gad').val();

        validate(params).then((valid)=>{
            var $this = this;
            if (!valid) {
                loading.stop();
            } else {
                //set data to session
                sessionStorage.setItem('firstName', _self.userData.firstName);
                sessionStorage.setItem('lastName', _self.userData.lastName);
                sessionStorage.setItem('email', _self.userData.email);
                sessionStorage.setItem('business', JSON.stringify(params));
                sessionStorage.setItem('back_url', window.location.pathname);

                _http.get('/search/listings', {params: params})
                    .success(response => {
                        //we're removing previously configured handler (is any)- since user has submitted form successfully
                        this.$window.onbeforeunload = undefined;

                        // if a new business was added, response will be an object rather than an array
                        var newBusiness = false;
                        if (!_.isArray(response) && _.has(response, 'newBusiness')) {
                            response = response.businesses;
                            newBusiness = true;
                        }

                        if (response.length > 1) {
                            //show modal window with scan results
                            uibModal.open({
                                templateUrl: '/public/templates/search/scan_results.tpl.html',
                                controller: 'BusinessSearchLandingModalCtrl',
                                size: 'lg',
                                windowClass: 'search-scan-results',
                                keyboard: true,
                                resolve: {
                                    data: function () {
                                        return {
                                            businesses: response,
                                            user: _self.userData,
                                            params: params,
                                            $http: $this.$http
                                        };
                                    }
                                }
                            });
                        } else if (response.length === 1) {
                            var business = _.first(response);

                            if (newBusiness) {
                                // ll('tagEvent', 'Add a Business', {'Business ID': business.id, 'Location': params.zipcode});
//                                var _hsq = window._hsq = window._hsq || [];
//                                _hsq.push(['trackEvent', {
//                                    id: '000005943354',
//                                    value: {'Business ID': business.id, 'Location': params.zipcode}
//                                }]);
                            }

                            params.BPP_URL = business && business.Slug && business.Slug.slug;
                            params.businessId = business && business.id;
                            
			    let formName = "";
                            if(window.location.pathname.toLowerCase().indexOf("/reviews-and-reputation")>= 0){
                                formName = "Reviews and Reputation";
                            }
                            else if(window.location.pathname.toLowerCase().indexOf("/advertise-my-business")>= 0){
                                formName = "Advertise My Business";
                            }
                            else if(window.location.pathname.toLowerCase().indexOf("/directory-and-listings")>= 0){
                                formName = "Directory and Listings";
                            }
                            else {
                              formName = "Seo Promotion";
                            }
                            this.$window.dataLayer.push({'event' : 'lp_form_submit','formName' : formName});
                            redirectListingHandler(_http, business, params);
                        }
                        //always must be at least one result

                        loading.stop();
                    })
                    .error(function (errors) {
                        if (errors.details && errors.details.restricted) {
                            _self.errors = errors.details;
                        } else {
                            _self.errors.zipcode = ["Oops! It appears there was a problem with your form. Please contact your representative."];
                        }
                        console.error(errors);
                        loading.stop();
                    });

            }

        });

        function validate(requiredField) {
            let deferred = _q.defer();
            let valid = true;

            Object.keys(requiredField).forEach((key) => {
                if (key === 'keyword') {//skip this UTM field
                    return;
                }
                if (requiredField[key] === "" || typeof requiredField[key] === 'undefined') {
                    _self.errors[key] = ['Please enter your ' + key.replace('_', ' ').toLowerCase()];
                    valid = false;
                } else if ('zipcode' === key) {
                    if (requiredField[key].toString().length !== 5) {
                        _self.errors[key] = ['Please enter valid ' + key.replace('_', ' ').toLowerCase()];
                        valid = false;
                    } else {
                        _self.errors[key] = [];
                    }
                } else {
                    _self.errors[key] = [];
                }
            });


            //this.$http.post('/user/validate_user_email', {email: requiredField.email})
            //    .success(() => {
            //        callback(valid)})
            //    .error((response) => {
            //        valid = false;
            //        _$scope.errors = _.extend(_$scope.errors, response.error);
            //        callback(valid);
            //    });
            deferred.resolve(valid);

            return deferred.promise;
        }


    }

    scrollToElement(id) {
        var target = $('#' + id);
        if (target.length > 0) {
            $('html,body').animate({
                scrollTop: (target.offset().top) - 10
            }, 1000);
        }
    }

    checkBusiness(phone) {
        this.checkingBusiness = 1;
        this.$window.onbeforeunload = notifyAboutIncompleteFlow.bind(this, phone, _self.keyword, this.$window.location.pathname);
        _http.get('/business/check', {params: {phone: phone}})
            .success((response) => {console.log(response.payload);this.checkingBusiness = 2;})
            .error((response) => {this.checkingBusiness = 0;console.error(response);});


        function notifyAboutIncompleteFlow (phone, keyword, path) {
            $.ajax({
                async: false,
                cache: false,
                data: {phone: phone, keyword: keyword, path: path},
                method: 'POST',
                url: '/business/incomplete_flow_notification'
            });
        }
    }
    /*Get slug value*/

     getSlugValue(){
      var  key = 'slug';
       var regexS = "[\\?&]" + key + "=([^&#]*)";
       var regex = new RegExp(regexS);
       var results = regex.exec(window.location.search);
       if (results == null)
           return "";
       else
           window.location = "/"+ decodeURIComponent(results[1].replace(/\+/g, " "));

    }
}

export default BusinessSearchLandingCtrl
