/**
 * Directive to show Ad Google Custom Search
 */
angular.module('adGoogleCustomSearch', [])
    .directive('adgooglecustomsearch', function () {
        return {
            restrict: 'E',
            template: function (element, attrs) {
                //provide at least basic attribute
                var gname = attrs.gname || 'adgooglecustomsearch';

                return '<gcse:search gname="{{gname}}"></gcse:search>'.replace('{{gname}}', gname);
            },
            link: function () {
                var cx = 'partner-pub-3790483111863219:1166824483';
                var gcse = document.createElement('script');
//                gcse.type = 'text/javascript';
                gcse.async = true;

                gcse.src = (document.location.protocol === 'https:' ? 'https:' : 'http:') +
                    '//cse.google.com/cse.js?cx=' + cx;
                var s = document.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(gcse, s);
            }
        };
    });
