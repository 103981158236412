'use strict';

class CopyToClipboard {
    constructor() {
        this.restrict = 'A';
        this.scope= {
            ngCopy: "=",
                onSuccess: "&",
                onError: "&"
        }
    }

    link(scope, element, attrs) {
        /*const eventName = attrs.evetName || 'click';
        const mas = 'Hello!!!!';


        const handleClick = function () {
            console.log(mas);
            const $temp = $("<input>");
            $("body").append($temp);
            $temp.val($(element).text()).select();
            document.execCommand("copy");
            $temp.remove();
        };

        element.on(eventName, handleClick);*/

        const createFake =  (text, command, onSuccess, onError) => {
            let placeholder = document.createElement("textarea");
            placeholder.setAttribute(
                "style",
                "position: absolute;overflow: hidden;width: 0;height: 0;top: 0;left: 0;"
            );
            placeholder.innerText = text;
            document.body.appendChild(placeholder);
            placeholder.select();
            try {
                document.execCommand(command);
                placeholder.remove();
            } catch (err) {
                onError(err);
            }
            onSuccess();
        };

        let onSuccess = () => {
            scope.onSuccess();
        };
        let onError = () => {
            scope.onError();
        };
        element.on("click", () => {
            createFake(scope.ngCopy, "copy", onSuccess, onError);
        });
    }
}

export default CopyToClipboard;