class BusinessSearchLandingModalCtrl {

    constructor($scope, data) {

        $scope.businesses = data.businesses;
        $scope.user = data.user;
        $scope.$http = data.$http;
        var params = data.params;
        $scope.runScan = function ($http, business) {
            if (business) {
                params.BPP_URL = business && business.Slug && business.Slug.slug;
                params.businessId = business && business.id;
                redirectListingHandler($http, business, params);
            }
        };

    }

}

/**
 * Send email from SEO landing form and do redirect to verify/upgrade flow
 */
function redirectListingHandler($http, business, params) {
    var doRedirect = function (business) {
            window.location.href = '/business/upgrade_weblead/' + business.id + '#!#1';
        },
        formSubmitHandler = doRedirect.bind(this, business);
    $http.post('/home/seo_promotion_form_submit', params)
        .success(formSubmitHandler)
        .error(formSubmitHandler);
}

export default BusinessSearchLandingModalCtrl;