'use strict';

import _ from "lodash";

export default class VendastaCtrl {
    constructor($scope, $rootScope, $http, $uibModal, $cookies, $window, $location, blockUI) {

        this.blockData = blockUI.instances.get('block-data');
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$http = $http;
        this.$uibModal = $uibModal;
        this.$cookies = $cookies;
        this.$window = $window;
        this.$location = $location;
        this.businessData = {};
        this.vendastaSSOPage = '';
        this.businessOwnerData = {};
        this.tokenId = '';
        this.categories = [];
        this.selectedCategory = {};
        this.errors = {};
        this.step = 1;
        this.states = [];

        this.onSelectCategories = function(id, categoryIndex) {
            // clear redundant selectors:
            if (_.isNumber(categoryIndex) && Object.keys(this.selectedCategory).length - categoryIndex > 1) {
                delete this.selectedCategory[++categoryIndex];
                delete this.selectedCategory[++categoryIndex];

                try {document.getElementsByClassName('input-with-icon ng-scope')[2].style.display = 'none';} catch (e11) {}
            }

            this.loadCategories(id);
        }.bind(this);

        this.loadCategories = (parentId, level, limit) => {
            let $this = this,
                params = {
                    limit: limit || 10000,
                    level: level,
                    parentId: parentId
                };

            $this.$http.get('/category', {params: params})
                .then(onSuccess)
                .catch(onError);

            function onSuccess(response) {
                const hideCategoriesNames = ['All'];
                if (response.data.count > 0) {
                    let rows = response.data.rows.filter(item => hideCategoriesNames.indexOf(item.name) === -1);
                    const indx = _.get(_.head(rows), 'syphLevel', 0);
                    $this.categories[indx] = rows;
                }
            }

            function onError(error) {
                console.log(error);
            }
        };

        this.loadCategories(null, 0);

        VendastaCtrl.init();
    }

    static init () {}

    initVendastaCtrl(businessData, vendastaSSOPage, businessOwnerData, tokenId, states) {
        var $this = this;

        $this.businessData = JSON.parse(businessData);

        // fix the issue with double quotas:
        if ($this.businessData.name) {
            $this.businessData.name = $this.businessData.name.replace(/`/g, "'").replace(/&quot;/g, '"');
        }

        $this.vendastaSSOPage = vendastaSSOPage;
        $this.businessOwnerData = JSON.parse(businessOwnerData);
        $this.tokenId = tokenId;
        $this.states = JSON.parse(states);

        $this.updatePasswordData = {
            id: $this.businessOwnerData.id,
            email: $this.businessOwnerData.email,
            password: '',
            passwordConfirm: '',
        };

        $this.loadSelectedCategoriesInitList();
    }

    async loadSelectedCategoriesInitList() {
        let categoryLevel = 0;
        if (this.businessData.Category) {
            let parentCategory = _.get(this, 'businessData.Category.Parent.parentId', null);
            if (parentCategory) {
                this.selectedCategory[categoryLevel++] = parentCategory;
                await this.loadCategories(parentCategory);
            }

            parentCategory = _.get(this, 'businessData.Category.parentId', null);
            this.selectedCategory[categoryLevel++] = parentCategory;
            await this.loadCategories(parentCategory);

            parentCategory = _.get(this, 'businessData.Category.id', null);
            this.selectedCategory[categoryLevel++] = parentCategory;
            await this.loadCategories(parentCategory);
        }
    }

    sendUpdatePassword() {
        var $this = this;

        $this.errors = {};
        $this.commonupdatePasswordError = null;
        const updatePasswordData = this.updatePasswordData;

        const isValidData = validateCreatePasswordInformation(updatePasswordData, this.errors);

        if (isValidData) {
            $this.blockData.start();
            $this.$http.post('/update-password-business-app-invitation', updatePasswordData)
                .then(() => {
                    $this.step = 2;
                    $this.blockData.stop();
                })
                .catch(function (response) {
                    console.error(response);
                    $this.errors = response.data.errors;
                    $this.commonupdatePasswordError = 'Please verify your entered information one more time.';
                    $this.blockData.stop();
                });
        } else {
            $this.commonupdatePasswordError = 'Please verify entered information. Invalid fields are marked in red';
        }
    }

    confirmPopup() {
        const $this = this;
        if (this.$scope.modalInstance) {
            this.$scope.modalInstance.close();
        }

        this.$scope.modalInstance = this.$uibModal
            .open({
                templateUrl: '/public/templates/confirmation/vendasta-confirmation.html',
                size: 'login-panel-form',
                keyboard: true,
                backdrop: 'static',
                windowClass: 'top25vh',
                scope: $this.$scope
            });
        this.blockData.stop();
    }

    closePopup() {
        this.blockData.stop();
        this.$scope.modalInstance.close();
    };

    confirmDetails() {
        var $this = this;
        $this.businessData.categories = Object.keys($this.selectedCategory).map((key) => $this.selectedCategory[key]);

        $this.blockData.start();
        $this.$http.post('/process-business-app-invitation', {tokenId: this.tokenId, businessData: this.businessData})
            .then(() => {
                $this.$window.location.href = $this.vendastaSSOPage;
            })
            .catch(function (response) {
                console.error(response);
                $this.errors = response.data;
                $this.commonUpdateBusinessError = response.message || _.get(response, 'data.message') || 'Something went wrong, please, try one more.';
                $this.blockData.stop();
            });
    }
}

function validateCreatePasswordInformation(updatePasswordData, errors) {
    let validationResult = true;

    // PASSWORD VALIDATION
    if (_.isEmpty(updatePasswordData.password)) {
        validationResult = false;
        errors.password = ['Password is empty'];
    }

    // PASSWORD CONFIRM VALIDATION
    if (_.isEmpty(updatePasswordData.passwordConfirm)) {
        validationResult = false;
        errors.passwordConfirm = ['Confirm is empty'];
    } else if (updatePasswordData.passwordConfirm !== updatePasswordData.password) {
        validationResult = false;
        errors.passwordConfirm = ['Passwords are different'];
    }

    return validationResult;
}
