/**
 * Filter to put word break symbols every "number" characters.
 * For example:
 * - break words (let's "number"=5): "lorem ipsum dolor loremipsumsitamen" => "lorem ipsum dolor lorem<wbr>ipsum<wbr>sitam<wbr>en"
 */
export default ($sce) => {
    return function (text, length) {
        var regex = new RegExp("\\b(\\w{" + length + "})(\\w+)\\b", 'ig');
        return recursiveReplace(text, regex);
    };

    function recursiveReplace(text, regex) {
        if (!regex.test(text)) {
            return $sce.trustAsHtml(text);
        }
        return recursiveReplace(text.replace(regex, "$1<wbr>$2"), regex);
    }
}