import _ from 'lodash';

function definedMediaType(media) {
    let galleryFilterType = '';
    if(media.imageType){
        galleryFilterType = 'photo';
    }
    if(media.type){
        galleryFilterType = 'video';
    }
    media.galleryFilterType = galleryFilterType;

    return media;
}
const PAGE_SIZE  = 16,
    SLIDER_PAGE_SIZE = 4;


class GalleryCtrl {
    constructor($scope, $uibModalInstance, options, CommonUtils) {

        let that = this;
        that.content = options;
        that.filterMode = 'all';
        that.currentPage = 0;
        that.selectedMediaItem = null;
        that.pageSize = PAGE_SIZE;
        that.businessName = options.businessName || '';
        that.mediaList = options.images.concat(options.videos).map(item => definedMediaType(item));

        that.init = (initEntry)=>{
            if(initEntry){
                that.selectedMediaItem = that.mediaList.find(item => item.playUrl ===  options.initEntry.playUrl);
                that.filterMode = that.selectedMediaItem.galleryFilterType;
            }
        };


        that.init(options.initEntry);


        that.numberOfPagesByMode = (mode) => {
            let pages = that.mediaList.filter(item => !mode || (mode === '' || mode === 'all') || item.galleryFilterType === mode);
            return Math.ceil(pages.length/that.pageSize);
        };

        that.calculatePages = (mode)=>{
            let maxPage = that.numberOfPagesByMode(mode);
            return _.range(1, ++maxPage);
        };

        that.calculatePagesAndSetFilterMode = (mode)=>{
            that.pages = that.calculatePages(mode);
            that.filterMode = mode;
            that.currentPage = 0;
        };

        that.pages = that.calculatePages();

        that.toPage = (page)=>{
            that.currentPage = --page;
        };

        that.onFilter = (filterBy) =>{
            that.calculatePagesAndSetFilterMode(filterBy);
        };

        that.selectMediaItem = (item)=>{
            that.selectedMediaItem = item;
            that.pageSize = SLIDER_PAGE_SIZE;
            that.calculatePagesAndSetFilterMode(item.galleryFilterType);
        };

        that.selectNextMediaItem = (currentItem)=> {
            let filtered = that.mediaList.filter(item => item.imageType === currentItem.imageType),
                index = filtered.findIndex(item => item.id === currentItem.id);
            that.selectedMediaItem = filtered[(filtered.length <= ++index ? 0 : index)];
        };

        that.back = ()=>{
            that.pageSize = PAGE_SIZE;
            that.calculatePagesAndSetFilterMode(_.get(that.selectedMediaItem, 'galleryFilterType', 'all'));
            that.selectedMediaItem = null;

        };

        that.selectFilterMode = (mode)=>{
            that.filterMode = mode;
        };

        that.trustUrl = (url) => {
            return CommonUtils.trustUrl(url);
        };

    }



}

export default GalleryCtrl;