'use strict';


class PrVerifiedLabel {
    constructor() {
        this.template = `
            <!--VERIFIED-->
                <div class="verified" data-ng-class="{'inline': inline === 'true'}" data-ng-if="verified">
                    <img src="/public/images/business_profile_page/verified.png" alt="business_profile_page-verified">&nbsp;Verified Info
                </div>
            
                <div class="unverified" data-ng-class="{'inline': inline === 'true'}" data-ng-if="!verified">
                    <img src="/public/images/business_profile_page/unclaimed.png" alt="business_profile_page-unclaimed">&nbsp;Unverified Info
                </div>
`;
        this.restrict = 'E';
        this.scope = {
            verified: '=',
            width: '@',
            inline: '@'//boolean
        };
    }
}

class PrSyncedLabel {
    constructor() {
        this.template = `    
    <!--SYNCED-->
    <!--unclaimed-->
    <div class="unsynced" data-ng-class="{'inline': inline === 'true'}" data-ng-if="!business.features || business.features.length == 0 || business.features.indexOf('upgraded') !== -1 && business.features.indexOf('paid') === -1">
        <img src="/public/images/business_profile_page/unclaimed.png" alt="business_profile_page-unclaimed"/>&nbsp;Unsynced Listings
    </div>


    <!--otherwise-->
    <div class="synced" data-ng-class="{'inline': inline === 'true'}" data-ng-if="business.features && business.features.indexOf('upgraded') !== -1 && business.features.indexOf('paid') !== -1">
        <img src="/public/images/business_profile_page/verified.png" alt="business_profile_page-verified"/>&nbsp;Synced <span class="listings-display">Listings</span>
        <span>Updated Now</span>
    </div>`;
        this.restrict = 'E';
        this.scope = {
            business: '=',
            width: '@',
            inline: '@'//boolean
        };
    }
}

class PrYextPartnerActiveLabel {
    constructor() {
        this.template = `    
    <!--Claimed by Yext-->
    <div class="synced" data-ng-class="{'inline': inline === 'true'}">
        <img src="/public/images/business_profile_page/check_green.png" width="{{width}}" alt=""/>&nbsp;PowerListing Synced
    </div>`;
        this.restrict = 'E';
        this.scope = {
            business: '=',
            width: '@',
            inline: '@'//boolean
        };
    }
}

export {PrVerifiedLabel, PrSyncedLabel, PrYextPartnerActiveLabel}