/**
 * common utils
 */
import _ from 'lodash';

const _$window = new WeakMap(),
    _$sce = new WeakMap(),
    r35CDN = 'http://c6804845becd1fb9235e-f663ccb0e39b4b76d5e0eb5b6dc4141b.r35.cf1.rackcdn.com',
    r34CDN = 'http://39075dc2b0fc20ecf313-fd096613630a8f907ad02ef43966322e.r34.cf1.rackcdn.com';

class CommonUtils {

    constructor($window, $sce){
        _$window.set(this,$window);
        _$sce.set(this,$sce);
    }

    changeValueForUrlSearchParam(string) {
        return string && string.replace(new RegExp('-', 'g'), '_').replace(new RegExp('&', 'g'), '%26').replace(new RegExp(' ', 'g'), '-').toLowerCase();
    }

    /**
     * Implementation based on Browser plugin, fallback to width of window
     * @returns {*}
     */
    isMobile() {
        return window.bowser ? window.bowser.mobile  : _$window.get(this).innerWidth < 768;
    }

    /**
     * Implementation based on Browser plugin, fallback to width of window
     * @returns {*}
     */
    isTablet() {
        return window.bowser ? (window.bowser.tablet || _$window.get(this).innerWidth >= 768 && _$window.get(this).innerWidth <= 1200) : _$window.get(this).innerWidth >= 768 && _$window.get(this).innerWidth <= 1200;
    }

    normalizeUrl(url){
        return url.indexOf('http') === -1 ? ('http://' + url) : url
    }

    trustUrl(url){
        if (!url || typeof url === 'object') {
            return url;
        }

        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        let match = url.match(regExp);

        return _$sce.get(this).trustAsResourceUrl(url);

        // return (match && match[7].length === 11 ? _$sce.get(this).trustAsResourceUrl("https://www.youtube.com/embed/" + match[7] + "?wmode=transparent&controls=0&color=white&modestbranding=1&showinfo=0") : '')
    }

    /**
     * Update Url as proxy url for entry using path (ui version)
     *
     * @param {Object} entity
     * @param {Array<String>} fieldPathsAsArray
     *
     * @returns {Object}
     */
    uISetProxyUrlInsteadOfRackspace (entity, fieldPathsAsArray) {
        for (const currentPath of fieldPathsAsArray) {
            const primeImageUrl = _.get(entity, currentPath);

            if (primeImageUrl) {
                _.set(entity, currentPath, primeImageUrl.replace(r35CDN, '/cdn-images').replace(r34CDN, '/cdn-34-images'));
            }
        }

        return entity;
    }

}

export default CommonUtils;
