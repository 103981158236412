'use strict';

import _ from 'lodash';

function validateInputScan(scanInformation, errors) {
    const phoneRegExp = /^[0-9]{10}$/;
    let validationResult = true;

    // NAME VALIDATION
    if (_.isEmpty(scanInformation.name)) {
        validationResult = false;
        errors.scanInformation.name = ['Business Name is empty'];
    }

    if (!_.isEmpty(scanInformation.name) && scanInformation.name.includes("\'")) {
        validationResult = false;
        errors.scanInformation.name = ["Business Name cannot contain \' (Apostrophe), please, replace it with \`(Acute accent)"];
    }

    // FIRST NAME VALIDATION
    if (_.isEmpty(scanInformation.firstName)) {
        validationResult = false;
        errors.scanInformation.firstName = ['First Name is empty'];
    }

    // LAST NAME VALIDATION
    if (_.isEmpty(scanInformation.lastName)) {
        validationResult = false;
        errors.scanInformation.lastName = ['Last Name is empty'];
    }

    // PHONE VALIDATION
    if (_.isEmpty(scanInformation.phone)) {
        validationResult = false;
        errors.scanInformation.phone = ['Phone is empty'];
    } else if (!phoneRegExp.test(_.trim(scanInformation.phone))) {
        validationResult = false;
        errors.scanInformation.phone = ['Invalid phone format'];
    }

    return validationResult;
}

function validateDetermineEmail(email, errors) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let validationResult = true;

    // EMAIL VALIDATION
    if (_.isEmpty(email)) {
        validationResult = false;
        errors.determineUserInformation.email = ['Email is empty'];
    } else if (!emailRegExp.test(email)) {
        validationResult = false;
        errors.determineUserInformation.email = ['Invalid email format'];
    }

    return validationResult;
}

function validateBusinessInformation(buildInformation, errors) {
    const phoneRegExp = /^[0-9]{10}$/,
        websiteRegExp = /^((ftp|http|https):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
//        emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;

    let validationResult = true;

    // NAME VALIDATION
    if (_.isEmpty(buildInformation.name)) {
        validationResult = false;
        errors.buildInformation.name = ['Business Name is empty'];
    }
    if (!_.isEmpty(buildInformation.name) && buildInformation.name.includes("\'")) {
        validationResult = false;
        errors.buildInformation.name = ["Business Name cannot contain \' (Apostrophe), please, replace it with \`(Acute accent)"];
    }

    // PHONE VALIDATION
    if (_.isEmpty(buildInformation.phone)) {
        validationResult = false;
        errors.buildInformation.phone = ['Phone is empty'];
    } else if (!phoneRegExp.test(_.trim(buildInformation.phone))) {
        validationResult = false;
        errors.buildInformation.phone = ['Invalid phone format'];
    }

    // WEBSITE VALIDATION
//  if (_.isEmpty(buildInformation.website)) {
//    validationResult = false;
//    errors.buildInformation.website = ['Website is empty'];
//  } else if (!websiteRegExp.test(_.trim(buildInformation.website))) {
//    validationResult = false;
//    errors.buildInformation.website = ['Invalid website format'];
//  }
    if (!_.isEmpty(buildInformation.website) && !websiteRegExp.test(_.trim(buildInformation.website))) {
        validationResult = false;
        errors.buildInformation.website = ['Invalid website format'];
    }

    // COUNTRY VALIDATION
    if (_.isEmpty(buildInformation.country)) {
        validationResult = false;
        errors.buildInformation.country = ['Country is empty'];
    }

    // STATES VALIDATION
    if (_.isEmpty(buildInformation.state)) {
        validationResult = false;
        errors.buildInformation.state = ['State is empty'];
    }

    // ZIPCODE VALIDATION
    if (_.isEmpty(buildInformation.zipcode)) {
        validationResult = false;
        errors.buildInformation.zipcode = ['Zipcode is empty'];
    } else if (!zipCodeRegExp.test(buildInformation.zipcode)) {
        validationResult = false;
        errors.buildInformation.zipcode = ['Invalid zipcode format'];
    }

    // CITY VALIDATION
    if (_.isEmpty(buildInformation.city)) {
        validationResult = false;
        errors.buildInformation.city = ['City is empty'];
    }

    // ADDRESS VALIDATION
    if (_.isEmpty(buildInformation.address)) {
        validationResult = false;
        errors.buildInformation.address = ['Address is empty'];
    }

    // CATEGORY VALIDATION
    if (_.isEmpty(buildInformation.category)) {
        validationResult = false;
        errors.buildInformation.category = ['Category is empty'];
    }

//  // MESSAGE VALIDATION
//  if (_.isEmpty(buildInformation.description)) {
//    validationResult = false;
//    errors.buildInformation.description = ['Message is empty'];
//  }

    return validationResult;
}

function validateContactInformation(contactInformation, errors) {
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phoneRegExp = /^[0-9]{10}$/;

    let validationResult = true;

    // FIRST NAME VALIDATION
    if (_.isEmpty(contactInformation.firstName)) {
        validationResult = false;
        errors.contactInformation.firstName = ['First Name is empty'];
    }

    // LAST NAME VALIDATION
    if (_.isEmpty(contactInformation.lastName)) {
        validationResult = false;
        errors.contactInformation.lastName = ['Last Name is empty'];
    }

    // EMAIL VALIDATION
    if (_.isEmpty(contactInformation.email)) {
        validationResult = false;
        errors.contactInformation.email = ['Email is empty'];
    } else if (!emailRegExp.test(contactInformation.email)) {
        validationResult = false;
        errors.contactInformation.email = ['Invalid email format'];
    }

    // PASSWORD VALIDATION
    if (_.isEmpty(contactInformation.password)) {
        validationResult = false;
        errors.contactInformation.password = ['Password is empty'];
    }

    // PASSWORD CONFIRM VALIDATION
    if (_.isEmpty(contactInformation.passwordConfirm)) {
        validationResult = false;
        errors.contactInformation.passwordConfirm = ['Confirm is empty'];
    } else if (contactInformation.passwordConfirm !== contactInformation.password) {
        validationResult = false;
        errors.contactInformation.passwordConfirm = ['Passwords are different'];
    }

    // PHONE VALIDATION
    if (!_.isEmpty(contactInformation.mobilePhone) && !phoneRegExp.test(_.trim(contactInformation.mobilePhone))) {
        validationResult = false;
        errors.contactInformation.mobilePhone = ['Invalid phone format'];
    }

    return validationResult;
}

function validateCheckoutInformation(checkoutInformation, errors) {
    let validationResult = true;

    const zipCodeRegExp = /(^\d{5}(-\d{4})?$)|(^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$)/;
    const yearRegExp = /^\d{4}$/;
    const commonAddressError = 'Check data';

    //billing address:
    if (_.isEmpty(checkoutInformation.companyName)) {
        validationResult = false;
        errors.checkoutInformation.companyName = ['Name is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.address)) {
        validationResult = false;
        errors.checkoutInformation.address = ['Address is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.city)) {
        validationResult = false;
        errors.checkoutInformation.city = ['City is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.state)) {
        validationResult = false;
        errors.checkoutInformation.state = ['State is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.zipcode)) {
        validationResult = false;
        errors.checkoutInformation.zipcode = ['Zipcode is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    } else if (!zipCodeRegExp.test(checkoutInformation.zipcode)) {
        validationResult = false;
        errors.checkoutInformation.zipcode = ['Invalid zipcode format'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    if (_.isEmpty(checkoutInformation.country)) {
        validationResult = false;
        errors.checkoutInformation.country = ['Country is empty'];
        errors.checkoutInformation.commonAddressError = commonAddressError;
    }

    //billing Information:
    if (_.isEmpty(checkoutInformation.firstName)) {
        validationResult = false;
        errors.checkoutInformation.firstName = ['First Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.lastName)) {
        validationResult = false;
        errors.checkoutInformation.lastName = ['Last Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.name)) {
        validationResult = false;
        errors.checkoutInformation.name = ['Card Name is empty'];
    }

    if (_.isEmpty(checkoutInformation.number)) {
        validationResult = false;
        errors.checkoutInformation.number = ['Card Number is empty'];
    }

    if (_.isEmpty(checkoutInformation.cvv)) {
        validationResult = false;
        errors.checkoutInformation.cvv = ['Empty'];
    }

    if (!_.isNumber(checkoutInformation.expirationMonth)) {
        validationResult = false;
        errors.checkoutInformation.expirationMonth = ['Invalid'];
    }

    if (!yearRegExp.test(_.trim(checkoutInformation.expirationYear))) {
        validationResult = false;
        errors.checkoutInformation.expirationYear = ['Invalid'];
    }

    return validationResult;
}

function changedBuildData(businessInformation, contactInformation) {
    const newData = {};

    const newBusiness = {};
    const newUser = {};

    //add optional values if necessary:
    if (!_.isEmpty(businessInformation.phone)) {
        contactInformation.mobilePhone = businessInformation.phone;
    }

    //if business is exist:
    if (businessInformation.id) {
        newBusiness.id = businessInformation.id;
    }

    //parse:
    newBusiness.phone = businessInformation.phone;
    newBusiness.name = businessInformation.name;
    newBusiness.address = businessInformation.address;
    newBusiness.city = businessInformation.city;
    newBusiness.state = businessInformation.state;
    newBusiness.zipcode = businessInformation.zipcode;
    newBusiness.country = businessInformation.country;
    newBusiness.description = businessInformation.description;
    newBusiness.website = businessInformation.website;
    newBusiness.categoryId = _.last(businessInformation.category) || null;

    newUser.email = contactInformation.email;
    newUser.firstName = contactInformation.firstName;
    newUser.lastName = contactInformation.lastName;
    newUser.password = contactInformation.password;
    newUser.passwordConfirm = contactInformation.passwordConfirm;
    newUser.phone = contactInformation.mobilePhone;

    newData.business = newBusiness;
    newData.user = newUser;

    return newData;
}

function changedBuildDataWithUserId(businessInformation, userId) {
    const newData = {};

    const newBusiness = {};

    //if business is exist:
    if (businessInformation.id) {
        newBusiness.id = businessInformation.id;
    }

    //parse:
    newBusiness.phone = businessInformation.phone;
    newBusiness.name = businessInformation.name;
    newBusiness.address = businessInformation.address;
    newBusiness.city = businessInformation.city;
    newBusiness.state = businessInformation.state;
    newBusiness.zipcode = businessInformation.zipcode;
    newBusiness.country = businessInformation.country;
    newBusiness.description = businessInformation.description;
    newBusiness.website = businessInformation.website;
    newBusiness.categoryId = _.last(businessInformation.category) || null;

    newData.business = newBusiness;

    //set only userId:
    newData.user = userId;

    return newData;
}

function changedCheckoutData(userId, businessId, membershipId, checkoutInformation, leadId) {
    const newData = {};

    if (checkoutInformation.number) {
        checkoutInformation.cardNumber = checkoutInformation.number;
    }

    if (checkoutInformation.name) {
        checkoutInformation.cardholderName = checkoutInformation.name;
    }

    newData.userId = userId;
    newData.businessId = businessId;
    newData.membershipId = membershipId;
    newData.billing = checkoutInformation;
    newData.leadId = leadId;

    return newData;
}

function parseErrors (responseErrors) {
    const parsedErrors = {buildInformation: {}, contactInformation: {}};

    if (responseErrors.business.existBusiness) {
        parsedErrors.buildInformation.name = ['Such business is exist'];
    } else {
        parsedErrors.buildInformation = responseErrors.business;
    }

    parsedErrors.contactInformation.email = !_.isEmpty(responseErrors.user.email) ? [_.first(responseErrors.user.email)] : undefined;
    if (!_.isEmpty(responseErrors.user.user)) {
        parsedErrors.contactInformation.email = [_.first(responseErrors.user.user)];
    }
    parsedErrors.contactInformation.firstName = responseErrors.user.firstName;
    parsedErrors.contactInformation.lastName = responseErrors.user.lastName;
    parsedErrors.contactInformation.mobilePhone = responseErrors.user.phone;
    if (!_.isEmpty(responseErrors.user.password) && responseErrors.user.password.length > 0) {
        if (responseErrors.user.password.length > 1) {
            parsedErrors.contactInformation.password = [_.first(responseErrors.user.password)];
            parsedErrors.contactInformation.passwordConfirm = [_.last(responseErrors.user.password)];
        } else {
            parsedErrors.contactInformation.password = responseErrors.user.password;
            parsedErrors.contactInformation.passwordConfirm = responseErrors.user.password;
        }
    }

    return parsedErrors;
}

function parseCheckoutErrors (errorMessages) {
    const parsedErrors = {checkoutInformation: {}};
    let notValidationError = null;

    //billing address:
    const billingAddressErrors = _.get(errorMessages, 'billingAddress.base[0].message', '');
    if (!_.isEmpty(billingAddressErrors)) {
        notValidationError = 'Wrong Billing Address';
    }

    //billing information:
    const billingNameErrors = _.get(errorMessages, 'billingInformation.base[0].message', '');
    if (!_.isEmpty(billingNameErrors)) {
        notValidationError = 'Wrong Billing Information';
    }
    const billingCvvErrors = _.get(errorMessages, 'billingInformation.cvv[0].message', '');
    if (!_.isEmpty(billingCvvErrors)) {
        parsedErrors.checkoutInformation.cvv = [billingCvvErrors];
    }
    const expirationMonthErrors = _.get(errorMessages, 'billingInformation.expirationMonth[0].message', '');
    if (!_.isEmpty(expirationMonthErrors)) {
        parsedErrors.checkoutInformation.expirationMonth = [expirationMonthErrors];
    }
    const expirationYearErrors = _.get(errorMessages, 'billingInformation.expirationYear[0].message', '');
    if (!_.isEmpty(expirationYearErrors)) {
        parsedErrors.checkoutInformation.expirationYear = [expirationYearErrors];
    }
    const numberErrors = _.get(errorMessages, 'billingInformation.number[0].message', '');
    if (!_.isEmpty(numberErrors)) {
        parsedErrors.checkoutInformation.number = [numberErrors];
    }

    return {parsedErrors, notValidationError};
}

class AffiliatesPaymentCtrl {

    constructor (
        $scope, $rootScope, $http, $uibModal, $cookies, $timeout, $window,
        $location, blockUI, CheckoutService, CommonUtils, CheckoutConst,
        ListingService, DefaultReviewsData) {

        var $this = this;

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$cookies = $cookies;
        this.$http = $http;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$location = $location;
        this.blockData = blockUI.instances.get('block-data');

        //cookie domain:
        this.cookieDomain = (this.$window.location.host === 'www.pr2.local' ||
            this.$window.location.host === 'pr2.local')
            ? '.pr2.local'
            : '.pr.business';

        //models:
        this.states = [];
        this.categories = {};
        this.errors = {
            businessInformation: {},
            contactInformation: {},
            creditCardBillingAddress: {},
            creditCardInformation: {},
            category: {}
        };
        this.notValidationError = null;
        this.callUsMessage = null;
        this.bannerMessage = null;
        this.paymentSubmitted = false;

        //scopes:
        this.apc = $scope.apc;
        $scope.months = CheckoutConst.monthsValues;
        $scope.years = CheckoutService.genYears();
        $scope.countries = [
            { code: 'US', name: 'USA' },
            { code: 'CA', name: 'Canada' },
            { code: 'UK', name: 'United Kingdom' },
            { code: 'AU', name: 'Australia' },
        ];

        /**
         * Init steps:
         */

        this.apc.initFirstStep = (stepNumber, listingsCompany, scanCookieData, leadSource) => {
            $this.$scope.step = Number(stepNumber);
            $this.apc.listingsCompany = JSON.parse(listingsCompany);

            $this.apc.listingsCompanyTop = _.slice($this.apc.listingsCompany, 1, 12);

            if (leadSource) $this.apc.leadSource = leadSource

            //get authData:
            $http.get('/auth')
            .then(function (response) {
                if(!!response.data.authData){
                    $this.authData = response.data.authData;
                    $this.scanInformation.firstName = response.data.authData.firstName;
                    $this.scanInformation.lastName = response.data.authData.lastName;
                }
            })
            .catch(function (error) {
                console.error(error);
            });

            try {
                const parsedScanCookieData = JSON.parse(scanCookieData);
                if (!_.isEmpty(parsedScanCookieData)) {
                    $this.scanInformation = {name: parsedScanCookieData.name,
                        firstName: parsedScanCookieData.firstName,
                        lastName: parsedScanCookieData.lastName,
                        phone: parsedScanCookieData.phone};
                } else {
                    $this.scanInformation = {name: '', firstName: '', lastName: '', phone: ''};
                }

                $this.determineUserInformation = {email: ''};
                if (!_.isEmpty(parsedScanCookieData) && parsedScanCookieData.userEmail) {
                    $this.determineUserInformation.email = parsedScanCookieData.userEmail;
                }

            } catch (err) {
                console.log('Parsed cookie error', err);
                $this.scanInformation = {name: '', firstName: '', lastName: '', phone: ''};
            }
        };

        this.apc.initSecondStep = (stepNumber, businessId, businessStateName) => {
            $this.$scope.step = Number(stepNumber);
            $this.businessId = businessId;
            $this.businessStateName = businessStateName;
            $this.publishersPending = DefaultReviewsData.getPublisherNames();
            $this.isReviewsUploaded = false;

            //generate review:
//            $this.blockData.start();
//            $this.blockData.message('Scanning...');

            //get authData:
            $http.get('/auth')
            .then(function (response) {
                if(!!response.data.authData){
                    $this.authData = response.data.authData;
                }
            })
            .catch(function (error) {
                console.error(error);
            });

            let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');
            this._initScanStepPart(cookieAffiliatePaymentData, businessId);
        };

        this._initScanStepPart = (cookieAffiliatePaymentData, businessId) => {

            const cookieReviewsReviewData = $this.$cookies.getObject('affiliatesPaymentData');

            $this.businessName = _.get(cookieReviewsReviewData, 'scanStep.name') || '';

            if (businessId) {
                //generate reviews:
                $http.post(`/reviews/generate-yext-review-by-id/${businessId}`)
                .then(function (response) {

                    const parsedLiveReport = response.data.liveReportData;

                    const features = response.data.features;
                    const isUpgraded = _.includes(features, 'upgraded');

                    $this.apc.reportData = response.data.reportData;
//                    $this.apc.visibilityScore = ListingService.getVisibilityScore(parsedLiveReport, isUpgraded);
//                    $this.apc.mainScore = _.round($this.apc.visibilityScore.score);
                    $this.apc.mainScore = _.round(response.data.mainScore);

                    $this.apc.isReviewsUploaded = true;
//                    $this.blockData.stop();
                })
                .catch(function (errors) {
                    console.error(`Cannot generate yext reviews: ${errors}`);

                    const
                        parsedLiveReport = DefaultReviewsData.emptyReviewData(),
                        features = [];
                    const isUpgraded = _.includes(features, 'upgraded');

                    $this.apc.reportData = DefaultReviewsData.emptyYextReportData();
//                    $this.apc.visibilityScore = ListingService.getVisibilityScore(parsedLiveReport, isUpgraded);
//                    $this.apc.mainScore = _.round($this.apc.visibilityScore.score);
                    $this.apc.mainScore = 1;

                    $this.apc.isReviewsUploaded = true;
//                    $this.blockData.stop();
                });
            } else {
                console.log('Business is not exist!');

                const
                    parsedLiveReport = DefaultReviewsData.emptyReviewData(),
                    features = [];
                const isUpgraded = _.includes(features, 'upgraded');

                $this.apc.reportData = DefaultReviewsData.emptyYextReportData();
//                $this.apc.visibilityScore = ListingService.getVisibilityScore(parsedLiveReport, isUpgraded);
//                $this.apc.mainScore = _.round($this.apc.visibilityScore.score);
                $this.apc.mainScore = 1;

                $this.apc.isReviewsUploaded = true;
//                $this.blockData.stop();
            }

            //update lead status:
            if (cookieAffiliatePaymentData && cookieAffiliatePaymentData.scanStep && cookieAffiliatePaymentData.scanStep.leadId) {
                const leadData = {
                    id: cookieAffiliatePaymentData.scanStep.leadId,
                    leadStatus: 'Ecommerce Review Step'
                }

                $this.$http.post('/payments/update-saleforce-lead', leadData)
                .then((response) => {
                    console.log('Lead is updated!');
                })
                .catch(function (response) {
                    console.error(response);
                });
            }
        }

        this.apc.initThirdStep = (stepNumber) => {
            $this.$scope.step = Number(stepNumber);
            $this.loadCategories(null, 0);

            $this.buildInformation = {
                name: '',
                phone: '',
                website: '',
                country: 'US',
                state: '',
                zipcode: '',
                city: '',
                address: '',
                category: [],
                description: ''
            };

            $this.contactInformation = {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: '',
                mobilePhone: ''
            };

            $this.hasBusinessOwner = false;

            //get authData:
            $http.get('/auth')
            .then(function (response) {
                if(!!response.data.authData){
                    $this.authData = response.data.authData;
                    $this.contactInformation.email = response.data.authData.email.replace(/@.+/, '*****');
                }
            })
            .catch(function (error) {
                console.error(error);
            });

            let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');

            return new Promise(resolve =>
                resolve($this._checkIfBusinessHasOwner(cookieAffiliatePaymentData)))
                .then(() => $this._initBusinessDataIfExists(cookieAffiliatePaymentData));
        }

        this._changeBusinessIfIsNotOwner = () => {
            let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');

            const authData = _.get($this, '$rootScope.authData', null);
            let userId = null;

            if (authData) {
                //if user is logged in - set contactInformation.email
                $this.contactInformation.email = authData.email.replace(/@.+/, '*****');
                userId = authData.id;

                //if there is error in email field clear it:
                const emailContactInformationError = _.get($this,'errors.contactInformation.email', null);
                if (emailContactInformationError) {
                    $this.errors.contactInformation = {};
                }
            }

            //check if business is exists and user is exists:
            if (cookieAffiliatePaymentData && cookieAffiliatePaymentData.reviewStep
                && cookieAffiliatePaymentData.reviewStep.businessId && $this.businessOwnerId) {
                $this.blockData.start();

                $http.get('/payments/check-user-if-owner/' + userId + '/' + cookieAffiliatePaymentData.reviewStep.businessId)
                .then(function (response) {
                    if (response.data.redirectUrl) {
                        $this.$window.location.href = response.data.redirectUrl || '/business/add';
                    }

                    if (response.data.newBusiness) {
                        cookieAffiliatePaymentData.reviewStep.businessId = response.data.newBusiness.id;
                        $this.$cookies.putObject('affiliatesPaymentData', cookieAffiliatePaymentData, {domain: $this.cookieDomain, path: '/'});

                        //update business data on page:
                        $this._initBusinessDataIfExists(cookieAffiliatePaymentData);
                    }
                })
                .catch(function (errors) {
                    console.error(errors);
                });
                $this.blockData.stop();
            }
        }

        this._checkIfBusinessHasOwner = (cookieAffiliatePaymentData) => {
            const businessId = _.get(cookieAffiliatePaymentData, 'reviewStep.businessId', null);
            if (businessId) {
                $this.blockData.start();
                $http.get('/payments/get-business-owner-by-id/' + businessId)
                .then(function (response) {
                    if (!_.isEmpty(response.data)) {
                        $this.hasBusinessOwner = response.data.hasBusinessOwner;
                        $this.businessOwnerId = response.data.id;
                    }
                })
                .catch(function (errors) {
                    console.error(`Cannot check user with email[${cookieAffiliatePaymentData.scanStep.email}]: ${errors}`);
                });
                $this.blockData.stop();
            }
        }

        this._initBusinessDataIfExists = (cookieAffiliatePaymentData) => {

            if (cookieAffiliatePaymentData) {

                if (cookieAffiliatePaymentData.scanStep) {
                    $this.contactInformation.firstName = cookieAffiliatePaymentData.scanStep.firstName || '';
                    $this.contactInformation.lastName = cookieAffiliatePaymentData.scanStep.lastName || '';
                    $this.contactInformation.email = cookieAffiliatePaymentData.scanStep.userEmail || '';
                    $this.buildInformation.name = cookieAffiliatePaymentData.scanStep.name || '';
                    $this.buildInformation.phone = cookieAffiliatePaymentData.scanStep.phone || '';
                }

                const businessId = _.get(cookieAffiliatePaymentData, 'reviewStep.businessId', null) || _.get(cookieAffiliatePaymentData, 'scanssoStep.businessId');
                if (businessId) {
                    $this.blockData.start();

                    $http.get('/business/get/' + businessId)
                    .then(function (response) {
                        $this.buildInformation.id = response.data.id;
                        $this.buildInformation.name = response.data.name;
                        $this.buildInformation.phone = response.data.phone;
                        $this.buildInformation.website = response.data.website;
                        $this.buildInformation.country = response.data.countryCode || response.data.country || 'US';
                        $this.buildInformation.state = response.data.state;
                        $this.buildInformation.zipcode = response.data.zipcode;
                        $this.buildInformation.city = response.data.city;
                        $this.buildInformation.address = response.data.address;
                        $this.buildInformation.description = response.data.description;

                        if (response.data.Category && response.data.Category.Parent) {
                            $this.buildInformation.category.push(response.data.Category.Parent.id);
                            $this.buildInformation.category.push(response.data.Category.id);
                            $this.loadCategories(response.data.Category.Parent.id);
                        }
                        $this.business = response.data;
                    })
                    .catch(function (errors) {
                        console.error(`Cannot load business with id[${cookieAffiliatePaymentData.reviewStep.businessId}]: ${errors}`);
                    });
                    $this.blockData.stop();
                }
            }

            const currentLeadId = _.get(cookieAffiliatePaymentData, 'reviewStep.leadId');
            if (currentLeadId) {
                const leadData = {
                    id: currentLeadId,
                    leadStatus: 'Ecommerce Build Step'
                }

                $this.$http.post('/payments/update-saleforce-lead', leadData)
                .then((response) => {
                    console.log('Lead is updated!');
                })
                .catch(function (response) {
                    console.error(response);
                });
            }
        }

        this.signInPopupPayment = (typeFormOpen)=> {
            $this.$scope.typeForm = typeFormOpen;
            $this.$scope.login = $this.contactInformation.email;
            $this.$scope.modalInstance = $uibModal
            .open({
                templateUrl: '/public/templates/signInPopupWithoutRedirect.tpl.html',
                size: 'login-panel-form',
                keyboard: true,
                bindToController: true,
                controller: 'PaymentUserCtrl',
                controllerAs: 'puc',
                scope: $this.$scope
            });
        };

        this.apc.initFourthStep = (stepNumber, listingsCompany) => {
            $this.$scope.step = Number(stepNumber);
            $this.apc.listingsCompany = JSON.parse(listingsCompany);

            let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData') || {};
            const currentLeadId = _.get(cookieAffiliatePaymentData, 'buildStep.leadId');
            if (currentLeadId) {
                const leadData = {
                    id: currentLeadId,
                    leadStatus: 'Ecommerce Fix Step'
                }

                $this.$http.post('/payments/update-saleforce-lead', leadData)
                .then((response) => {
                    console.log('Lead is updated!');
                })
                .catch(function (response) {
                    console.error(response);
                });
            }
        }

        this.apc.initFifthStep = (stepNumber) => {
            $this.$scope.step = Number(stepNumber);
            $this.isUseBusinessAddress = false;

            $this.checkoutInformation = {
                companyName: '',
                country: '',
                state: '',
                zipcode: '',
                city: '',
                address: '',

                firstName: '',
                lastName: '',
                name: '',
                number: '',
                cvv: '',
                expirationMonth: '',
                expirationYear: '',
                isAgree: false
            };

            $this.blockData.start();

            let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');
            let leadId = null;

            //try to get lead id from build step:
            if (cookieAffiliatePaymentData.buildStep && cookieAffiliatePaymentData.buildStep.leadId) {
                leadId = cookieAffiliatePaymentData.buildStep.leadId;
            }

            //try to get lead id from checkout step:
            if (cookieAffiliatePaymentData.checkoutStep && cookieAffiliatePaymentData.checkoutStep.leadId) {
                leadId = cookieAffiliatePaymentData.checkoutStep.leadId;
            }

            $http.post('/affiliates-payments/init-checkout')
            .then(function (response) {

                $this.business = response.data.business;
                $this.userId = response.data.userId;
                $this.planData = response.data.planData;

                if ($this.planData && !_.isEmpty($this.planData.PaymentPlans)) {
                    const paymentsPlan = _.first($this.planData.PaymentPlans) || {};
                    $this.cardSummary = {originalPrice: paymentsPlan.setupFee || 699, packageType: `${paymentsPlan.name || ''}`,
                        termType: paymentsPlan.subscriptionMonthly ? 'Monthly' : paymentsPlan.duration == 12 ? 'Yearly' : `${paymentsPlan.duration}-Months`};
                } else {
                    $this.cardSummary = {originalPrice: 699, packageType: '6-Month', termType: '6-Months'};
                }

                //clear previous cookie data and set data for current step:
                const checkoutCookieDataStep = {
                    checkoutStep: {
                        businessId: $this.business.id,
                        userId: $this.userId,
                        leadId: leadId,
                        membershipId: _.get($this, 'planData.id')
                    }
                };

//                $this.isReviewGeneratorPlan = _.isEqual($this.planData.title, 'Review Generator Plus');

                $this.$cookies.putObject('affiliatesPaymentData', checkoutCookieDataStep, {domain: $this.cookieDomain, path: '/'});
            })
            .catch(function (errors) {
                console.error(`Cannot load business data: ${errors}`);
                $this.cardSummary = {originalPrice: 699, packageType: '6-Month', termType: '6-Months'};
                $this.business = {};
            });
            $this.blockData.stop();

            $this._updateLeadInCheckoutPage(leadId);
        }

        this._updateLeadInCheckoutPage = (leadId) => {
            if (leadId) {
                const leadData = {
                    id: leadId,
                    leadStatus: 'Ecommerce Checkout Step'
                }

                $this.$http.post('/payments/update-saleforce-lead', leadData)
                .then((response) => {
                    console.log('Lead is updated!');
                })
                .catch(function (response) {
                    console.error(response);
                });
            }
        }

        this.apc.initSixthStep = (stepNumber) => {
            $this.$scope.step = Number(stepNumber);
            const thankyouStepDataSlug = _.get($this.$cookies.getObject('affiliatesPaymentData'), 'thankyouStep.businessSlug', null);
            $this.businessSlug = thankyouStepDataSlug || '';
            $this.bannerMessage = _.get($this.$cookies.getObject('affiliatesPaymentData'), 'thankyouStep.bannerMessage', null);
        }


        // other methods:

        $scope.isNumber = (value) => {
            return _.isNumber(value);
        };

        $scope.getCeilValue = (value) => {
            return Math.ceil(value);
        }

        $scope.isMobile = () => {
            return CommonUtils.isMobile();
        };

        this.onSelectCategories = function(id) {
            this.loadCategories(id);
        }.bind(this);

        this.addWebsiteUrl = function(url){
            let validUrl = url;
            if (url.indexOf('http') === -1) {
                validUrl = `http://${url}`;
            }
            return validUrl ;
        };

        this.loadCategories = (parentId, level, limit)=>{
            let $this = this,
                params = {
                    limit: limit || 10000,
                    level: level,
                    parentId: parentId
                };

            $this.$http.get('/category', {params: params})
            .then(onSuccess)
            .catch(onError);

            function onSuccess(response) {
                const hideCategoriesNames = ['All'];
                if(response.data.count > 0){
                    let rows = response.data.rows.filter(item => hideCategoriesNames.indexOf(item.name) === -1);
                    $this.categories[_.get(_.head(rows), 'syphLevel', 0)] = rows;
                }
            }

            function onError(error) {
                console.error(error);
            }
        };

        $this.apc.isNotError = report => {
            return report && _.isEmpty(report.errors);
        };

        $this.apc.checkStatus = (report)=> {
            let image = 'attention', color = 'red', fontWeight = 'bold';

            if (!report.match_address && !report.match_name && !report.match_phone) {
                image = 'close';
            } else if (report.match_address && report.match_phone && report.match_name) {
                image = 'check';
                color = 'black';
                fontWeight = ''
            }

            return {image: image, color: color, fontWeight: fontWeight};

        };

        $this.apc.isUpgraded = isUpdated => {
            return isUpdated === 'true';
        };

        AffiliatesPaymentCtrl.init();
    }

    static init () {}

    setStates (states) {
        this.states = JSON.parse(states);
    }

    setAsBusinessAddress() {
        //if set as business address, update values for billing:
        if (this.isUseBusinessAddress) {
            this.checkoutInformation.companyName = this.business.name;
            this.checkoutInformation.address = this.business.address;
            this.checkoutInformation.city = this.business.city;
            this.checkoutInformation.country = this.business.country || this.business.countryCode || 'US';
            this.checkoutInformation.state = this.business.state;
            this.checkoutInformation.zipcode = this.business.zipcode;
        }
    }

    /**
     * separated step sending:
     */
    sendScanStep() {
        const $this = this;
        $this.errors = {scanInformation: {}, determineUserInformation: {}};
        $this.notValidationError = null;

        let isValidData = validateInputScan($this.scanInformation, $this.errors);
        let isValidDetermineEmail = true;

        let userEmail = null;
        if ($this.authData && $this.authData.email) {
            userEmail = $this.authData.email;
        } else {
            userEmail = $this.determineUserInformation.email;
            isValidDetermineEmail = validateDetermineEmail(userEmail, $this.errors);
        }

        const inputData = $this.scanInformation;
        inputData.userEmail = userEmail;
        inputData.leadSource = $this.apc.leadSource;

        if (isValidData && isValidDetermineEmail) {
            const leadData = {
                name: $this.scanInformation.firstName,
                lastName: $this.scanInformation.lastName,
                businessName: $this.scanInformation.name,
                phone: $this.scanInformation.phone,
                leadSource: $this.apc.leadSource || 'Organic BPP Scan', //todo check it!!!!!!!!
                service: ["websites"],
                Status: 'Ecommerce Scan Step',
                isAffiliate: true //todo do we need it?
            };

            $this.blockData.start();
            $this.$http.post('/payments/create-saleforce-lead', leadData)
            .then((response) => {
                console.log(response);
                if(response.status === 200) {
                    inputData.leadId = response.data.id;
                    $this.$cookies.putObject('affiliatesPaymentData', {scanStep: inputData}, {domain: $this.cookieDomain, path: '/'});

                    $this.$window.location.href = '/affiliates/review';
                } else {
                    $this.blockData.stop();
                }
            })
            .catch(function (response) {
                console.error(response);

                $this.$cookies.putObject('affiliatesPaymentData', {scanStep: inputData}, {domain: $this.cookieDomain, path: '/'});

                // send to /affiliates/review:
                $this.$window.location.href = '/affiliates/review';
            });
        } else {
            $this.notValidationError = 'Wrong inputs';
        }
    }

    sendReviewStep() {
        const $this = this;
        console.log('was click #sendReviewStep');

        let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');

        let userId = null;
        if ($this.authData) {
            userId = $this.authData.id;
        }

        const reviewData = {businessId: $this.businessId, userId: userId};

        if (_.isEmpty(cookieAffiliatePaymentData)) {
            cookieAffiliatePaymentData = {reviewStep: reviewData};
        } else {
            cookieAffiliatePaymentData.reviewStep = reviewData;
        }

        const stepLeadId = _.get(cookieAffiliatePaymentData, 'scanStep.leadId', null) || $this.scanssoLeadId;

        if (stepLeadId) {
            cookieAffiliatePaymentData.reviewStep.leadId = stepLeadId;
        }

        $this.$cookies.putObject('affiliatesPaymentData', cookieAffiliatePaymentData, {domain: $this.cookieDomain, path: '/'});
        $this.$window.location.href = '/affiliates/build';
    }

    sendBuildStep(buildInformation, contactInformation) {
        var $this = this;

        $this.errors = {buildInformation: {}, contactInformation: {}};
        $this.notValidationError = null;
        $this.notValidationErrorInputs = null;
        $this.isUserEmailExist = false;

        const isValidBusinessData = validateBusinessInformation(buildInformation, $this.errors);
        let isValidContactData = true;

        const authData = $this.authData || $this.$rootScope.authData;

        //if user is not log in:
        if (_.isEmpty(authData)) {
            isValidContactData = validateContactInformation(contactInformation, $this.errors)
        }

        if (isValidBusinessData && isValidContactData) {
            let formParams = {};

            //if user is exist - sent only userId, if not - sent whole user contactInformation data:
            if (_.isEmpty(authData)) {
                formParams = changedBuildData(buildInformation, contactInformation);
            } else {
                formParams = changedBuildDataWithUserId(buildInformation, authData.id);
            }

            //set sourceFile:
            if (formParams.business) formParams.business.sourceFile = 'PR-affiliate-lead-source';

            $this.blockData.start();
            $this.$http.post('/payments/build', formParams)
            .then((response) => {
                console.log(response);

                if(response.status === 200) {
                    $this.businessId = response.data.business.id;
                    $this.userId = response.data.user.id;

                    let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');

                    //create or update lead and put cookie, and send to next step:
                    $this._createOrUpdateBuildLeadStep($this, cookieAffiliatePaymentData, authData);

                } else {
                    $this.blockData.stop();
                }
            })
            .catch(function (response) {

                if (!_.isEmpty(response.statusText)) {
                    $this.notValidationError = response.statusText;
                } else {
                    $this.notValidationError = _.get(response, 'data.message', 'Something went wrong');
                }

                const errorDetails = _.get(response, 'data.details', {}) || {};

                if (!!errorDetails.business && !!errorDetails.user) {
                    $this.notValidationError = 'Wrong entered information';
                    $this.errors = parseErrors(_.get(response, 'data.details', $this.errors));
                }

                if (errorDetails.business && errorDetails.business.business) {
                    $this.notValidationError = _.first(errorDetails.business.business);
                }

                if (errorDetails.user && !_.isEmpty(errorDetails.user.user)) {
                    const firstUserErrorDetail = _.first(errorDetails.user.user);
                    $this.notValidationError = firstUserErrorDetail;

                    $this.isUserEmailExist = _.endsWith(firstUserErrorDetail, 'already exists');
                }

                //if mobile phone is not valid by salesforce:
                const wrongPhone = _.get(response, 'data.details.possibleWrongPhoneError.phone', []);
                if (!_.isEmpty(wrongPhone)) {
                    const possibleWrongPhoneErrorMessage = _.first(wrongPhone);
                    if (possibleWrongPhoneErrorMessage.startsWith("Phone number")) {
                        $this.errors.buildInformation.phone = wrongPhone;
                        $this.notValidationError = 'Please, verify your Business Number one more time';
                    }
                }

                //if has already owner:
                if (errorDetails.message && errorDetails.message.startsWith("Business has an owner already")) {
                    $this.notValidationError = errorDetails.message;
                }

                //if No Reason Error Phrase:
                if (_.isEmpty($this.notValidationError)
                    || _.isEqual($this.notValidationError, 'Validation error')
                    || _.isEqual($this.notValidationError, 'No Reason Phrase')
                    || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                    $this.notValidationError = 'Please call us at 800-266-0219 to get assistance with your order';
                }

                console.error(response);
                $this.blockData.stop();
            });

        } else {
            $this.notValidationErrorInputs = 'Please verify entered information. Invalid entries are marked in red.';
        }
    }

    _setAndUpdateBuildCookieData($this, cookieAffiliatePaymentData, authData) {
        var $that = this;

        if (_.isEmpty(cookieAffiliatePaymentData)) {
            cookieAffiliatePaymentData = {buildStep: {businessId: $this.businessId, userId: $this.userId}};
        } else {
            cookieAffiliatePaymentData.buildStep = {businessId: $this.businessId, userId: $this.userId};
        }

        if (cookieAffiliatePaymentData.reviewStep && cookieAffiliatePaymentData.reviewStep.leadId) {
            cookieAffiliatePaymentData.buildStep.leadId = cookieAffiliatePaymentData.reviewStep.leadId;
        }

        //if we get response with leadId, set this value:
        if ($this.responseLeadId) {
            cookieAffiliatePaymentData.buildStep.leadId = $this.responseLeadId;
        }

        $this.$cookies.putObject('affiliatesPaymentData', cookieAffiliatePaymentData, {domain: $this.cookieDomain, path: '/'});

        //if success and not log in, log in user:
        if (_.isEmpty(authData)) {
            $this._loginUser($this);
        } else {
            $this.$window.location.href = '/affiliates/fix';
        }
    }

    _createOrUpdateBuildLeadStep($this, cookieAffiliatePaymentData, authData) {
        if (cookieAffiliatePaymentData && cookieAffiliatePaymentData.reviewStep && cookieAffiliatePaymentData.reviewStep.leadId) {
            const leadData = {
                id: cookieAffiliatePaymentData.reviewStep.leadId,
                leadStatus: 'Ecommerce Build Step',
                email: !!authData ? authData.email : $this.contactInformation.email
            }

            $this.$http.post('/payments/update-saleforce-lead', leadData)
            .then((response) => {
                console.log('Lead is updated!');
                $this._setAndUpdateBuildCookieData($this, cookieAffiliatePaymentData, authData);
            })
            .catch(function (response) {
                console.error(response);
                $this._setAndUpdateBuildCookieData($this, cookieAffiliatePaymentData, authData);
            });
        } else {
            $this.$http.get('/payments/get-business-lead/' + $this.businessId)
            .then((response) => {
                console.log(response);
                if(response.status === 200) {
                    $this.responseLeadId = response.data;
                    $this._setAndUpdateBuildCookieData($this, cookieAffiliatePaymentData, authData);
                }
            })
            .catch(function (response) {
                console.error('cannot get or create lead');
                console.error(response);
                $this._setAndUpdateBuildCookieData($this, cookieAffiliatePaymentData, authData);
            });
        }
    }

    _loginUser($this) {
        const credentialsData = {login: $this.contactInformation.email, password: $this.contactInformation.password};

        $this.$http.post('/payments/signin', credentialsData)
        .then((response) => {
            $this.$rootScope.authData = response.data.user;

            //redirect:
            $this.$window.location.href = '/affiliates/fix';
        })
        .catch(function (response) {
            if (response.statusText) {
                $this.notValidationError = 'BusinessCannot log in:' + response.statusText;
            } else {
                $this.notValidationError = 'BusinessCannot log in';
            }
            console.error(response);
            $this.blockData.stop();
        });
    }

    sendFixStep(planType) {
        var $this = this;

        let planData = {}

        let cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');
        if (!_.isEmpty(cookieAffiliatePaymentData) && !_.isEmpty(cookieAffiliatePaymentData.buildStep)) {
            planData = {
                plan: planType,
                userId: cookieAffiliatePaymentData.buildStep.userId,
                businessId: cookieAffiliatePaymentData.buildStep.businessId,
                paymentPlanDescription: 'Affiliate-Sales-Funnel'
            };
        }

        $this.blockData.start();
        $this.$http.post('/payments/choose-plan', planData)
        .then((response) => {
            console.log(response);

            if (_.isEmpty(cookieAffiliatePaymentData)) {
                cookieAffiliatePaymentData = {fixStep: response.data};
            } else {
                cookieAffiliatePaymentData.fixStep = response.data;
            }

            $this.$cookies.putObject('affiliatesPaymentData', cookieAffiliatePaymentData, {domain: $this.cookieDomain, path: '/'});
            $this.$window.location.href = '/affiliates/checkout';
            $this.blockData.stop();
        })
        .catch(function (response) {
            if (response.statusText) {
                $this.notValidationError = response.statusText;
            }

            if (response.data.message) {
                $this.notValidationError = response.data.message;
            }

            console.error(response);
            $this.blockData.stop();
        });
    }

    sendCheckoutStep(checkoutInformation) {
        var $this = this;

        $this.errors = {checkoutInformation: {}};
        $this.notValidationError = null;
        $this.bannerMessage = null;

        const isValidCheckoutData = validateCheckoutInformation(checkoutInformation, $this.errors);
        const membershipId = $this.planData.id;

        const cookieAffiliatePaymentData = $this.$cookies.getObject('affiliatesPaymentData');
        const leadId = _.get(cookieAffiliatePaymentData, 'checkoutStep.leadId', null);

        if (isValidCheckoutData) {

            console.log("#Valid!");

            const formParams = changedCheckoutData($this.userId, $this.business.id, membershipId, checkoutInformation, leadId);
            formParams.isAffiliate = true;

            $this.blockData.start();
            $this.$http.post('/payments/make-payments-charge', formParams)
            .then((response) => {
                console.log(response);
                if(response.status === 200 && !_.isEmpty(_.get(response.data, 'business.Slug.slug', null))) {

                    //get banner message if it is present:
                    if (_.isString(response.data.salesforce)) {
                        $this.bannerMessage = response.data.salesforce;
                    }
                    if (_.isString(response.data.yext)) {
                        $this.bannerMessage = response.data.yext;
                    }

                    const businessSlug = _.get(response, 'data.business.Slug.slug', '');
                    const checkoutCookieResultData = {thankyouStep: {businessSlug: businessSlug}};

                    //do we need to show red banner in thankyou page:
                    if ($this.bannerMessage) {
                        checkoutCookieResultData.thankyouStep.bannerMessage = $this.bannerMessage;
                    }

                    $this.$cookies.putObject('affiliatesPaymentData', checkoutCookieResultData, {domain: $this.cookieDomain, path: '/'});

                    $this.$window.location.href = '/affiliates/thankyou';
                } else {
                    $this.notValidationError = 'Something went wrong';
                    $this.blockData.stop();
                }
            })
            .catch(function (response) {
                if (response.data && !_.isEmpty(response.data.errorMessages)) {
                    $this.notValidationError = 'Wrong inputs';
                    const parsedErrors = parseCheckoutErrors(_.get(response, 'data.errorMessages', $this.errors));
                    $this.errors = parsedErrors.parsedErrors;
                    if (parsedErrors.notValidationError) {
                        $this.notValidationError = parsedErrors.notValidationError;
                    }
                } else {
                    $this.notValidationError = response.statusText;
                }

                //if cvv has error message:
                const possibleCVVError = _.get(response, 'data.errors.data.message');
                if (_.isEqual(possibleCVVError, 'Gateway Rejected: cvv')) {
                    $this.notValidationError = 'Please, verify CVV value and try again.';
                    $this.errors.checkoutInformation.cvv = ['Invalid cvv'];
                }

                //if No Reason Error Phrase:
                if (_.isEmpty($this.notValidationError)
                    || _.isEqual($this.notValidationError, 'Validation error')
                    || _.isEqual($this.notValidationError, 'No Reason Phrase')
                    || _.isEqual($this.notValidationError, 'Unprocessable Entity')) {
                    $this.notValidationError = 'Something went wrong. Please call us at 800-266-0219 to get assistance with your order';
                }

                console.error(response);
                $this.blockData.stop();
            });

        } else {
            $this.notValidationError = 'Wrong inputs';
        }
    }

    goToListingPage() {
        var $this = this;
        console.log('#goToListingPage was clicked');

//        $this.blockData.start();

        //clear the cookie:
        $this.$cookies.putObject('affiliatesPaymentData', {}, {domain: $this.cookieDomain, path: '/'});
//        $this.$window.location.href = `/${$this.businessSlug || ''}`;
        $this.$window.open(`/${$this.businessSlug || ''}`, '_blank');
    }

}

export default AffiliatesPaymentCtrl;
